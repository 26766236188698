import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: '16px',
    },
    withBadge: {
      background: 'transparent !important',
      boxShadow: 'none !important',
      color: `${theme.palette.text.secondary} !important`,
      transition: 'all 0.3s, height 1ms',
      minHeight: '35px !important',
      border: `1px solid ${theme.palette.grey.light}`,
      borderRadius: '5px !important',
      width: '100%',
      display: 'inline-flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
      maxWidth: '100%',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.common.white,
      },
    },
    withBadgeError: {
      background: 'transparent !important',
      boxShadow: 'none !important',
      color: `${theme.palette.text.primary} !important`,
      transition: 'all 0.3s, height 1ms',
      minHeight: '35px !important',
      border: `1px solid ${theme.palette.error.main} !important`,
      borderRadius: '5px !important',
      width: '100%',
      display: 'inline-block',
      cursor: 'pointer',
      maxWidth: '100%',
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.common.white,
        border: '1px solid',
      },
    },
    disabledWithBadge: {
      boxShadow: 'none !important',
      transition: '0.3s',
      minHeight: '35px !important',
      border: `1px solid ${theme.palette.grey.light} !important`,
      borderRadius: '5px !important',
      display: 'inline-flex',
      width: '100%',
    },
    spanLabel: {
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    spanLabelDropdown: {
      fontSize: '9pt',
      padding: '0 0 5px 0',
      height: '18px',
      borderRadius: '5px',
      marginLeft: '10px',
      maxWidth: 'inherit',
      whiteSpace: 'nowrap',
    },
    spanAccount: {
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    spanAccountName: {
      padding: '2px 5px 0 5px',
      borderRadius: '4px',
      maxWidth: '50%',
      display: 'inline-flex',
      verticalAlign: '-webkit-baseline-middle',
      margin: '-3px -1px 0 -1px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    spanAccountDropdown: {
      fontSize: '9pt',
      padding: '1px 0 1px 0',
      height: '27px',
      borderRadius: '5px',
      marginLeft: '10px',
    },
    lvValue: {
      padding: '5px',
      maxWidth: '50%',
      display: 'inline-flex',
      verticalAlign: '-webkit-baseline-middle',
    },
    spanLabelName: {
      padding: '2px 6px 0 5px',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      maxWidth: '50%',
      display: 'inline-flex',
      verticalAlign: '-webkit-baseline-middle',
      margin: '-3px 0 0 -1px',
      color: theme.palette.common.white,
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    icons: {
      marginLeft: '-5px',
      marginRight: '10px',
    },
    dropdown: {
      top: '8px !important',
      right: 'unset !important',
    },
    item: {
      '&:active': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    avatar: {
      height: '-webkit-fill-available',
    },
    first: {
      minHeight: '35px',
      padding: '20px 15px 0 15px',
      position: '-webkit-sticky',
      top: '0',
    },
    second: {
      textAlign: 'right',
      padding: '7px 15px 0 0',
      color: theme.palette.primary.main,
    },
    middle: {
      minHeight: '35px',
      padding: '8px 15px 8px 15px',
      cursor: 'pointer',
    },
    last: {
      minHeight: '35px',
      padding: '8px 15px 8px 15px',
      cursor: 'pointer',
    },
    icon: {
      fill: `${theme.palette.common.white} !important`,
      fontSize: '20px',
      height: '100%',
      marginBottom: '-8px',
    },
    iconHide: {},
    label: {
      margin: '3px 0',
      fontSize: 14,
      color: theme.palette.text.primary,
    },
    separator: {
      paddingLeft: '10px',
      margin: '5px',
      paddingRight: '5px',
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    refDropdown: {
      borderRadius: '5px',
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      maxHeight: '300px',
      zIndex: 1000,
    },
    divDropdown: {
      // width: '-webkit-fill-available',
      width: '100%',
      borderRadius: '4px',
      zIndex: 1001,
      maxHeight: '300px',
      position: 'absolute',
      backgroundColor: theme.palette.background.default,
      borderTop: 'none',
      boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)',
    },
    avatarL: {
      width: 'unset !important',
      borderRadius: '17px',
      paddingLeft: '5px',
      paddingRight: '5px',
      maxWidth: '165px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: `${theme.palette.common.white} !important`,
      display: 'block !important',
      lineHeight: '18px !important',
    },
    warningIcon: {
      fill: theme.palette.error.main,
      marginLeft: '10px',
      fontSize: '13px',
    },
    accountNameWithHover: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline',
      },
    },
    accountNameActive: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    popoverAdvanced: {
      /* marginTop: '33px', */
      '& .MuiPopover-paper': {
        minWidth: 200,
      },
    },
    chevronRight: {
      marginRight: 0,
      marginLeft: 'auto',
      color: theme.palette.text.secondary,
    },
    menuItem: {
      fontFamily: 'Poppins',
    },
    menuItemSelected: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.background1,
      fontFamily: 'Poppins',
    },
    advancedSettings: {
      fontFamily: 'Poppins',
      fontSize: '11px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '20px',
      color: theme.palette.primary.main,
      marginLeft: 'auto',
      marginRight: 0,
      cursor: 'pointer',
    },
    popoverContainer: {
      height: '100%',
      left: 'unset !important',
      right: '0',
      top: '0 !important',
      width: '600px',
      maxHeight: '100% !important',
    },
    popoverTitleText: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '20px',
    },
    chipFilter: {
      backgroundColor: theme.palette.background.background1,
      color: theme.palette.primary.main,
    },
    closeChipFilter: {
      fontSize: '14px',
      color: theme.palette.text.primary,
    },
    textField: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.grey.light,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '10.5px 6px',
      },
    },
    tooltipCustom: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
      maxWidth: 240,
      padding: '4px 8px 4px 8px',
      textAlign: 'center',
      border: `1px solid ${theme.palette.text.secondary}`,
      top: '9px',
    },
    arrowCustom: {
      color: theme.palette.text.secondary,
    },
    scrollableArea: {
      maxHeight: '210px',
      overflow: 'auto',
      '-webkit-transform': 'translateZ(0) !important',
    },
    inputAdornment: {
      color: theme.palette.grey.light,
    },
  });
