import { addSpace, getErrorObject } from 'src/utils/funcs';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { Dispatch } from 'redux';
import { NexusGenInputs, NexusGenFieldTypes, NexusGenEnums } from '../../../../server/src/types';
import i18n from 'src/utils/translations/i18n';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { orderBy } from 'lodash';
import {
  CreateIssueCatalogDraftDocument,
  CreateIssueCatalogFolderDocument,
  CreateManyTaskMappingDraftsDocument,
  DeleteIssueCatalogFolderDocument,
  DeleteIssueDraftDocument,
  DeleteIssuesCatalogDocument,
  DeleteManyTaskMappingDraftsDocument,
  DraftToIssueDocument,
  GetCatalogLabelAssigneesDocument,
  GetDistinctIssueCatalogValuesDocument,
  GetIssueCatalogFolderDocument,
  GetIssueCatalogFoldersSimpleDocument,
  GetIssueCatalogsAndFoldersDocument,
  GetIssueDraftDocument,
  GetIssueDraftsDocument,
  GetIssueInstancesToStartDocument,
  GetIssueScanMappingDocument,
  GetIssuesCatalogsToTreeDocument,
  GetIssuesCatalogToPreviewDocument,
  GetIssueTemplateFoldersDocument,
  GetIssueTemplatesInCatalogsDocument,
  GetIssueTemplatesLeanDocument,
  GetIssueTemplatesTreeDocument,
  GetTaskTemplateGroupsDocument,
  GetTimeTableDocument,
  IssueCatalog,
  IssueCatalogDraftCreateInput,
  IssueCatalogSchedulerCreateInput,
  IssueCatalogUpdateInput,
  IssueTemplate,
  IssueTemplateWhereInput,
  IssueToDraftDocument,
  IssueToDraftMutation,
  RunIssuesDocument,
  SearchGlarPrefixesDocument,
  TaskGroupLabelFilterDocument,
  UpdateIssueCatalogDocument,
  UpdateIssueCatalogFolderDocument,
  UpdateIssueDraftDocument,
} from 'src/gql/graphql';

export default (): void => null;

export const getCatalogFoldersToShow =
  (
    limit: number,
    skip: number,
    filterFolder: NexusGenInputs['IssueCatalogFolderWhereInput'],
    filterIssue: NexusGenInputs['IssueCatalogWhereInput'],
    orderBy?: string,
  ) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          templateWhere: filterIssue,
          templateFolderWhere: filterFolder,
          skip,
          limit,
          orderByTemplate: orderBy ? [orderBy] : ['name_ASC'],
          orderByTemplateFolder: ['name_ASC'],
        },
        fetchPolicy: 'no-cache',
        query: GetIssueCatalogsAndFoldersDocument,
      });

      const countToReturn = response.data.issueCatalogsAndFolders.length;

      const folders = response.data.issueCatalogsAndFolders.filter(
        (item: any) => item.__typename === 'IssueCatalogFolder',
      );
      const issues = response.data.issueCatalogsAndFolders.filter((item: any) => item.__typename === 'IssueCatalog');

      return {
        folders,
        issues,
        countToReturn,
      };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: { message: obj.message, severity: 'error' },
      });
      return error;
    }
  };

export const getIssueCatalogFolder = (id: string) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.query({
      variables: {
        where: { _id: id },
      },
      fetchPolicy: 'no-cache',
      query: GetIssueCatalogFolderDocument,
    });
    return resp.data.issueCatalogFolder;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getIssueCatalogsToList =
  (limit: number, skip: number, filter: NexusGenInputs['IssueCatalogWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response: {
        data: {
          issueCatalogs: NexusGenFieldTypes['IssueCatalog'][];
          issueCatalogsCount: number;
        };
      } = await APOLLO_CLIENT.query({
        variables: {
          limit,
          skip,
          filter,
        },
        fetchPolicy: 'no-cache',
        query: GetIssuesCatalogsToTreeDocument,
      });

      return {
        content: response.data.issueCatalogs,
        total: response.data.issueCatalogsCount,
      };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getCatalogLabelAssignees =
  (ids: string[]) =>
  async (dispatch: Dispatch): Promise<IssueCatalog[]> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter: {
            _id_in: ids,
          },
        },
        fetchPolicy: 'no-cache',
        query: GetCatalogLabelAssigneesDocument,
      });

      return response.data.issueCatalogs;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateCatalogFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }, { _id }) =>
  async (dispatch: Dispatch) => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id, _id },
        fetchPolicy: 'no-cache',
        mutation: UpdateIssueCatalogFolderDocument,
      });

      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteCatalogFolders =
  (folderIds: string[]) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: { where: { _id_in: folderIds } },
        fetchPolicy: 'no-cache',
        mutation: DeleteIssueCatalogFolderDocument,
      });

      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: folderIds.length > 1 ? i18n.t('foldersToastDeleteSuccess') : i18n.t('folderToastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteIssueCatalog = (ids: string[], doingOnBoarding?: boolean) => async (dispatch: Dispatch) => {
  try {
    await APOLLO_CLIENT.mutate({
      variables: {
        ids,
      },
      mutation: DeleteIssuesCatalogDocument,
    });

    !doingOnBoarding &&
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('issueCatalogToastDeleteSuccess'),
          severity: 'success',
        },
      });
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getIssueCatalogsToPreview =
  (filter: NexusGenInputs['IssueCatalogWhereUniqueInput']) => async (dispatch: Dispatch) => {
    try {
      const response: { data: { issueCatalog: NexusGenFieldTypes['IssueCatalog'] } } = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: GetIssuesCatalogToPreviewDocument,
      });

      return response.data.issueCatalog;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateIssueCatalog =
  (issue: IssueCatalogUpdateInput, id: string, showSnackbar: boolean = true) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issue,
          id,
        },
        mutation: UpdateIssueCatalogDocument,
      });

      if (showSnackbar) {
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: response.data.updateIssueCatalog.name + ' ' + i18n.t('toastUpdateSuccess'),
            severity: 'success',
          },
        });
      }

      return response.data.updateIssueCatalog;
    } catch (error) {
      const obj = getErrorObject(error, '');
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createCatalogFolder =
  ({ name, parent = null, context }: { name: string; parent: { _id: string } | null; context: string }) =>
  async (dispatch: Dispatch) => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id, context },
        fetchPolicy: 'no-cache',
        mutation: CreateIssueCatalogFolderDocument,
      });

      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folderModalCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const runIssues =
  (
    ids: string[],
    labelIds?: string[],
    siteIds?: string[],
    elementIds?: string[],
    scheduler?: IssueCatalogSchedulerCreateInput[],
    doingOnBoarding?: boolean,
  ) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['IssueInstance'][] | { graphQLErrors: string }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          _ids: ids,
          labelIds,
          siteIds,
          elementIds,
          scheduler,
        },
        fetchPolicy: 'no-cache',
        mutation: RunIssuesDocument,
      });

      !doingOnBoarding &&
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('issueStarted'),
            severity: 'success',
          },
        });

      return response.data.runIssues;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getCatalogFolders =
  (limit: number, skip: number, where: NexusGenInputs['IssueCatalogFolderWhereInput']) =>
  async (dispatch: Dispatch): Promise<any | Error> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          limit,
          skip,
          where,
        },
        fetchPolicy: 'no-cache',
        query: GetIssueCatalogFoldersSimpleDocument,
      });

      return response.data.issueCatalogFolders;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error as Error;
    }
  };

export const createIssueCatalogDraft = (data: IssueCatalogDraftCreateInput) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ createIssueCatalogDraft: NexusGenFieldTypes['IssueCatalogDraft'] }> =
      await APOLLO_CLIENT.mutate({
        variables: {
          data,
        },
        mutation: CreateIssueCatalogDraftDocument,
      });

    return response.data.createIssueCatalogDraft;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getIssueDraft =
  (filter: NexusGenInputs['IssueCatalogDraftWhereUniqueInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: GetIssueDraftDocument,
      });

      return response.data.issueCatalogDraft;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueDrafts = (filter: NexusGenInputs['IssueCatalogDraftWhereInput']) => async () => {
  try {
    const response: { data: { issueCatalogDrafts: NexusGenFieldTypes['IssueCatalogDraft'][] } } =
      await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: GetIssueDraftsDocument,
      });

    return response.data.issueCatalogDrafts;
  } catch (error) {
    return error;
  }
};

export const deleteIssueDraft = (id: string) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ deleteOneIssueCatalogDraft: NexusGenFieldTypes['IssueCatalogDraft'] }> =
      await APOLLO_CLIENT.mutate({
        variables: {
          id,
        },
        mutation: DeleteIssueDraftDocument,
      });

    return response.data.deleteOneIssueCatalogDraft;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const updateIssueDraft =
  (issue: NexusGenInputs['IssueCatalogDraftUpdateInput'], id: string) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          issue,
          id,
        },
        mutation: UpdateIssueDraftDocument,
      });

      return response.data.updateIssueCatalogDraft;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const issueToDraft =
  (id: string, updateCatalog = false) =>
  async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate<IssueToDraftMutation>({
        variables: {
          id,
          updateOriginal: updateCatalog,
        },
        mutation: IssueToDraftDocument,
      });

      return response.data.createDraftFromIssueCatalog;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const draftToIssue = (id: string) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        id,
      },
      mutation: DraftToIssueDocument,
    });

    return response.data.upsertIssueCatalogFromDraft;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const createManyTaskMappingDrafts = (id: string, taskMappings: string[]) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.mutate({
      variables: {
        taskMappings,
        id,
      },
      mutation: CreateManyTaskMappingDraftsDocument,
    });

    return response.data.createManyTaskMappingDrafts;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getGlarPrefixes = (searchString) => async (dispatch: Dispatch) => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: { searchString },
      fetchPolicy: 'cache-first',
      query: SearchGlarPrefixesDocument,
    });

    return response.data.glarPrefixes;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const taskTemplateGroupsByLabelFilter =
  (id, filter) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['IssueTemplate']> => {
    try {
      const resp = await APOLLO_CLIENT.query({
        variables: {
          _id: id,
          filter: filter,
        },
        fetchPolicy: 'no-cache',
        query: TaskGroupLabelFilterDocument,
      });
      return resp.data.taskTemplateGroupsByLabelFilter;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getTimeTable = (id: string) => async (dispatch: Dispatch) => {
  try {
    const resp = await APOLLO_CLIENT.query({
      variables: {
        _id: id,
      },
      fetchPolicy: 'no-cache',
      query: GetTimeTableDocument,
    });
    return resp.data.getTimeTable;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getFoldersToModal =
  (
    options: { parent_eq: string; parent_exists: string; _id_eq: string },
    item: { totalFolders: number; totalItems: number },
  ) =>
  async (/* dispatch: Dispatch */): Promise<any> => {
    try {
      let response: ApolloQueryResult<{ issueTemplateFolders: NexusGenFieldTypes['IssueTemplateFolder'][] }> | null =
        null;
      if (item?.totalFolders || options.parent_exists !== undefined || options._id_eq !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options },
          fetchPolicy: 'no-cache',
          query: GetIssueTemplateFoldersDocument,
        });
      }

      let issueTemplates: ApolloQueryResult<{ issueTemplates: NexusGenFieldTypes['IssueTemplate'][] }> | null = null;
      if (options.parent_eq && item.totalItems) {
        issueTemplates = await APOLLO_CLIENT.query({
          variables: {
            filter: { folder: { _id_eq: options.parent_eq }, variant: { position_eq: 1 }, archived_ne: true },
          },
          fetchPolicy: 'no-cache',
          query: GetIssueTemplatesTreeDocument,
        });
      } else if (item && response && response.data.issueTemplateFolders) {
        // on back
        issueTemplates = await APOLLO_CLIENT.query({
          variables: {
            filter: {
              folder: { _id_eq: response.data.issueTemplateFolders[0]._id },
              variant: { position_eq: 1 },
              archived_ne: true,
            },
          },
          fetchPolicy: 'no-cache',
          query: GetIssueTemplatesTreeDocument,
        });
      }

      return orderBy(
        [
          ...(response ? response.data.issueTemplateFolders : []),
          ...(issueTemplates ? issueTemplates.data.issueTemplates : []),
        ],
        [
          (o): string => {
            //@ts-ignore
            return o.name || o.title;
          },
        ],
        'asc',
      );
    } catch (error) {
      console.error('getFoldersToModal: ', error);
    }
  };

export const getIssueTemplates = (filter: IssueTemplateWhereInput) => async (): Promise<IssueTemplate> => {
  try {
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: GetIssueTemplatesInCatalogsDocument,
    });

    return response.data.issueTemplates[0];
  } catch (error) {
    /* const obj = getErrorObject(error, ""); */
    /* dispatch({
        type: "SNACKBAR_NEW_MESSAGE",
        payload: {
          message: obj.message,
          severity: "error",
        },
      }); */
    return error;
  }
};

export const deleteManyTaskMappingDrafts = (id: string, taskMappings: string[]) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ deleteManyTaskMappingDrafts: NexusGenFieldTypes['IssueCatalogDraft'] }> =
      await APOLLO_CLIENT.mutate({
        variables: {
          taskMappings,
          id,
        },
        mutation: DeleteManyTaskMappingDraftsDocument,
      });

    return response.data.deleteManyTaskMappingDrafts;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getIssueInstancesToShowOnStart =
  (filter: NexusGenInputs['IssueInstanceWhereInput'], orderBy: NexusGenEnums['IssueInstanceOrderByInput']) =>
  async (dispatch) => {
    try {
      const response: { data: { issues: NexusGenFieldTypes['IssueInstance'][] } } = await APOLLO_CLIENT.query({
        variables: {
          filter,
          orderBy,
        },
        fetchPolicy: 'no-cache',
        query: GetIssueInstancesToStartDocument,
      });

      return response.data.issues;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueScanMapping =
  (filter: NexusGenInputs['IssueTemplateWhereUniqueInput']) => async (/* dispatch: Dispatch */) => {
    try {
      const response: { data: { issueTemplate: NexusGenFieldTypes['IssueTemplate'] } } = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: GetIssueScanMappingDocument,
      });

      return response.data.issueTemplate.frame.scanMapping;
    } catch (error) {
      /* const obj = getErrorObject(error, ""); */
      /* dispatch({
        type: "SNACKBAR_NEW_MESSAGE",
        payload: {
          message: obj.message,
          severity: "error",
        },
      }); */
      return error;
    }
  };

export const distinctIssueCatalogValues =
  (where) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['IssueCatalogDistinctValues']> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { where },
        fetchPolicy: 'no-cache',
        query: GetDistinctIssueCatalogValuesDocument,
      });

      return response.data.distinctIssueCatalogValues;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getTaskTemplateGroups =
  (where: NexusGenInputs['TaskTemplateGroupWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { where },
        fetchPolicy: 'no-cache',
        query: GetTaskTemplateGroupsDocument,
      });

      return response.data.taskTemplateGroups;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueTemplatesLean =
  (where: NexusGenInputs['IssueTemplateWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: { filter: where },
        fetchPolicy: 'no-cache',
        query: GetIssueTemplatesLeanDocument,
      });

      return response.data.issueTemplates;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };
