import { AddPhotoAlternate, Delete } from '@mui/icons-material';
import { useState, useEffect, useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { showSnackbar } from 'src/base/root/root.redux';
import { FileInterface } from 'src/interfaces/file';
import { addDescriptionFile, getDescriptionFile } from 'src/modules/file-storage/file-storage.redux';
import VideoSnapshot from 'video-snapshot';
import { NexusGenFieldTypes } from '../../../../../../server/src/types';
import { useTheme } from '@mui/styles';

interface Input {
  [x: string]: any;
  _id: string;
  name: string;
  type: string;
  order: number;
  required: boolean;
  multiple: boolean;
  options: string[];
  subType?: string;
  container?: string;
  description?: string;
  number?: {
    onResponse?: any;
    unit?: {
      _id: string;
    };
  };
}

type GroupName =
  | 'INLINE_STYLE_BUTTONS'
  | 'BLOCK_TYPE_BUTTONS'
  | 'LINK_BUTTONS'
  | 'BLOCK_TYPE_DROPDOWN'
  | 'HISTORY_BUTTONS';
interface Props {
  addDescriptionFile: (data: FileInterface, thumbnail: Blob) => Promise<{ data: FileInterface }>;
  input: Input;
  getDescriptionFile: (id: string) => Promise<NexusGenFieldTypes['File']>;
  setDesc: (val: string) => void;
  desc: string;
  setInput: (val: Partial<Input>) => void;
  showSnackbar: (type: string, message: string) => void;
  addDescription: any;
}

let filesID: string[] = [];
let urlArray: string[] = [];
let descriptionInMarkdown: string;
const RichTextBox = (props: Props): JSX.Element => {
  const theme = useTheme();
  const { addDescriptionFile, input, setDesc, desc, setInput, addDescription } = props;

  const [file, setFile] = useState<string>(null);
  const [value, setValue] = useState<EditorValue>(null);

  const hiddenFileInput = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (addDescription) if (input.description) setDesc(input.description);
  }, [addDescription]);

  useEffect(() => {
    if (addDescription) {
      const descInMarkdown = descriptionInMarkdown;
      descriptionInMarkdown = '';
      return () => {
        setInput({
          _id: input._id.toString(),
          description: descInMarkdown,
        });
      };
    }
    return undefined;
  }, [addDescription]);

  const getRenderText = async () => {
    let renderText: EditorValue;
    if (desc) {
      const valueToRender = await handleDescriptionImages();
      renderText = RichTextEditor.createValueFromString(valueToRender, 'markdown');
    } else {
      renderText = RichTextEditor.createEmptyValue();
    }
    setValue(renderText);
  };

  useEffect(() => {
    getRenderText();
  }, [file]);

  const getFilesIdFromText = (desc: string) => {
    let filesIDAux = desc.match('<file>(.*)</file>')[1];
    if (filesIDAux.includes('<file>')) {
      let aux = filesIDAux.toString().replaceAll('<file>', '');
      aux = aux.toString().replaceAll('</file>', ',');
      aux = aux.replace(/\s/g, '');
      aux.split(',').forEach((id) => filesID.push(id.toString()));
    } else {
      filesID.push(filesIDAux.toString());
    }
    if (desc.replace(`<file>${filesIDAux}</file>`, '').match('<file>(.*)</file>')) {
      getFilesIdFromText(desc.replace(`<file>${filesIDAux}</file>`, ''));
    }
  };

  const handleDescriptionImages = async () => {
    let valueToReturn = desc;
    if (desc.match('<file>(.*)</file>')) {
      filesID = [];
      urlArray = [];
      getFilesIdFromText(desc);

      if (filesID.length > 0) {
        for (let i = 0; i < filesID.length; i++) {
          const currentFileID = filesID[parseInt(i.toString())].toString();
          if (!currentFileID.includes('http')) {
            await props.getDescriptionFile(currentFileID).then((resp: NexusGenFieldTypes['File']) => {
              if (resp.extension !== 'pdf' && !urlArray.includes(resp.download.thumbnail.url)) {
                urlArray.push(resp.download.thumbnail.url);
              }
            });
          } else if (!urlArray.includes(currentFileID)) {
            urlArray.push(currentFileID);
          }
        }
        urlArray.forEach((url, idx) => {
          valueToReturn = valueToReturn.replace(`<file>${filesID[parseInt(idx.toString())]}</file>`, `![](${url})`);
        });
      }
    }
    return valueToReturn;
  };

  const dataUrlToFile = async (dataUrl: string, fileName: string) => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeLocalFile = async (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.type.includes('pdf')) {
      props.showSnackbar('error', t('invalidFile'));
      return;
    }

    let blob: Blob;
    if (fileUploaded.type.includes('image')) {
      blob = await resizeImage({ maxSize: 100, file: fileUploaded });
    } else if (fileUploaded.type.includes('video') || fileUploaded.name.includes('mkv')) {
      const snapThumbnail = new VideoSnapshot(fileUploaded);
      const previewSr = await snapThumbnail.takeSnapshot(0);
      const previewFile = await dataUrlToFile(previewSr, 'preview_video');
      blob = await resizeImage({ maxSize: 100, file: previewFile });
    }

    const resp = await addDescriptionFile(fileUploaded, blob);

    if (resp.data?._id) {
      const description = desc ? desc.concat(`<file>${resp.data?._id}</file>`) : `<file>${resp.data?._id}</file>`;

      setDesc(description);
      setInput({
        _id: input._id.toString(),
        description: description,
      });
      //value to make component to rerender
      setFile(resp.data?._id);
    }
  };

  interface IResizeImageOptions {
    maxSize: number;
    file: any;
  }

  const resizeImage = (settings: IResizeImageOptions) => {
    const file = settings.file;
    const maxSize = settings.maxSize;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const type = settings.file.type;

    const dataURItoBlob = (dataURI: string) => {
      const bytes =
        dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) ia[parseInt(`${i}`)] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    };

    const resize = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL(type);
      return dataURItoBlob(dataUrl);
    };

    return new Promise<Blob>((ok, no) => {
      if (!file.type?.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }

      reader.onload = (readerEvent: any) => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };

      reader.readAsDataURL(file);
    });
  };

  const customControl = [
    () => (
      <>
        <AddPhotoAlternate onClick={handleClick} />
        <input type='file' ref={hiddenFileInput} onChange={handleChangeLocalFile} style={{ display: 'none' }} />
        <Delete
          style={{ marginLeft: '10px' }}
          onClick={() => {
            setValue(RichTextEditor.createEmptyValue());
            setDesc(null);
            setInput({
              _id: input._id.toString(),
              description: null,
            });
            filesID = [];
            urlArray = [];
          }}
        />
      </>
    ),
  ];

  const toolbarDisplay: GroupName[] = [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS',
  ];
  const toolbarConfig: ToolbarConfig = {
    INLINE_STYLE_BUTTONS: [{ label: 'Bold', style: 'BOLD', className: 'custom-css-class' }],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
    display: toolbarDisplay,
    BLOCK_ALIGNMENT_BUTTONS: [],
  };

  const handleChange = (val: EditorValue) => {
    setValue(val);
    if (!(val.toString('markdown') === value.toString('markdown'))) {
      saveDescription(val);
    }
  };

  const saveDescription = async (value: EditorValue) => {
    const valueMarkdown = value.toString('markdown', { blockRenderers: {} });
    let description = valueMarkdown;
    //remove the urls and set the ids of each file
    if (urlArray.length > 0) {
      urlArray.forEach((url, idx) => {
        description = description.replace(`![](${url})`, `<file>${filesID[parseInt(idx.toString())]}</file>`);
      });
    }

    setDesc(!value.getEditorState().getCurrentContent().hasText() ? null : description);
    descriptionInMarkdown = description;
    // setInput({
    //   description: !value.getEditorState().getCurrentContent().hasText() ? null : description,
    // });
  };

  return value ? (
    <RichTextEditor
      data-testid='input-description-text-box'
      placeholder={t('insertDescription')}
      toolbarConfig={toolbarConfig}
      value={value}
      onChange={handleChange}
      customControls={customControl}
      rootStyle={{ backgroundColor: theme.palette.background.default }}
    />
  ) : null;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addDescriptionFile,
      getDescriptionFile,
      showSnackbar,
    },
    dispatch,
  );

export default compose<any>(withTranslation('translation'), connect(mapStateToProps, mapDispatchToProps))(RichTextBox);
