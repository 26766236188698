import axios from 'axios';
import saveAs from 'file-saver';
import { isEqual, sum } from 'lodash';
import { IssueInstance, Task, TaskGroup } from 'src/gql/graphql';
import { API_URL } from 'src/utils/env';
import { isRequiredInputFilled } from '..';

export const isExecutable = (stateMachine, issue): boolean =>
  stateMachine &&
  issue &&
  stateMachine.states.filter((s) => s.name === stateMachine.currentState)[0].executable &&
  !issue.isLocked;

export const countTasksDoneByGroup = (tasks: Task[]) => {
  let tasksDone = 0;
  tasks.map((task) => (task.stateMachineInstance?.currentState === 'Done' ? (tasksDone += 1) : null));
  return tasksDone;
};

export const allTasks = (execGroups) => {
  let tasks = [];
  execGroups.forEach((exec) => {
    tasks = [...tasks, ...exec.tasks];
  });
  return tasks;
};

export const checkToolReservation = (issue: IssueInstance) => {
  if (issue.toolItems?.length) {
    return false;
  }

  for (const taskGroup of issue.taskGroups) {
    for (const task of taskGroup.tasks) {
      if (task.assignedTools?.length) {
        for (const assignedTool of task.assignedTools) {
          if (assignedTool?.reserveAtStart && !issue.toolItems?.some((t) => t.tool._id === assignedTool.tool._id)) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export const checkRequiredInputs = (issue: IssueInstance): number => {
  return sum(
    issue?.taskGroups?.flatMap((tG) =>
      tG.tasks.flatMap((task) => task.executionInputs.filter((input) => !isRequiredInputFilled(input as any)).length),
    ),
  );
};

export const totalTasks = (issue: IssueInstance): number => {
  let total = 0;
  issue?.taskGroups?.forEach((exec) => {
    total = total + exec.tasks.filter((t) => !t.onResponseId || (t.isActiveByOnResponse && t.onResponseId)).length;
  });
  return total;
};

export const showTimer = (
  execGroup: TaskGroup,
  task: Task,
  taskIndex: number,
  isLastExecGroupFinished: boolean,
  sequential: boolean,
) => {
  if (!task.stateMachineInstance) return false;

  let newIndex = null;
  execGroup?.tasks
    ?.filter((t) => !t.onResponseId)
    .sort((x, y) => x.order - y.order)
    .map((t, tIndex) => {
      if (!t.stateMachineInstance.finishedAt && newIndex === null) newIndex = tIndex;
    });

  return (
    (task.stateMachineInstance.catalog.name === 'Start with timer' &&
      (taskIndex === 0 || newIndex === taskIndex) &&
      (isLastExecGroupFinished || !sequential) &&
      task.stateMachineInstance.states
        .filter((st) => st.name === task.stateMachineInstance.currentState)[0]
        .transitions.filter((act) => act.position === 2).length) ||
    ((isLastExecGroupFinished || !sequential) && task.stateMachineInstance.wip)
  );
};

export const setIdsArray = (inputs, ids, responseId?) => {
  return inputs?.length
    ? inputs.map((inp) => {
        return {
          ...inp,
          onResponsePath: responseId ? [...ids, responseId, inp._id] : [inp._id],
          [inp.type]: {
            ...inp[inp.type],
            onResponse: !inp[inp.type].onResponse
              ? []
              : inp[inp.type].onResponse.map((resp) => {
                  return {
                    ...resp,
                    inputs: setIdsArray(resp.inputs, responseId ? [...ids, responseId, inp._id] : [inp._id], resp._id),
                  };
                }),
          },
        };
      })
    : [];
};

export const downloadReport = async (issueId: string, name: string) => {
  return new Promise((resolve) => {
    axios
      .get(API_URL + '/issue/report/' + issueId, {
        responseType: 'blob',
        headers: {
          Authorization: localStorage.getItem('access_token'),
        },
      })
      .then((response) => {
        saveAs(new Blob([response.data], { type: 'application/pdf' }), name + '.pdf');
        resolve('');
      });
  });

  // const response = await axios.get(API_URL + '/issue/report/' + issueId, {
  //   responseType: 'blob',
  //   headers: {
  //     Authorization: localStorage.getItem('access_token'),
  //   },
  // });

  // const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
  // const link = document.createElement('a');
  // link.href = url;
  // link.setAttribute('download', `${name}.pdf`);
  // document.body.appendChild(link);
  // link.click();
  // setTimeout(() => {
  //   document.body.removeChild(link);
  //   window.URL.revokeObjectURL(url);
  // }, 100);
};

export const verifyIssueTemplateChanges = (current, previous): boolean => {
  return current && previous && current.title !== previous.title;
};

export const verifyIssueTemplateFrame = (current, previous): boolean => {
  return current && previous && !isEqual(current.frame, previous.frame);
};

export const verifyIssueTemplateVariant = (current, previous): boolean => {
  return current && previous && !isEqual(current.variant, previous.variant);
};

export const verifyTaskTemplateGroupChanges = (current, previous) => {
  if (!current || !previous) return { taskTemplateChanges: new Map(), groupChanges: new Map() };

  const taskTemplateChanges = detectTaskTemplateChanges(current.taskTemplateGroups, previous.taskTemplateGroups);
  const groupChanges = detectGroupChanges(current.taskTemplateGroups, previous.taskTemplateGroups);

  return { taskTemplateChanges, groupChanges };
};

function detectTaskTemplateChanges(currentGroups, previousGroups) {
  const changesArray = [];

  const previousTaskMap = new Map();
  previousGroups.forEach((group) => {
    group.taskTemplates.forEach((template) => {
      previousTaskMap.set(template._id, template);
    });
  });

  currentGroups.forEach((group) => {
    const groupId = group._id;

    group.taskTemplates.forEach((task) => {
      const previousTask = previousTaskMap.get(task._id);

      if (!previousTask) {
        changesArray.push({
          action: 'ADD',
          taskTemplate: { ...task },
          groupId: groupId,
        });
      } else if (!isEqual(task, previousTask)) {
        changesArray.push({
          action: 'EDIT',
          taskTemplate: { ...task },
          groupId: groupId,
        });
        previousTaskMap.delete(task._id);
      } else {
        previousTaskMap.delete(task._id);
      }
    });
  });

  previousTaskMap.forEach((task) => {
    const groupId = previousGroups.find((group) => group.taskTemplates.some((t) => t._id === task._id))?._id;
    if (groupId) {
      changesArray.push({
        action: 'DELETE',
        taskTemplate: { _id: task._id },
        groupId: groupId,
      });
    }
  });

  return changesArray;
}

function detectGroupChanges(currentGroups, previousGroups) {
  const changesArray = [];

  const previousGroupMap = new Map();
  previousGroups.forEach((group) => previousGroupMap.set(group._id, group));

  currentGroups.forEach((group) => {
    const previousGroup = previousGroupMap.get(group._id);

    if (!previousGroup) {
      changesArray.push({
        action: 'ADD',
        group: group,
      });
    } else if (areGroupAttributesDifferent(group, previousGroup)) {
      changesArray.push({
        action: 'EDIT',
        group: group,
      });
      previousGroupMap.delete(group._id);
    } else {
      previousGroupMap.delete(group._id);
    }
  });

  previousGroupMap.forEach((group) => {
    changesArray.push({
      action: 'DELETE',
      group: group,
    });
  });

  return changesArray;
}

function areGroupAttributesDifferent(group1, group2) {
  const keys1 = Object.keys(group1).filter((key) => key !== 'taskTemplates');
  const keys2 = Object.keys(group2).filter((key) => key !== 'taskTemplates');

  if (!isEqual(keys1, keys2)) {
    return true;
  }

  for (const key of keys1) {
    if (!isEqual(group1[key], group2[key])) {
      return true;
    }
  }

  return false;
}
