import {
  AddCircle,
  CallSplitOutlined,
  ChevronRight,
  FeedbackOutlined,
  HighlightOffOutlined,
  InsertDriveFileOutlined,
  LiveHelpOutlined,
  RemoveCircleOutlineOutlined,
  ReportProblemOutlined,
  VerticalAlignCenter,
} from '@mui/icons-material';
import { MenuItem, Popover, useTheme } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import moment from 'moment';
import { Ref, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { typedConnect } from 'src/utils/funcs';
import ObjectId from 'bson-objectid';

interface Input {
  [x: string]: any;
  _id: string;
  name: string;
  type: string;
  order: number;
  required: boolean;
  multiple: boolean;
  options: string[];
  subType?: string;
  container?: string;
  number?: {
    validations: {
      name?: string;
      min?: number;
      max?: number;
      inclusive?: boolean;
      notBetween?: any[] | undefined;
    }[];
    onResponse?: OnResponse[];
    unit?: {
      _id: string;
    };
  };
}

interface OnResponse {
  inputs: Input[];
  actionRequired?: boolean;
  createAlert?: boolean;
  createTasks?: boolean;
  ifValues?: { label: string; color?: string }[];
  _id?: string;
  color?: string;
  condition?: string;
  failResponse?: boolean;
  finishIssue?: boolean;
  repeatTasksIds?: string[];
  repeatTasks?: boolean;
}

interface NumberPopoverProps extends WithStyles<typeof styles>, WithTranslation, ConnectedProps<typeof connector> {
  anchor: {
    current: Element;
  };
  onClose: () => void;
  input: Input;
  setInput: (input: Partial<Input>) => void;
  innerRef: Ref<HTMLDivElement>;
  subRef: Ref<HTMLDivElement>;
  instruction: boolean;
}

const colors = ['#B468FF', '#00BF9D', '#FE8947', '#ACED24'];

const NumberPopover = (props: NumberPopoverProps): JSX.Element => {
  const { classes, input, setInput, innerRef, subRef, anchor, instruction } = props;
  const [openValidation, setOpenValidation] = useState<EventTarget | null>(null);
  const [openConditional, setOpenConditional] = useState<EventTarget | null>(null);
  const theme = useTheme();
  const { doingOnBoarding } = useMainContainerContext();

  return (
    <Popover
      open={true}
      anchorEl={anchor.current}
      ref={innerRef}
      onClose={(): void => {
        props.onClose();
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem
        id={`menuItemValidationCharLimits`}
        data-testid={`menuItemValidationCharLimits`}
        classes={{ root: classes.menuItemAdvanced }}
        onClick={(e): void => {
          setOpenValidation(e.target);
        }}
      >
        <VerticalAlignCenter style={{ fontSize: '16px', transform: 'rotate(90deg)' }} />
        <span className={classes.menuItemWithoutCheck}>{props.t('characterLimits')}</span>
        <ChevronRight classes={{ root: classes.chevronRight }} />
      </MenuItem>
      {openValidation ? (
        <Popover
          open={!!openValidation}
          // @ts-ignore
          anchorEl={openValidation}
          ref={subRef}
          onClose={(): void => {
            setOpenValidation(null);
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            id={`menuItemValidationBetween`}
            data-testid={`menuItemValidationBetween`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              setInput({
                _id: input._id,
                number: { ...input.number, validations: [{ name: 'between', notBetween: undefined, inclusive: true }] },
              });
              setOpenValidation(null);
              props.onClose();
            }}
          >
            <span className={classes.menuItemWithoutCheck}>{props.t('between')}</span>
          </MenuItem>
          <MenuItem
            id={`menuItemValidationNotBetween`}
            data-testid={`menuItemValidationNotBetween`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              setInput({
                _id: input._id,
                number: {
                  ...input.number,
                  validations: [{ name: 'notBetween', notBetween: [null, null], inclusive: false }],
                },
              });
              setOpenValidation(null);
              props.onClose();
            }}
          >
            <span className={classes.menuItemWithoutCheck}>{props.t('notBetween')}</span>
          </MenuItem>
          <MenuItem
            id={`menuItemValidationEqualTo`}
            data-testid={`menuItemValidationEqualTo`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              setInput({
                _id: input._id,
                number: { ...input.number, validations: [{ name: 'equalTo', notBetween: undefined, inclusive: true }] },
              });
              setOpenValidation(null);
              props.onClose();
            }}
          >
            <span className={classes.menuItemWithoutCheck}>{props.t('equalTo')}</span>
          </MenuItem>
          <MenuItem
            id={`menuItemValidationMoreThan`}
            data-testid={`menuItemValidationMoreThan`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              setInput({
                _id: input._id,
                number: {
                  ...input.number,
                  validations: [{ name: 'moreThan', notBetween: undefined, inclusive: false }],
                },
              });
              setOpenValidation(null);
              props.onClose();
            }}
          >
            <span className={classes.menuItemWithoutCheck}>{props.t('moreThan')}</span>
          </MenuItem>
          <MenuItem
            id={`menuItemValidationLessThan`}
            data-testid={`menuItemValidationLessThan`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              setInput({
                _id: input._id,
                number: {
                  ...input.number,
                  validations: [{ name: 'lessThan', notBetween: undefined, inclusive: false }],
                },
              });
              setOpenValidation(null);
              props.onClose();
            }}
          >
            <span className={classes.menuItemWithoutCheck}>{props.t('lessThan')}</span>
          </MenuItem>
          <MenuItem
            id={`menuItemValidationLessOrEqualTo`}
            data-testid={`menuItemValidationLessOrEqualTo`}
            classes={{ root: classes.menuItemAdvanced }}
            onClick={(): void => {
              setInput({
                _id: input._id,
                number: {
                  ...input.number,
                  validations: [{ name: 'lessOrEqualTo', notBetween: undefined, inclusive: true }],
                },
              });
              setOpenValidation(null);
              props.onClose();
            }}
          >
            <span className={classes.menuItemWithoutCheck}>{props.t('lessOrEqualTo')}</span>
          </MenuItem>
        </Popover>
      ) : null}
      {instruction ? (
        <>
          {!doingOnBoarding && (
            <MenuItem
              id={`menuItemCreateConditional`}
              data-testid={`menuItemCreateConditional`}
              classes={{ root: classes.menuItemAdvanced }}
              onClick={(e): void => {
                setOpenConditional(e.target);
              }}
            >
              <CallSplitOutlined style={{ fontSize: '16px', transform: 'rotate(90deg)' }} />
              <span className={classes.menuItemWithoutCheck}>{props.t('createConditional')}</span>
              <ChevronRight classes={{ root: classes.chevronRight }} />
            </MenuItem>
          )}
          {openConditional ? (
            <Popover
              open={!!openConditional}
              // @ts-ignore
              anchorEl={openConditional}
              ref={subRef}
              onClose={(): void => {
                setOpenConditional(null);
              }}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {!props.isMasterTenant ? (
                <MenuItem
                  data-testid={`menu-item-conditional-create-action`}
                  classes={{ root: classes.menuItemAdvanced }}
                  onClick={(): void => {
                    setInput({
                      _id: input._id,
                      number: {
                        ...input.number,
                        onResponse: [
                          ...input.number.onResponse,
                          {
                            inputs: [],
                            condition: 'EQUALS',
                            ifValues: [null],
                            actionRequired: true,
                            _id: ObjectId().toString(),
                          },
                        ],
                      },
                    });
                    setOpenConditional(null);
                    props.onClose();
                  }}
                >
                  <FeedbackOutlined style={{ fontSize: '16px', color: '#F4A261' }} />
                  <span className={classes.menuItemWithoutCheck}>{props.t('createAction')}</span>
                </MenuItem>
              ) : null}
              <MenuItem
                data-testid={`menu-item-conditional-create-alert`}
                classes={{ root: classes.menuItemAdvanced }}
                onClick={(): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: [
                        ...input.number.onResponse,
                        {
                          inputs: [],
                          condition: 'EQUALS',
                          ifValues: [null],
                          createAlert: true,
                          _id: ObjectId().toString(),
                        },
                      ],
                    },
                  });
                  setOpenConditional(null);
                  props.onClose();
                }}
              >
                <ReportProblemOutlined style={{ fontSize: '16px', color: theme.palette.error.main }} />
                <span className={classes.menuItemWithoutCheck}>{props.t('createAlert')}</span>
              </MenuItem>
              <MenuItem
                data-testid={`menu-item-conditional-answer-new-question`}
                classes={{ root: classes.menuItemAdvanced }}
                onClick={(): void => {
                  const id = `new-${
                    moment().toISOString() + '' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
                  }`;

                  let color = -1;
                  if (input.number?.onResponse?.length)
                    input.number?.onResponse?.map(() => {
                      if (color < 3) color++;
                      else color = 0;
                    });
                  else color = 0;

                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: [
                        //@ts-ignore
                        ...input.number.onResponse,
                        {
                          inputs: [
                            //@ts-ignore
                            {
                              order: 0,
                              name: 'New input',
                              type: 'string',
                              required: false,
                              _id: id,
                              string: {
                                options: [],
                                values: [],
                                validations: [],
                                multiple: false,
                                onResponse: [],
                              },
                            },
                          ],
                          color: colors[parseInt(`${color}`)],
                          condition: 'EQUALS',
                          ifValues: [],
                          _id: ObjectId().toString(),
                        },
                      ],
                    },
                  });
                  setOpenConditional(null);
                  props.onClose();
                }}
              >
                <LiveHelpOutlined style={{ fontSize: '16px', color: theme.palette.primary.main }} />
                <span className={classes.menuItemWithoutCheck}>{props.t('answerNewQuestion')}</span>
              </MenuItem>
              <MenuItem
                data-testid={`menu-item-conditional-fail-response`}
                classes={{ root: classes.menuItemAdvanced }}
                onClick={(): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: [
                        ...input.number.onResponse,
                        {
                          inputs: [],
                          condition: 'EQUALS',
                          ifValues: [null],
                          failResponse: true,
                          _id: ObjectId().toString(),
                        },
                      ],
                    },
                  });
                  setOpenConditional(null);
                  props.onClose();
                }}
              >
                <RemoveCircleOutlineOutlined style={{ fontSize: '16px', color: theme.palette.error.main }} />
                <span className={classes.menuItemWithoutCheck}>{props.t('failResponse')}</span>
              </MenuItem>
              <MenuItem
                data-testid={`menu-item-conditional-execute-new-task`}
                classes={{ root: classes.menuItemAdvanced }}
                onClick={(): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: [
                        ...input.number.onResponse,
                        {
                          inputs: [],
                          condition: 'EQUALS',
                          ifValues: [null],
                          createTasks: true,
                          _id: ObjectId().toString(),
                        },
                      ],
                    },
                  });
                  setOpenConditional(null);
                  props.onClose();
                }}
              >
                <InsertDriveFileOutlined style={{ fontSize: '16px' /* color: theme.palette.success.main */ }} />
                <span className={classes.menuItemWithoutCheck}>{props.t('executeNewTask')}</span>
              </MenuItem>
              <MenuItem
                data-testid={`menu-item-conditional-repeat-task`}
                classes={{ root: classes.menuItemAdvanced }}
                onClick={(): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: [
                        ...input.number.onResponse,
                        {
                          inputs: [],
                          ifValues: [null],
                          createTasks: false,
                          _id: ObjectId().toString(),
                          repeatTasks: true,
                          repeatTasksIds: [],
                        },
                      ],
                    },
                  });
                  setOpenConditional(null);
                  props.onClose();
                }}
              >
                <AddCircle style={{ fontSize: '16px', color: theme.palette.success.main }} />
                <span className={classes.menuItemWithoutCheck}>{props.t('repeatTask')}</span>
              </MenuItem>
              <MenuItem
                data-testid={`menu-item-conditional-finish-issue`}
                classes={{ root: classes.menuItemAdvanced }}
                onClick={(): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: [
                        ...input.number.onResponse,
                        {
                          inputs: [],
                          condition: 'EQUALS',
                          ifValues: [null],
                          finishIssue: true,
                          _id: ObjectId().toString(),
                        },
                      ],
                    },
                  });
                  setOpenConditional(null);
                  props.onClose();
                }}
              >
                <HighlightOffOutlined style={{ fontSize: '16px', color: theme.palette.error.main }} />
                <span className={classes.menuItemWithoutCheck}>{props.t('finishIssue')}</span>
              </MenuItem>
            </Popover>
          ) : null}
        </>
      ) : null}
    </Popover>
  );
};

const connector = typedConnect((state) => ({
  isMasterTenant: state.authenticationReducer?.tenantInfo?.master,
}));

export default compose<any>(connector, withTranslation('translation'), withStyles(styles))(NumberPopover);
