import { Button, Grid, MenuItem, Popover, Tooltip, Typography, useTheme } from '@mui/material';
import { Add, ArrowBackIos, Delete, FileCopy, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { withStyles, WithStyles } from '@mui/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { getLabels } from 'src/modules/label/label.redux';
import DefaultButton from 'src/utils/components/default-button';
import { updateIssueTemplate, getTimeMapColumns } from 'src/modules/issue-templates/issue.templates.redux';
import { styles } from 'src/utils/components/heat-map/styles';
import GroupItem from './group-item';
import update from 'immutability-helper';
import ColumnItem from './column-item';
import _, { cloneDeep } from 'lodash';
import { showSnackbar } from 'src/base/root/root.redux';
import {
  checkColumnsOptionsPresence,
  checkLinesOptionsPresence,
  reorderItemsOfColumns,
  updateColumnsAfterChangeLabelGroup,
} from 'src/utils/components/heat-map/utils';
import { Label, LabelValue } from 'src/gql/graphql';

export interface column {
  label: Label;
  labelValue: LabelValue;
}

interface Props extends WithStyles<typeof styles>, ConnectedProps<typeof connector> {
  close: () => void;
  templateID: string;
  isDraft: string;
  colsToEdit: column[][];
  headerNames: string[];
}

let totalCols = 0;
const EditTable = (props: Props): JSX.Element => {
  const { classes, templateID, close } = props;
  const [groupLabels, setGroupLabels] = useState<Label[]>([]);
  const [addGroup, setAddGroup] = useState<boolean>(false);
  const [addColumn, setAddColumn] = useState<boolean>(false);
  const [labelGroupsSelected, setLabelGroupsSelected] = useState<Label[]>([]);
  const [groupPopover, setGroupPopover] = useState<EventTarget | null>(null);
  const [labelPopover, setLabelPopover] = useState<EventTarget | null>(null);
  const [duplicatePopover, setDuplicatePopover] = useState<EventTarget | null>(null);
  const [deletePopover, setDeletePopover] = useState<EventTarget | null>(null);
  const [groupSelected, setGroupSelected] = useState<Label>(null);
  const [columns, setColumns] = useState<column[][]>([]);
  const [changeColumn, setChangeColumn] = useState<any>([]);
  const [changeColumnIdx, setChangeColumnIdx] = useState<any>([]);
  const [idxToDelete, setIdxToDelete] = useState<any>(null);
  const [selectedLabelGroupIdx, setSelectedLabelGroupIdx] = useState<any>(null);
  const [labelValuesIds, setLabelValuesIds] = useState<string[][]>([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const refPopover = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const groupsDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    props.getLabels({ context_eq: 'Task' as any }).then((resp) => {
      setGroupLabels(resp);
      setLabelGroupsSelected(props.headerNames.map((n) => resp.find((r) => r.name === n)));
    });
    setColumns(cloneDeep(props.colsToEdit));
    setAddGroup(true);
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [buttonRef]);

  useEffect(() => {
    // Set labelvaluesids when columns change
    const labelValuesIds = columns.length
      ? columns.map((column) => column.map((item) => (item.labelValue ? item.labelValue._id : null)))
      : [];
    setLabelValuesIds(labelValuesIds);
  }, [columns]);

  const handleAddColumn = () => {
    if (!addColumn) {
      setAddColumn(true);
    }

    let auxValue: column[] = [];
    labelGroupsSelected.map((label) =>
      auxValue.push({
        label: label,
        labelValue: null,
      }),
    );
    setColumns([...columns, auxValue]);
  };

  useEffect(() => {
    if (totalCols !== 0 && totalCols < columns.length) {
      groupsDiv.current.scrollLeft = groupsDiv.current.scrollWidth;
      totalCols = columns.length;
    } else {
      totalCols = columns.length;
    }
  }, [columns.length]);

  const handleSave = async () => {
    const columnsHaveOptions = checkColumnsOptionsPresence(columns);
    if (!columnsHaveOptions) {
      props.showSnackbar('error', t('columnMustHave1SelectedOption'));
      return;
    }

    const linesHaveOptions = checkLinesOptionsPresence(columns);
    if (!linesHaveOptions) {
      props.showSnackbar('error', t('lineMustHave1SelectedOption'));
      return;
    }

    let dataToSave: { labelValues: string[] }[] = [];
    columns.map((column) => {
      let IdsArray = [];
      for (let i = 0; i < labelGroupsSelected.length; i++) {
        const item = column[i];
        if (item?.labelValue) IdsArray.push(item.labelValue?._id);
        else IdsArray.push(null);
      }

      dataToSave.push({ labelValues: IdsArray });
    });

    const timeMapColumn = {
      timeMapColumns: dataToSave,
    };
    const resp = await props.updateIssueTemplate(timeMapColumn, templateID);
    if (resp) {
      close();
    }
  };

  /* const handleClear = async () => {

    setColumns([]);
    setLabelGroupsSelected([]);
    const timeMapColumn = {
      timeMapColumns: [],
    };
    if (isDraft === 'true') {
      await props.updateIssueTemplate(timeMapColumn, templateID);
    } else {
      await props.updateTemplate(timeMapColumn, templateID);
    }
  }; */

  const handleDuplicate = () => {
    let auxArray = [];

    for (let i: number = 0; i < columns.length; i++) {
      // Set labelValuesIds to be changed in real time
      auxArray.push(columns[parseInt(i.toString())]);

      if (columns[parseInt(i.toString())] === changeColumn && i === changeColumnIdx) {
        let duplicated = _.cloneDeep(columns[parseInt(i.toString())]);
        auxArray.push(duplicated);
      }
    }

    setColumns(auxArray);
    setDuplicatePopover(null);
  };

  const handleDelete = () => {
    if (idxToDelete.type === 'group') {
      const updatedLabelGroupsSelected = labelGroupsSelected.filter((_, idx) => idx !== idxToDelete.idx);
      setLabelGroupsSelected(updatedLabelGroupsSelected);

      const updatedColumns = columns.map((column) => column.filter((_, i) => i !== idxToDelete.idx));
      setColumns(updatedColumns);
    } else {
      const updatedColumns = columns.filter((_, idx) => idx !== idxToDelete.idx);
      setColumns(updatedColumns);
    }

    setDuplicatePopover(null);
    setDeletePopover(null);
  };

  const addNewItemToColumns = () => {
    const auxColumns = [];
    columns.map((column) => auxColumns.push([...column, { label: null, labelValue: null }]));
    setColumns(auxColumns);
  };

  const moveItem = useCallback(
    (dragIndex, hoverIndex, type) => {
      if (type === 'line') {
        const dragItem = labelGroupsSelected[dragIndex];

        setLabelGroupsSelected(() =>
          update(labelGroupsSelected, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragItem],
            ],
          }),
        );

        const reorderedColumns = reorderItemsOfColumns(columns, dragIndex, hoverIndex);
        setColumns(reorderedColumns);
      } else {
        const dragItem = columns[dragIndex];

        setColumns(() =>
          update(columns, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragItem],
            ],
          }),
        );
      }
    },
    [labelGroupsSelected, columns],
  );

  const updateLabelGroupsSelectedAndColumns = (columns, selectedLabelGroup, selectedLabelGroupIdx) => {
    setLabelGroupsSelected(labelGroupsSelected.map((lg, i) => (i === selectedLabelGroupIdx ? selectedLabelGroup : lg)));

    const updatedColumns = updateColumnsAfterChangeLabelGroup(columns, selectedLabelGroupIdx);
    setColumns(updatedColumns);
  };

  const setDataOfExistentColumnItems = (data, columnIndex, itemIndex) => {
    setChangeColumn(changeColumn.map((cc, index) => (itemIndex === index ? data : cc)));
    setColumns(
      columns.map((c, k) =>
        columnIndex === k ? c.map((colItem, index) => (itemIndex === index ? data : colItem)) : c,
      ),
    );
  };

  // Change label value option to the selected label value
  const changeLabelValueOption = (selectedLabelValue) => {
    const currentOption = columns[changeColumnIdx][selectedItemIndex];

    // If item exists, just change data of label value
    if (currentOption) {
      const newOption =
        columns[changeColumnIdx][selectedItemIndex]?.labelValue?.value === selectedLabelValue?.value
          ? { labelValue: null, label: null } // No label value selected
          : { labelValue: selectedLabelValue, label: groupSelected }; // Different label value selected

      setDataOfExistentColumnItems(newOption, changeColumnIdx, selectedItemIndex);
    } else {
      // If never existed item on that line of that column
      const newOption = { labelValue: selectedLabelValue, label: groupSelected };
      setChangeColumn([...changeColumn, newOption]);
      setColumns(columns.map((c, k) => (idxToDelete.idx === k ? [...c, newOption] : c)));
    }
  };

  return (
    <div
      style={{
        height: 'calc(100vh - 55px)',
      }}
    >
      <div
        className={classes.rootGrid}
        style={{
          paddingRight: '0px',
          height: 'calc(100vh - 80px)',
          width: '97%',
        }}
      >
        <Grid container spacing={2} style={{ height: '90%' }}>
          <Grid item xs={12}>
            <Button variant='text' onClick={() => close()} className={classes.backBtn}>
              <ArrowBackIos />
              <Typography style={{ fontSize: '18px' }}>{t('editingTable')}</Typography>
            </Button>
          </Grid>
          <div className={classes.scrollableDiv}>
            <div ref={groupsDiv} style={{ width: 'calc(95% - 170px)', flexGrow: 1 }}>
              <div className={classes.minH100} style={{ display: 'flex' }}>
                <div className={`${classes.minH100} ${classes.flexRow}`}>
                  <div className={classes.fixedColumn}>
                    <div className={classes.blankSpace} style={{ height: '35px', position: 'sticky', top: '0' }}>
                      <div style={{ width: '220px' }}></div>
                    </div>
                    {addGroup ? (
                      <div style={{ display: 'inline-table', whiteSpace: 'nowrap', height: 'calc(100% - 35px)' }}>
                        <div className={`${classes.h100} ${classes.groupTable}`}>
                          {labelGroupsSelected.length > 0 ? (
                            <div>
                              {labelGroupsSelected.map((label, idx) => (
                                <GroupItem
                                  // key={label._id}
                                  label={label}
                                  idx={idx}
                                  moveListItem={moveItem}
                                  setSelectedLabelGroupIdx={(idx) => {
                                    setSelectedLabelGroupIdx(idx);
                                  }}
                                  setGroupPopover={(group) => {
                                    setGroupPopover(group);
                                  }}
                                  setDeletePopover={(itemToDelete) => {
                                    setDeletePopover(itemToDelete);
                                  }}
                                  setIdxToDelete={(idxToDelete) => {
                                    setIdxToDelete(idxToDelete);
                                  }}
                                />
                              ))}
                              {/* Verify the possibility to add more groups */}
                              {labelGroupsSelected.length < groupLabels.length ? (
                                <div style={{ paddingBottom: '8px' }} ref={refPopover}>
                                  <Button
                                    variant='text'
                                    onClick={() => {
                                      const newLabel = {
                                        context: null,
                                        folder: null,
                                        labelValues: [],
                                        name: t('selectGroup'),
                                        singleSelection: null,
                                        __typename: 'Label',
                                        _id: (labelGroupsSelected.length + 1).toString(),
                                      } as Label;

                                      addNewItemToColumns();

                                      setLabelGroupsSelected([...labelGroupsSelected, newLabel]);
                                    }}
                                    className={classes.addGroupText}
                                  >
                                    <Add />
                                    {t('addGroup')}
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          ) : (
                            <div>
                              {labelGroupsSelected.length < groupLabels.length ? (
                                <div style={{ paddingBottom: '8px' }} ref={refPopover}>
                                  <Button
                                    variant='text'
                                    onClick={() => {
                                      const newLabel = {
                                        context: null,
                                        folder: null,
                                        labelValues: [],
                                        name: 'Select group',
                                        singleSelection: null,
                                        __typename: 'Label',
                                        _id: (labelGroupsSelected.length + 1).toString(),
                                      } as Label;

                                      addNewItemToColumns();

                                      setLabelGroupsSelected([...labelGroupsSelected, newLabel]);
                                    }}
                                    className={classes.addGroupText}
                                  >
                                    <Add />
                                    {t('addGroup')}
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {columns.length > 0
                    ? columns.map((column, idx) => (
                        <ColumnItem
                          column={column}
                          idx={idx}
                          moveItem={moveItem}
                          columns={columns}
                          labelValuesIdsByColumn={labelValuesIds[idx]}
                          labelGroupsSelected={labelGroupsSelected}
                          setSelectedItemIndex={(e) => {
                            setSelectedItemIndex(e);
                          }}
                          setDuplicatePopover={(e) => {
                            setDuplicatePopover(e);
                          }}
                          setIdxToDelete={(idx) => {
                            setIdxToDelete(idx);
                          }}
                          setChangeColumn={(column) => {
                            setChangeColumn(column);
                            setChangeColumnIdx(idx);
                          }}
                          setLabelPopover={(e) => {
                            setLabelPopover(e);
                            setChangeColumnIdx(idx);
                          }}
                          setGroupSelected={(label) => {
                            setGroupSelected(label);
                          }}
                        />
                      ))
                    : null}
                  <div className={classes.groupTable} style={{ minHeight: '0px', border: 'none' }}>
                    <div
                      className={classes.divHeader}
                      style={{ width: '220px', backgroundColor: theme.palette.common.white }}
                    >
                      <Button
                        variant='text'
                        onClick={handleAddColumn}
                        className={classes.addGroupText}
                        style={{ marginLeft: '0px' }}
                      >
                        <Add />
                        {t('addColumn')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Popover
            open={!!groupPopover}
            // @ts-ignore
            anchorEl={groupPopover}
            onClose={(): void => {
              setGroupPopover(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {groupLabels.length > 0
              ? groupLabels.map((group, idx) => (
                  <MenuItem
                    style={
                      labelGroupsSelected[selectedLabelGroupIdx]?.name === group?.name
                        ? { width: '217px', backgroundColor: theme.palette.info.light }
                        : { width: '217px' }
                    }
                    className={classes.menuItem}
                    onClick={() => {
                      updateLabelGroupsSelectedAndColumns(columns, group, selectedLabelGroupIdx);
                    }}
                    key={idx}
                  >
                    {labelGroupsSelected[selectedLabelGroupIdx]?.name === group?.name ? (
                      <RadioButtonChecked className={classes.radioButtonChecked} />
                    ) : (
                      <RadioButtonUnchecked className={classes.radioButtonUnchecked} />
                    )}
                    <Tooltip
                      title={group.name}
                      arrow
                      placement='top'
                      classes={{ tooltip: props.classes.tooltipCustom, arrow: props.classes.arrowCustom }}
                    >
                      <span
                        className={classes.spanGroup}
                        style={
                          labelGroupsSelected[selectedLabelGroupIdx]?.name === group?.name
                            ? { color: theme.palette.primary.main }
                            : { color: theme.palette.text.primary }
                        }
                      >
                        {group.name}
                      </span>
                    </Tooltip>
                  </MenuItem>
                ))
              : null}
          </Popover>
          {/* Popover to select label value*/}
          <Popover
            open={!!labelPopover}
            // @ts-ignore
            anchorEl={labelPopover}
            onClose={(): void => {
              setLabelPopover(null);
              setIdxToDelete(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {groupSelected ? (
              <>
                {groupSelected.labelValues?.map((lv, idx) => (
                  <MenuItem
                    key={idx}
                    style={
                      changeColumn[selectedItemIndex]?.labelValue?.value === lv.value
                        ? { width: '237px', backgroundColor: theme.palette.info.light }
                        : { width: '237px' }
                    }
                    onClick={() => {
                      changeLabelValueOption(lv);
                      setLabelPopover(null);
                    }}
                    className={classes.menuItem}
                  >
                    <div className={classes.divOption}>
                      {changeColumn[selectedItemIndex]?.labelValue?.value === lv.value ? (
                        <RadioButtonChecked className={classes.radioButtonChecked} />
                      ) : (
                        <RadioButtonUnchecked className={classes.radioButtonUnchecked} />
                      )}
                      <Tooltip
                        title={lv.value}
                        arrow
                        placement='top'
                        classes={{ tooltip: props.classes.tooltipCustom, arrow: props.classes.arrowCustom }}
                      >
                        <span
                          className={classes.addGroupDivText}
                          style={
                            changeColumn[selectedItemIndex]?.labelValue?.value === lv.value
                              ? { color: theme.palette.primary.main }
                              : { color: theme.palette.text.primary }
                          }
                        >
                          {lv.value}
                        </span>
                      </Tooltip>
                    </div>
                  </MenuItem>
                ))}
              </>
            ) : null}
          </Popover>
          {/*Popover to delete or duplicate row*/}
          <Popover
            open={!!duplicatePopover}
            // @ts-ignore
            anchorEl={duplicatePopover}
            onClose={(): void => {
              setDuplicatePopover(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleDelete} className={classes.menuItemDelete}>
              <Delete className={`${classes.iconSize} ${classes.deleteColor}`} />
              <span className={` ${classes.dialogText} ${classes.deleteColor}`}>{t('delete')}</span>
            </MenuItem>
            <MenuItem onClick={handleDuplicate} className={classes.menuItemDuplicate}>
              <FileCopy className={classes.iconSize} />
              <span className={classes.dialogText}>{t('duplicate')}</span>
            </MenuItem>
          </Popover>
          {/*Popover to delete row*/}
          <Popover
            open={!!deletePopover}
            // @ts-ignore
            anchorEl={deletePopover}
            onClose={(): void => {
              setDeletePopover(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleDelete} className={classes.menuItemDelete}>
              <Delete className={`${classes.iconSize} ${classes.deleteColor}`} />
              <span className={` ${classes.dialogText} ${classes.deleteColor}`}>{t('delete')}</span>
            </MenuItem>
          </Popover>
        </Grid>
        <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
          <DefaultButton id='buttonDiscard' discard onClick={close} disabled={columns.length > 0 ? false : true}>
            {t('discard')}
          </DefaultButton>
          <DefaultButton id='buttonApply' success onClick={handleSave} disabled={columns.length > 0 ? false : true}>
            {t('apply')}
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (): object => ({});

const mapDispatchToProps = {
  getLabels,
  updateIssueTemplate,
  getTimeMapColumns,
  showSnackbar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<any>(withStyles(styles), connector)(EditTable);
