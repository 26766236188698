import gql from 'graphql-tag';
import {
  INPUT,
  INPUT_NUMBER,
  INPUT_STRING,
  INPUT_WITH_LOG,
  INPUT_WITH_RECURSIVE,
  ON_RESPONSE_COMMON_NUMBER,
  ON_RESPONSE_COMMON_STRING,
} from 'src/utils/fragments/input';
import {
  ISSUE_TEMPLATE,
  ISSUE_TEMPLATE_FOLDER,
  ISSUE_TEMPLATE_TO_TREE,
  ISSUE_TEMPLATE_DRAFT,
  PRESET,
} from 'src/utils/fragments/issue';
import { LABEL_VALUE } from 'src/utils/fragments/label';

/* export const Q_GET_ISSUE_TEMPLATE_FOLDERS = gql`
  query ($where: IssueTemplateFolderWhereInput) {
    issueTemplateFolders(where: $where, orderBy: name_ASC) {
      ...issueTemplateFolder
    }
  }
  ${ISSUE_TEMPLATE_FOLDER}
`; */

export const M_CREATE_PRESET = gql`
  mutation CreatePreset($templateId: ID!, $preset: PresetWhereInput!) {
    createPreset(templateId: $templateId, preset: $preset) {
      frame {
        ...preset
      }
      taskTemplateGroups {
        taskTemplates {
          variant {
            variantId
            name
            position
          }
          inputs {
            ...inputWithRecursive
          }
        }
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
  ${PRESET}
`;

export const M_EDIT_PRESET = gql`
  mutation EditPreset($templateId: ID!, $where: PresetWhereInput!, $presetId: ID, $presetIds: [ID]) {
    editPreset(templateId: $templateId, presetId: $presetId, presetIds: $presetIds, where: $where) {
      frame {
        ...preset
      }
      taskTemplateGroups {
        taskTemplates {
          variant {
            variantId
            name
            position
          }
          inputs {
            ...inputWithRecursive
          }
        }
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
  ${PRESET}
`;

export const M_CREATE_ISSUE_TEMPLATE_FOLDER = gql`
  mutation CreateIssueTemplateFolder($name: String!, $parent: ID) {
    createIssueTemplateFolder(data: { name: $name, parent: $parent }) {
      ...issueTemplateFolder
    }
  }
  ${ISSUE_TEMPLATE_FOLDER}
`;

export const M_UPDATE_ISSUE_TEMPLATE_FOLDER = gql`
  mutation UpdateIssueTemplateFolder($name: String!, $parent: ID, $_id: ID!) {
    updateIssueTemplateFolder(data: { name: $name, parent: $parent }, where: { _id: $_id }) {
      ...issueTemplateFolder
    }
  }
  ${ISSUE_TEMPLATE_FOLDER}
`;

export const M_DELETE_ISSUE_TEMPLATE_FOLDER = gql`
  mutation DeleteIssueTemplateFolder($where: IssueTemplateFolderWhereUniqueInput!) {
    deleteOneIssueTemplateFolder(where: $where) {
      ...issueTemplateFolder
    }
  }
  ${ISSUE_TEMPLATE_FOLDER}
`;

export const Q_GET_ISSUES_TEMPLATES = gql`
  query GetIssueTemplatesHeatmap($filter: IssueTemplateWhereInput!) {
    issueTemplates(where: $filter) {
      ...issueTemplate
      variant {
        variantId
        description
        name
        position
      }
      updatedBy {
        name
        _id
      }
      updatedAt
      taskTemplateGroups {
        name
        order
        sequential
        _id
        markersToShow
        taskTemplates {
          name
          manualId
          order
          parentsTree
          onResponseId
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          _id
          variant {
            variantId
            name
            position
          }
          isRepeatable
          assignedTools {
            tool {
              designation
              partNumber
              _id
            }
            quantity
          }
          assignedMaterials {
            material {
              code
              name
              _id
            }
            quantity
          }
          arConfiguration {
            markersType
            markersColor
            markersOnFinishingTask
            markersSize
            typeOfInput
            timerFinishTask
          }
          advancedLabelSelection {
            name
            filter {
              includeAllLabels
              conditions {
                operator
                labelValue {
                  value
                  _id
                  backgroundColor
                  label {
                    name
                    _id
                    labelValues {
                      ...labelValue
                    }
                  }
                }
              }
            }
          }
          labelValues {
            ...labelValue
          }
          inputs {
            ...inputWithRecursive
          }
        }
      }
    }
  }
  ${INPUT}
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
  ${ISSUE_TEMPLATE}
  ${LABEL_VALUE}
`;

export const Q_GET_ISSUES_TEMPLATES_LEAN = gql`
  query GetIssueTemplatesLean($filter: IssueTemplateWhereInput!) {
    issueTemplates(where: $filter) {
      ...issueTemplate
      variant {
        variantId
        description
        name
        position
      }
      updatedBy {
        name
        _id
      }
      updatedAt
    }
  }
  ${INPUT}
  ${ISSUE_TEMPLATE}
`;

gql`
  query GetIssueTemplateDraft($filter: IssueTemplateDraftWhereUniqueInput!) {
    issueTemplateDraft(where: $filter) {
      ...issueTemplateDraft
      updateTemplate {
        _id
      }
      taskTemplateGroups {
        name
        order
        sequential
        _id
        markersToShow
        taskTemplates {
          inputs {
            ...input
          }
          onResponseId
          parentsTree
          name
          manualId
          order
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          log {
            type
            action
            component
            from
            to
            time {
              from {
                value
                unit
              }
              to {
                value
                unit
              }
            }
            dateTime
          }
          _id
          variant {
            name
            position
            variantId
          }
          isRepeatable
          assignedTools {
            tool {
              designation
              partNumber
              _id
            }
            quantity
            reserveAtStart
            associatedWith {
              designation
              partNumber
              _id
            }
          }
          assignedMaterials {
            material {
              code
              name
              _id
            }
            quantity
          }
          arConfiguration {
            markersType
            markersColor
            markersOnFinishingTask
            markersSize
            typeOfInput
            timerFinishTask
          }
          advancedLabelSelection {
            name
            filter {
              includeAllLabels
              conditions {
                operator
                labelValue {
                  value
                  _id
                  backgroundColor
                  label {
                    name
                    _id
                    labelValues {
                      ...labelValue
                    }
                  }
                }
              }
            }
          }
          labelValues {
            ...labelValue
          }
          _id
          variant {
            name
            position
            variantId
          }
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          isRepeatable
          inputs {
            ...inputWithRecursive
          }
        }
      }
    }
  }

  query GetIssueTemplateDraftToHeatmap($filter: IssueTemplateDraftWhereUniqueInput!) {
    issueTemplateDraft(where: $filter) {
      title
      _id
      timeMapColumns {
        labelValuesIds
        labelValues {
          ...labelValue
        }
      }
      updateTemplateId
      taskTemplateGroups {
        name
        order
        _id
        taskTemplates {
          parentsTree
          name
          order
          _id
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          variant {
            name
            position
            variantId
          }
        }
      }
    }
  }
`;

export const Q_GET_ISSUES_TEMPLATES_TO_TREE = gql`
  query GetIssueTemplatesTree($filter: IssueTemplateWhereInput!) {
    issueTemplates(where: $filter, orderBy: title_ASC) {
      ...issueTemplateToTree
    }
  }
  ${ISSUE_TEMPLATE_TO_TREE}
`;

export const Q_GET_TASK_TEMPLATE_GROUPS = gql`
  query GetTaskTemplateGroups($where: TaskTemplateGroupWhereInput) {
    taskTemplateGroups(where: $where) {
      name
      order
      sequential
      _id
      taskTemplates {
        name
        manualId
        order
        estimatedDuration
        log {
          type
          from
          to
          time {
            from {
              value
              unit
            }
            to {
              value
              unit
            }
          }
          dateTime
        }
        _id
        advancedLabelSelection {
          name
          filter {
            includeAllLabels
            conditions {
              operator
              labelValue {
                value
                _id
                backgroundColor
                label {
                  name
                  _id
                  labelValues {
                    ...labelValue
                  }
                }
              }
            }
          }
        }
        labelValues {
          ...labelValue
        }
        inputs {
          ...inputWithRecursive
        }
        inputHistory {
          ...inputWithRecursive
        }
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
  ${LABEL_VALUE}
`;

export const M_CREATE_TASK_TEMPLATE_GROUP = gql`
  mutation CreateTaskTemplateGroup($data: TaskGroupCreateInput!, $draft: ID!) {
    createTaskTemplateGroupDraft(data: $data, draft: $draft) {
      _id
      taskTemplateGroups {
        _id
      }
    }
  }
`;

export const M_UPDATE_TASK_TEMPLATE_GROUP = gql`
  mutation UpdateTaskTemplateGroup($data: TaskGroupUpdateInput!, $id: ID!, $draft: ID!) {
    updateTaskTemplateGroupDraft(update: $data, taskTemplateGroup: $id, draft: $draft) {
      _id
    }
  }
`;

export const M_CREATE_ISSUES_TEMPLATE = gql`
  mutation CreateIssueTemplate(
    $title: String!
    $folder: ID!
    $frame: IssueTemplateDraftFrameCreateInput
    $taskTemplateGroups: [IssueTemplateDraftTaskTemplateGroupCreateInput]
  ) {
    createIssueTemplateDraft(
      data: { title: $title, folder: $folder, frame: $frame, taskTemplateGroups: $taskTemplateGroups }
    ) {
      ...issueTemplateDraft
      taskTemplateGroups {
        name
        order
        sequential
        _id
        taskTemplates {
          name
          manualId
          order
          estimatedDuration
          _id
          advancedLabelSelection {
            name
            filter {
              includeAllLabels
              conditions {
                operator
                labelValue {
                  value
                  _id
                  backgroundColor
                  label {
                    name
                    _id
                    labelValues {
                      ...labelValue
                    }
                  }
                }
              }
            }
          }
          labelValues {
            ...labelValue
          }
          inputs {
            ...inputWithRecursive
          }
        }
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
  ${ISSUE_TEMPLATE_DRAFT}
  ${INPUT}
  ${PRESET}
  ${LABEL_VALUE}
`;

export const Q_GET_ISSUES_TEMPLATES_LIBRARY = gql`
  query GetIssueTemplatesLibrary(
    $search: String
    $type: String
    $orderBy: [IssueTemplateOrderByInput!]!
    $skip: Int!
    $limit: Int!
  ) {
    issueTemplateLibrary(search: $search, type: $type, skip: $skip, limit: $limit, orderBy: $orderBy) {
      _id
      title
      folder {
        _id
        name
      }
    }
    issueTemplateLibraryCount(search: $search, type: $type)
  }
`;

export const Q_GET_ISSUES_TEMPLATES_LIBRARY_TYPES = gql`
  query GetIssueTemplatesLibraryTypes {
    templateLibraryFolderNames
  }
`;

export const M_CREATE_DRAFT_FROM_ISSUE_TEMPLATE = gql`
  mutation CreateDraftFromIssueTemplate($id: ID!, $folder: ID) {
    createDraftFromIssueTemplate(_id: $id, newFolder: $folder) {
      _id
    }
  }
`;

export const M_CREATE_COMMIT_ISSUE_TEMPLATE = gql`
  mutation CommitIssueTemplate($id: ID!) {
    upsertIssueTemplateFromDraft(_id: $id) {
      ...issueTemplateToTree
    }
  }
  ${ISSUE_TEMPLATE_TO_TREE}
`;

export const M_CREATE_NEW_VARIANT_ISSUE_TEMPLATE = gql`
  mutation CreateNewVariantIssueTemplate(
    $id: ID!
    $variantName: String!
    $variantDescription: String
    $variantPosition: Int!
  ) {
    upsertIssueTemplateVariantFromDraft(
      _id: $id
      variantName: $variantName
      variantDescription: $variantDescription
      variantPosition: $variantPosition
    ) {
      ...issueTemplateToTree
    }
  }
  ${ISSUE_TEMPLATE_TO_TREE}
`;

export const M_UPDATE_ISSUES_TEMPLATE = gql`
  mutation UpdateIssueTemplateDraft($data: IssueTemplateDraftUpdateInput!, $_id: ID!) {
    updateIssueTemplateDraft(data: $data, where: { _id: $_id }) {
      _id
      frame {
        ...preset
        scanMapping {
          label {
            _id
            name
          }
          numberCharacters
          thisForward
          isMultiEntry
          selectedOption
          firstDelimiter
          lastDelimiter
          delimiter
        }
      }
      favorites {
        name
        result {
          value
          _id
          backgroundColor
          label {
            name
            _id
          }
        }
        resultByCondition {
          value
          _id
          backgroundColor
          label {
            name
            _id
          }
        }
        isForAttribution
        _id
      }
      taskTemplateGroups {
        name
        order
        sequential
        _id
        markersToShow
        taskTemplates {
          onResponseId
          parentsTree
          name
          manualId
          order
          log {
            type
            action
            component
            from
            to
            time {
              from {
                value
                unit
              }
              to {
                value
                unit
              }
            }
            dateTime
          }
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          _id
          variant {
            name
            position
            variantId
          }
          isRepeatable
          assignedTools {
            tool {
              designation
              partNumber
              _id
            }
            quantity
          }
          assignedMaterials {
            material {
              code
              name
              _id
            }
            quantity
          }
          arConfiguration {
            markersType
            markersColor
            markersOnFinishingTask
            markersSize
            typeOfInput
            timerFinishTask
          }
          advancedLabelSelection {
            name
            filter {
              includeAllLabels
              conditions {
                operator
                labelValue {
                  value
                  _id
                  backgroundColor
                  label {
                    name
                    _id
                    labelValues {
                      ...labelValue
                    }
                  }
                }
              }
            }
          }
          labelValues {
            ...labelValue
          }
          inputs {
            ...inputWithRecursive
          }
        }
      }
      timeTableFilters {
        name
        filter {
          includeAllLabels
          conditions {
            operator
            labelValue {
              value
              _id
              backgroundColor
              label {
                name
                _id
                labelValues {
                  ...labelValue
                }
              }
            }
          }
        }
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${ON_RESPONSE_COMMON_STRING}
  ${INPUT_NUMBER}
  ${INPUT_STRING}
  ${INPUT_WITH_LOG}
  ${PRESET}
  ${LABEL_VALUE}
`;

export const M_UPDATE_TEMPLATE = gql`
  mutation UpdateIssueTemplate($data: IssueTemplateUpdateInput!, $_id: ID!) {
    updateIssueTemplate(data: $data, where: { _id: $_id }) {
      _id
      favorites {
        name
        result {
          value
          _id
          label {
            name
            _id
          }
        }
        resultByCondition {
          value
          _id
          backgroundColor
          label {
            name
            _id
          }
        }
        isForAttribution
        _id
      }
      timeTableFilters {
        name
        _id
        filter {
          includeAllLabels
          conditions {
            operator
            labelValue {
              value
              _id
              backgroundColor
              label {
                name
                _id
                labelValues {
                  ...labelValue
                }
              }
            }
          }
        }
      }
      timeMapColumns {
        labelValues {
          _id
        }
      }
    }
  }
  ${LABEL_VALUE}
`;

export const M_UPDATE_ISSUES_FOLDER_TEMPLATE = gql`
  mutation UpdateIssueFolderTemplate($title: String!, $folder: ID, $_id: ID!, $inputs: [InputUpdateInput!]) {
    updateIssueTemplate(data: { title: $title, folder: $folder, frame: { inputs: $inputs } }, where: { _id: $_id }) {
      ...issueTemplateToTree
    }
  }
  ${ISSUE_TEMPLATE_TO_TREE}
`;

export const M_DELETE_ISSUES_TEMPLATE = gql`
  mutation DeleteIssueTemplate($id: ID!) {
    deleteOneIssueTemplate(where: { _id: $id }) {
      ...issueTemplateToTree
    }
  }
  ${ISSUE_TEMPLATE_TO_TREE}
`;

export const M_DELETE_ISSUE_TEMPLATE_DRAFT = gql`
  mutation DeleteIssueTemplateDraft($id: ID!) {
    deleteOneIssueTemplateDraft(where: { _id: $id }) {
      _id
      folder {
        _id
      }
    }
  }
`;

export const M_DELETE_TASK_TEMPLATE_GROUP = gql`
  mutation DeleteTaskTemplateGroup($ids: [ID!]!, $draftId: ID!) {
    deleteManyTaskTemplateGroupDrafts(_ids: $ids, draftId: $draftId) {
      _id
      taskTemplateGroups {
        name
        order
        sequential
        _id
        markersToShow
        taskTemplates {
          onResponseId
          parentsTree
          name
          manualId
          order
          log {
            type
            action
            component
            from
            to
            time {
              from {
                value
                unit
              }
              to {
                value
                unit
              }
            }
            dateTime
          }
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          _id
          variant {
            name
            position
            variantId
          }
          isRepeatable
          assignedTools {
            tool {
              designation
              partNumber
              _id
            }
            quantity
          }
          assignedMaterials {
            material {
              code
              name
              _id
            }
            quantity
          }
          arConfiguration {
            markersType
            markersColor
            markersOnFinishingTask
            markersSize
            typeOfInput
            timerFinishTask
          }
          advancedLabelSelection {
            name
            filter {
              includeAllLabels
              conditions {
                operator
                labelValue {
                  value
                  _id
                  backgroundColor
                  label {
                    name
                    _id
                    labelValues {
                      ...labelValue
                    }
                  }
                }
              }
            }
          }
          labelValues {
            ...labelValue
          }
          inputs {
            ...inputWithRecursive
          }
        }
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${INPUT_STRING}
  ${INPUT_NUMBER}
  ${INPUT_WITH_LOG}
  ${ON_RESPONSE_COMMON_STRING}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${LABEL_VALUE}
`;

export const M_UPDATE_TASK_TEMPLATE_GROUP_DRAFTS_ORDER = gql`
  mutation UpdateTaskTemplateGroupDraftsOrder($ids: [ID!]!, $issueDraft: ID!) {
    updateTaskTemplateGroupDraftsOrder(taskTemplateGroups: $ids, draft: $issueDraft) {
      _id
      taskTemplateGroups {
        name
        order
        sequential
        _id
        markersToShow
        taskTemplates {
          onResponseId
          parentsTree
          name
          manualId
          order
          log {
            type
            action
            component
            from
            to
            time {
              from {
                value
                unit
              }
              to {
                value
                unit
              }
            }
            dateTime
          }
          estimatedDuration
          estimatedDurationDivided {
            valueAdded
            nonValueAdded
            waste
          }
          _id
          variant {
            name
            position
            variantId
          }
          isRepeatable
          assignedTools {
            tool {
              designation
              partNumber
              _id
            }
            quantity
          }
          assignedMaterials {
            material {
              code
              name
              _id
            }
            quantity
          }
          arConfiguration {
            markersType
            markersColor
            markersOnFinishingTask
            markersSize
            typeOfInput
            timerFinishTask
          }
          advancedLabelSelection {
            name
            filter {
              includeAllLabels
              conditions {
                operator
                labelValue {
                  value
                  _id
                  backgroundColor
                  label {
                    name
                    _id
                    labelValues {
                      ...labelValue
                    }
                  }
                }
              }
            }
          }
          labelValues {
            ...labelValue
          }
          inputs {
            ...inputWithRecursive
          }
        }
      }
    }
  }
  ${INPUT_WITH_RECURSIVE}
  ${INPUT_STRING}
  ${INPUT_NUMBER}
  ${INPUT_WITH_LOG}
  ${ON_RESPONSE_COMMON_STRING}
  ${ON_RESPONSE_COMMON_NUMBER}
  ${LABEL_VALUE}
`;

export const Q_SEARCH = gql`
  query SearchIssuetemplates($searchString: String) {
    issueTemplateFolders(where: { name_contains: $searchString }) {
      ...issueTemplateFolder
    }
    issueTemplates(where: { folder: { name_contains: $searchString } }) {
      ...issueTemplateToTree
    }
  }
  ${ISSUE_TEMPLATE_FOLDER}
  ${ISSUE_TEMPLATE_TO_TREE}
`;

export const M_TASK_GROUP_DRAFTS_LABEL_FILTER = gql`
  mutation TaskGroupDraftsLabelFilter($_id: ID!, $filter: [TaskTemplateAdvancedLabelSelectionFilterCreateInput]) {
    taskGroupDraftsByLabelFilter(_id: $_id, filter: $filter) {
      taskTemplateGroups {
        name
        order
        sequential
        _id
        taskTemplates {
          _id
          show
          order
        }
      }
    }
  }
`;

export const M_TASK_GROUP_LABEL_FILTER = gql`
  query TaskGroupLabelFilter($_id: ID!, $filter: [TaskTemplateAdvancedLabelSelectionFilterCreateInput]) {
    taskTemplateGroupsByLabelFilter(_id: $_id, filter: $filter) {
      taskTemplateGroups {
        name
        order
        sequential
        _id
        taskTemplates {
          _id
          show
          order
        }
      }
    }
  }
`;

export const Q_GET_TIME_TABLE = gql`
  query GetTimetable($_id: ID!) {
    getTimeTable(_id: $_id) {
      columns {
        groupId
        min
        max
      }
    }
  }
`;

export const Q_GET_DRAFT_TIME_TABLE = gql`
  query GetDraftTimeTable($_id: ID!) {
    getDraftTimeTable(_id: $_id) {
      columns {
        groupId
        min
        max
      }
    }
  }
`;

export const Q_GET_TIME_MAP = gql`
  query GetTimeMap($_id: ID!) {
    getTimeMap(_id: $_id) {
      columns {
        groupId
        time
      }
    }
  }
`;

export const Q_GET_DRAFT_TIME_MAP = gql`
  query GetDraftTimeMap($_id: ID!) {
    getDraftTimeMap(_id: $_id) {
      columns {
        groupId
        time
      }
    }
  }
`;

export const M_DUPLICATE_ISSUE_TEMPLATE = gql`
  mutation DuplicateIssueTemplate($_id: ID!, $copyChildren: Boolean!) {
    duplicateIssueTemplate(_id: $_id, copyChildren: $copyChildren) {
      ...issueTemplate
    }
  }
  ${INPUT}
  ${ISSUE_TEMPLATE}
`;

export const Q_GET_DRAFT_TIME_MAP_COLUMNS = gql`
  query GetDraftTimeMapColumns($filter: IssueTemplateDraftWhereUniqueInput!) {
    issueTemplateDraft(where: $filter) {
      timeMapColumns {
        labelValuesIds
        labelValues {
          ...labelValue
        }
      }
    }
  }
  ${LABEL_VALUE}
`;

export const Q_GET_TIME_MAP_COLUMNS = gql`
  query GetTimeMapColumns($filter: IssueTemplateWhereInput!) {
    issueTemplates(where: $filter) {
      timeMapColumns {
        labelValuesIds
        labelValues {
          value
          labelId
          _id
          color
          backgroundColor
          label {
            name
            _id
            labelValues {
              ...labelValue
            }
          }
        }
      }
    }
  }
  ${LABEL_VALUE}
`;

export const Q_GET_ISSUE_CATALOGS_COUNT = gql`
  query GetIssueCatalogsCount($filter: IssueCatalogWhereInput!) {
    issueCatalogsCount(where: $filter)
  }
`;

export const Q_GET_ISSUE_TEMPLATES_COUNT = gql`
  query GetIssueTemplatesCount($filter: IssueTemplateWhereInput!) {
    issueTemplatesCount(where: $filter)
  }
`;

export const Q_GET_ISSUE_TEMPLATE_FOLDERS_COUNT = gql`
  query GetIssueTemplateFoldersCount(
    $whereFolder: IssueTemplateFolderWhereInput
    $whereIssueTemplate: IssueTemplateWhereInput
  ) {
    issueTemplateFoldersCount(where: $whereFolder)
    issueTemplatesCount(where: $whereIssueTemplate)
  }
`;

export const Q_GET_ISSUE_TEMPLATE_FOLDER = gql`
  query GetIssueTemplateFolder($where: IssueTemplateFolderWhereUniqueInput!) {
    issueTemplateFolder(where: $where) {
      name
      _id
      parentsTree {
        name
        _id
      }
    }
  }
`;

export const Q_GET_ISSUE_TEMPLATE_FOLDERS = gql`
  query GetIssueTemplateFolders(
    $limitFolder: Int
    $limitIssueTemplate: Int
    $skipFolder: Int
    $skipIssueTemplate: Int
    $whereFolder: IssueTemplateFolderWhereInput
    $whereIssueTemplate: IssueTemplateWhereInput
  ) {
    issueTemplateFolders(limit: $limitFolder, skip: $skipFolder, where: $whereFolder, orderBy: name_ASC) {
      __typename
      _id
      context
      name
      protected
      issueTemplateFoldersCount
      issueTemplatesCount
      parentsTree {
        _id
        name
      }
      parent {
        _id
        name
      }
    }
    issueTemplates(
      skip: $skipIssueTemplate
      limit: $limitIssueTemplate
      where: $whereIssueTemplate
      orderBy: title_ASC
    ) {
      title
      createdBy {
        name
        _id
      }
      variant {
        variantId
        name
        description
      }
      updatedAt
      _id
    }
  }
`;

export const Q_GET_ISSUE_TEMPLATES = gql`
  query GetIssueTemplates(
    $limitIssueTemplate: Int
    $skipIssueTemplate: Int
    $whereIssueTemplate: IssueTemplateWhereInput
  ) {
    issueTemplates(
      skip: $skipIssueTemplate
      limit: $limitIssueTemplate
      where: $whereIssueTemplate
      orderBy: title_ASC
    ) {
      selectedVariant
      title
      createdBy {
        name
        _id
      }
      variant {
        variantId
        name
        description
      }
      updatedAt
      _id
    }
  }
`;

export const Q_GET_ISSUE_TEMPLATE_FOLDERS_ONLY = gql`
  query GetIssueTemplateFoldersOnly($limitFolder: Int, $skipFolder: Int, $whereFolder: IssueTemplateFolderWhereInput) {
    issueTemplateFolders(limit: $limitFolder, skip: $skipFolder, where: $whereFolder, orderBy: name_ASC) {
      __typename
      _id
      context
      name
      protected
      issueTemplateFoldersCount
      issueTemplatesCount
      parentsTree {
        _id
        name
      }
      parent {
        _id
        name
      }
    }
  }
`;

export const Q_GET_ISSUE_TEMPLATES_AND_FOLDERS = gql`
  query GetIssueTemplatesAndFolders(
    $skip: Int
    $limit: Int
    $templateWhere: IssueTemplateWhereInput
    $templateFolderWhere: IssueTemplateFolderWhereInput
    $orderByTemplate: [IssueTemplateOrderByInput!]!
    $orderByTemplateFolder: [IssueTemplateFolderOrderByInput!]!
    $tenant: ID
  ) {
    issueTemplatesAndFolders(
      skip: $skip
      limit: $limit
      templateWhere: $templateWhere
      templateFolderWhere: $templateFolderWhere
      templateOrderBy: $orderByTemplate
      templateFolderOrderBy: $orderByTemplateFolder
      tenant: $tenant
    ) {
      ... on IssueTemplate {
        __typename
        _id
        title
        folder {
          _id
          name
        }
        folderId
      }
      ... on IssueTemplateFolder {
        _id
        __typename
        name
        parentsTreeIds
        parentsTree {
          name
          _id
        }
        issueTemplateFoldersCount
        totalFolders: issueTemplateFoldersCount
        issueTemplateFoldersCount
        totalItems: issueTemplatesCount
      }
    }
  }
`;

export const Q_GET_ISSUE_TEMPLATE_FOLDERS_TREE = gql`
  query GetIssueTemplateFoldersTree($limit: Int, $skip: Int, $where: IssueTemplateFolderWhereInput) {
    issueTemplateFolders(skip: $skip, limit: $limit, where: $where, orderBy: name_ASC) {
      name
      _id
      parent {
        name
        _id
      }
      parentsTree {
        name
        _id
      }
      issueTemplateFoldersCount
    }
  }
`;

export const M_UPDATE_MANY_ISSUE_TEMPLATES = gql`
  mutation UpdateManyIssueTemplates($where: IssueTemplateWhereInput!, $update: IssueTemplateUpdateInput!) {
    updateManyIssueTemplates(where: $where, update: $update) {
      _id
      title
    }
  }
`;

export const M_MOVE_ISSUE_TEMPLATES = gql`
  mutation MoveIssueTemplates($_ids: [ID!]!, $folder: ID!) {
    moveIssueTemplates(_ids: $_ids, folder: $folder) {
      _id
    }
  }
`;

export const M_DELETE_ISSUE_TEMPLATE_FOLDERS = gql`
  mutation DeleteIssueTemplateFolders($_ids: [ID]) {
    deleteManyIssueTemplateFolders(_ids: $_ids)
  }
`;

export const Q_GET_ISSUES_TEMPLATES_BY_FATHER_ID = gql`
  query GetIssueTemplatesByFatherId($filter: IssueTemplateWhereInput!) {
    issueTemplates(where: $filter) {
      variant {
        variantId
      }
    }
  }
`;

export const Q_GET_HEATMAP_CONFIGURATIONS = gql`
  query GetHeatmapConfigurations($filter: HeatmapConfigurationWhereInput!) {
    heatmapConfigurations(where: $filter) {
      _id
      issueTemplateId
      taskTime
      colorsRange {
        min
        max
        color
      }
    }
  }
`;

export const Q_GET_HEATMAP_CONFIGURATION_DRAFTS = gql`
  query GetHeatmapConfigurationDrafts($filter: HeatmapConfigurationDraftWhereInput) {
    heatmapConfigurationDrafts(where: $filter) {
      _id
      issueTemplateId
      issueTemplateDraftId
      taskTime
      colorsRange {
        min
        max
        color
      }
    }
  }
`;

export const M_CREATE_HEATMAP_CONFIGURATION = gql`
  mutation CreateHeatmapConfiguration($data: HeatmapConfigurationCreateInput!) {
    createHeatmapConfiguration(data: $data) {
      _id
      issueTemplateId
      taskTime
      colorsRange {
        min
        max
        color
      }
    }
  }
`;

export const M_CREATE_HEATMAP_CONFIGURATION_DRAFT = gql`
  mutation CreateHeatmapConfigurationDraft($data: HeatmapConfigurationDraftCreateInput!) {
    createHeatmapConfigurationDraft(data: $data) {
      _id
      issueTemplateId
      issueTemplateDraftId
      taskTime
      colorsRange {
        min
        max
        color
      }
    }
  }
`;

export const M_UPDATE_HEATMAP_CONFIGURATION = gql`
  mutation UpdateHeatmapConfiguration($data: HeatmapConfigurationUpdateInput!, $_id: ID!) {
    updateHeatmapConfiguration(data: $data, where: { _id: $_id }) {
      _id
      issueTemplateId
      taskTime
      colorsRange {
        min
        max
        color
      }
    }
  }
`;

export const M_UPDATE_HEATMAP_CONFIGURATION_DRAFT = gql`
  mutation UpdateHeatmapConfigurationDraft($data: HeatmapConfigurationDraftUpdateInput!, $_id: ID!) {
    updateHeatmapConfigurationDraft(data: $data, where: { _id: $_id }) {
      _id
      issueTemplateId
      issueTemplateDraftId
      taskTime
      colorsRange {
        min
        max
        color
      }
    }
  }
`;

export const M_DELETE_HEATMAP_CONFIGURATION_DRAFT = gql`
  mutation DeleteHeatmapConfigurationDraft($id: ID!) {
    deleteOneHeatmapConfigurationDraft(where: { _id: $id }) {
      _id
    }
  }
`;

export const M_DELETE_HEATMAP_CONFIGURATION = gql`
  mutation DeleteHeatmapConfiguration($id: ID!) {
    deleteOneHeatmapConfiguration(where: { _id: $id }) {
      _id
    }
  }
`;

export const M_DELETE_MANY_ISSUE_TEMPLATES = gql`
  mutation DeleteManyIssueTemplates($where: IssueTemplateWhereInput!) {
    deleteManyIssueTemplates(where: $where)
  }
`;

export const M_UPDATE_PERIODIC_ISSUE_TEMPLATE_DRAFT = gql`
  mutation UpdatePeriodicIssueTemplateDraft(
    $draftId: ID!
    $taskMap: [TaskTemplateMapUpdateInput!]!
    $groupMap: [GroupMapUpdateInput!]!
    $otherDraftAttributes: DraftAttributesUpdateInput
  ) {
    updatePeriodicIssueTemplateDraft(
      draftId: $draftId
      taskMap: $taskMap
      groupMap: $groupMap
      otherDraftAttributes: $otherDraftAttributes
    )
  }
`;
