import { Config, fromFlux, Plot } from '@influxdata/giraffe/dist';
import { newTable } from '@influxdata/giraffe/dist';
import { useMemo, memo } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Label } from 'recharts';
import { sortBy } from 'lodash';
import {
  calculateTotal,
  convertToPercentage,
  formatTimeUnit,
  percentageFormatter,
  renderPercentage,
} from '../../widget-helper';
import { run } from 'src/utils/funcs';
import { useTranslation } from 'react-i18next';
import styles from '../bar/helpers/styles';
import { withStyles } from '@mui/styles';

const LineChartWidget = (props: any) => {
  const { t } = useTranslation();
  const { data, text } = useMemo(() => {
    let text;
    let data;
    let total;

    if (props.dataType === 'realtime') {
      text = props.result;
    } else {
      try {
        total = calculateTotal(props.result);
        data = sortBy(
          props.result?.map((inp) => {
            if (inp.metadata) {
              return {
                name: inp.metadata?.[inp.name]?.name || inp._id,
                value: convertToPercentage(inp[inp.name], total, props.showAsPercentage),
              };
            }

            return {
              name: inp.name || inp._id,
              value: convertToPercentage(inp[inp.name] || inp.count || inp.value || 0, total, props.showAsPercentage),
            };
          }) ?? [],
          'name',
        );
      } catch (e) {
        console.error(e);
      }
    }

    return { text, data, total };
  }, [props.result, props.dataType, props.showAsPercentage]);

  const { table } = useMemo(
    () => (props.dataType === 'realtime' && text?.length ? fromFlux(text) : { table: newTable(0) }),
    [props.dataType, text],
  );

  const tagValue = table.columnKeys?.filter((k) => k === '_value (number)').length > 0 ? '_value (number)' : '_value';

  const config: Config = {
    table: table.length > 0 ? table : newTable(0),
    valueFormatters: {
      [tagValue]: (y) => {
        return `${Math.round(y * 1000) / 1000}`;
      },
    },
    xScale: 'linear',
    yScale: 'linear',
    yAxisLabel:
      props.dataset.realtime?.filters
        .at(0)
        ?.tags?.flatMap((t) => t.name ?? [])
        .join(' - ') ?? '',
    layers: [
      {
        type: 'line',
        x: '_time',
        y: tagValue,
        fill: ['name'],
        colors: props.palette,
      },
    ],
  };

  if (props.dataType === 'realtime' && !props.result) {
    throw new Error('Error fetching influxdb data');
  }

  const yLabel = useMemo(() => {
    const timeUnit = props.dataset[props.dataType].timeUnit ? t(props.dataset[props.dataType].timeUnit + 's') : null;

    return `${timeUnit ?? ''}`.trim();
  }, [props.dataType, props.dataset]);

  return (
    <ResponsiveContainer id={props.id} width={'100%'} height='100%'>
      {props.dataType === 'realtime' ? (
        <Plot config={config} />
      ) : (
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            allowDecimals
            tickFormatter={(value, index) => {
              if (!data) {
                return null;
              }

              switch (props.by) {
                case 'time': {
                  const date = data[index].name;
                  return formatTimeUnit(date, props.groupBy || props.dataset[props.dataType].filter.groupBy, value);
                }

                case 'labelValues':
                  return `${data[index].metadata.label.name}: ${data[index].metadata.value}`;
                default:
                  return value;
              }
            }}
            dataKey='name'
          >
            {props.showLegend && (
              <Label value={t(props.dataset[props.dataType].filter?.groupBy)} offset={0} position='insideBottom' />
            )}
          </XAxis>
          <YAxis allowDecimals tickFormatter={percentageFormatter(props.showAsPercentage)} dataKey={'value'}>
            {props.showLegend && <Label angle={-90} value={t(props.dataType)} offset={0} position='insideLeft' />}
          </YAxis>
          <Tooltip
            content={({ active, payload, label }) => {
              if (!active || !payload?.length) {
                return null;
              }

              const lbl = run(() => {
                const date = props.groupBy ? label : undefined;
                if (props.groupBy || props.dataset[props.dataType].filter.groupBy) {
                  return formatTimeUnit(date, props.groupBy || props.dataset[props.dataType].filter.groupBy, label);
                }
                return label;
              });

              return (
                <div className={props.classes.tooltip}>
                  <span>{lbl}</span>
                  <span>
                    {renderPercentage(parseFloat(payload[0].value.toString()), props.showAsPercentage)} {yLabel}
                  </span>
                </div>
              );
            }}
          />
          <Line isAnimationActive={false} type='monotone' dataKey={'value'} stroke='#0078FE' />
          <Tooltip formatter={(value) => renderPercentage(parseFloat(value as any), props.showAsPercentage)} />
        </LineChart>
      )}
    </ResponsiveContainer>
  );
};

export default withStyles(styles)(memo(LineChartWidget));
