import {
  ArrowDropDown,
  CallSplit,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  InsertDriveFileOutlined,
} from '@mui/icons-material';
import { Chip, Divider, MenuItem, Popover } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';
import { styles } from 'src/utils/components/generic-input-card/styles';

const colors = ['#B468FF', '#00BF9D', '#FE8947', '#ACED24'];
const backgroundColors: string[] = [
  'rgba(180, 104, 255, 0.13)',
  'rgba(0, 191, 157, 0.13)',
  'rgba(254, 137, 71, 0.13)',
  'rgba(172, 237, 36, 0.13)',
];

interface CreateTaskChipProps extends WithTranslation, WithStyles<typeof styles>, WithIssueTemplateConnectorProps {
  active: boolean;
  associateTaskToInput: (responseId, task, associate) => Promise<void>;
  addTask: (order, group, taskId, onResponseId) => void;
  input: { type: string };
  setInput: (val) => void;
  clearOnResponseIdOnTask;
}

const CreateTaskChip = (props: CreateTaskChipProps) => {
  const {
    classes,
    input,
    setInput,
    active,
    t,
    addTask,
    selectedTemplateGroup,
    templateGroups,
    selectedTask,
    updateIssueTemplateDraftState,
    clearOnResponseIdOnTask,
  } = props;
  const [openSelectTaskPopover, setOpenSelectTaskPopover] = useState<{ onResponseId: string; anchor: Element }>(null);

  const showChip = (response: { createTasks: boolean; _id: string; ifValues: { label: string }[] }, i): JSX.Element => {
    const color: number = i;
    if (input.type === 'selection' && response.createTasks) {
      return (
        <Chip
          style={{ margin: '5px' }}
          icon={<CallSplit classes={{ root: classes.iconAlertChip }} />}
          label={
            <div className={classes.divLabel}>
              {props.t('if')}
              <span
                style={{
                  backgroundColor: backgroundColors[parseInt(`${color}`)],
                  color: colors[parseInt(`${color}`)],
                }}
                className={classes.firstSpanAlert}
              >
                {response.ifValues[0].label}
              </span>
              {props.t('selected')}
              <span className={classes.secondSpanCreateTask}>
                <InsertDriveFileOutlined style={{ fontSize: '15px' }} />
                {props.t('executeNewTask')}
              </span>
              {selectedTemplateGroup?.taskTemplates
                ?.filter((t) => t.onResponseId === response._id && t.variant.position === 0)
                ?.map((t) => <span className={classes.secondSpanCreateTask}>{t.name}</span>)}
              {!active ? null : (
                <>
                  <ArrowDropDown
                    id='arrowDropDown'
                    classes={{ root: classes.createTaskDropdownIcon }}
                    onClick={(e) => {
                      setOpenSelectTaskPopover({ anchor: e.currentTarget, onResponseId: response._id });
                    }}
                  />
                  <Close
                    id='chipClose'
                    classes={{ root: classes.closeIcon }}
                    onClick={async (): Promise<void> => {
                      const ts = selectedTemplateGroup?.taskTemplates.filter((t) => t.onResponseId === response._id);

                      for (let i = 0; i < ts.length; i++) {
                        await props.associateTaskToInput(response._id, ts[i], false);
                      }

                      const clonedInput = cloneDeep(input);
                      const onResponse = clonedInput[input.type].onResponse.find(
                        (elem) => elem.ifValues[0].label === response.ifValues[0].label,
                      );
                      if (
                        onResponse.inputs.length ||
                        onResponse.actionRequired ||
                        onResponse.createAlert ||
                        onResponse.finishIssue
                      ) {
                        onResponse.createTasks = false;
                      } else if (onResponse.createTasks) {
                        clearOnResponseIdOnTask(onResponse._id);
                        onResponse.createTasks = false;
                      } else {
                        clonedInput[props.input.type].onResponse = clonedInput[props.input.type].onResponse.filter(
                          (elem) => elem.ifValues[0].label !== response.ifValues[0].label,
                        );
                      }

                      setInput({
                        ...clonedInput,
                      });
                    }}
                  />
                </>
              )}
            </div>
          }
          classes={{ root: classes.alertChipExecution }}
        />
      );
    } else return <div />;
  };

  return (
    <>
      <div className={classes.createTaskChip}>
        {input[input.type].onResponse
          ?.filter((r) => r.createTasks)
          .map((response, i): JSX.Element => showChip(response, i))}
      </div>
      {openSelectTaskPopover ? (
        <Popover
          open={!!openSelectTaskPopover}
          anchorEl={openSelectTaskPopover.anchor}
          onClose={(): void => {
            setOpenSelectTaskPopover(null);
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {selectedTemplateGroup?.taskTemplates
            .filter(
              (t) =>
                t._id !== props.selectedTask._id &&
                (!t.onResponseId || openSelectTaskPopover.onResponseId === t.onResponseId) &&
                !selectedTemplateGroup?.taskTemplates.find(
                  (tt) => tt.parentsTree?.length && tt.parentsTree[0] === t._id,
                ) &&
                t.variant.position === 0,
            )
            .map((task) => (
              <MenuItem
                id={'menuItem' + task.name}
                data-testid={'menuItem' + task.name}
                classes={{ root: classes.createTaskMenuItem }}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await props.associateTaskToInput(
                    openSelectTaskPopover.onResponseId,
                    task,
                    task.onResponseId !== openSelectTaskPopover.onResponseId,
                  );
                  updateIssueTemplateDraftState({
                    taskTemplateGroups: templateGroups.map((tempGroup) => {
                      if (tempGroup._id !== selectedTemplateGroup._id) {
                        return tempGroup;
                      } else {
                        return {
                          ...tempGroup,
                          taskTemplates: tempGroup.taskTemplates.map((t) =>
                            t._id === task._id
                              ? {
                                  ...t,
                                  onResponseId:
                                    task.onResponseId !== openSelectTaskPopover.onResponseId
                                      ? openSelectTaskPopover.onResponseId
                                      : null,
                                  parentsTree: t.parentsTree
                                    ? task.onResponseId !== openSelectTaskPopover.onResponseId
                                      ? [...t.parentsTree, selectedTask._id]
                                      : t.parentsTree.filter((i) => i !== selectedTask._id)
                                    : t.parentsTree,
                                }
                              : t,
                          ),
                        };
                      }
                    }),
                  });
                }}
              >
                <div key={task._id} style={{ display: 'flex', alignItems: 'center' }}>
                  {task.onResponseId === openSelectTaskPopover.onResponseId ? (
                    <CheckBox classes={{ root: classes.createTaskCheck }} />
                  ) : (
                    <CheckBoxOutlineBlank classes={{ root: classes.createTaskCheckEmpty }} />
                  )}
                </div>
                {task.name}
              </MenuItem>
            ))}
          <Divider />
          <MenuItem
            id={'addNewTask'}
            data-testid={'addNewTask'}
            classes={{ root: classes.createTaskMenuItem }}
            onClick={(e) => {
              e.stopPropagation();
              addTask(
                selectedTemplateGroup.taskTemplates.length,
                selectedTemplateGroup,
                props.selectedTask._id,
                openSelectTaskPopover.onResponseId,
              );
            }}
          >
            {`+ ${t('addNewTask')}`}
          </MenuItem>
        </Popover>
      ) : undefined}
    </>
  );
};

export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  withIssueTemplateConnector,
)(CreateTaskChip);
