import { Add, Close } from '@mui/icons-material';
import { Box, Button, Divider, Modal, Popover, Typography } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { OptionInputType, PresetWhereInput } from 'src/gql/graphql';
import { Input } from 'src/interfaces/inputs';
import { createPreset } from 'src/modules/issue-templates/issue.templates.redux';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { styles } from 'src/utils/components/selection-presets-popover/components/response-settings-popover/styles';
import ResponseItem from './components/response-item';

type ResponseSettingsPopoverProps = {
  classes: Record<string, string>;
  openEditResponses: boolean;
  selectedPreset: PresetWhereInput;
  addPreset: boolean;
  presets: PresetWhereInput[];
  updatedPresets: PresetWhereInput[];
  input: Input;
  templateId: string;
  originalResponses: OptionInputType[];
  setOpenEditResponses: (val: boolean) => void;
  setSelectedPreset: (preset: PresetWhereInput) => void;
  setAddPreset: (val: boolean) => void;
  setChangesSubmited: (val: boolean) => void;
  setOpen: (val: boolean) => void;
} & ConnectedProps<typeof connecter> &
  WithIssueTemplateConnectorProps &
  WithStyles<typeof styles>;

const ResponseSettingsPopover: React.FC<ResponseSettingsPopoverProps> = (props): JSX.Element => {
  const {
    classes,
    openEditResponses,
    selectedPreset,
    addPreset,
    presets,
    updatedPresets,
    setSelectedPreset,
    setOpenEditResponses,
    setAddPreset,
    setChangesSubmited,
    createPreset,
    setOpen,
    issueTemplate,
    updateIssueTemplateDraftState,
  } = props;
  const { t } = useTranslation();
  const [openEditAllInputsModal, setOpenEditAllInputsModal] = React.useState(false);
  const [editingResponse, setEditingResponse] = React.useState(null);
  const emptyResponse = React.useMemo<boolean>(
    () => selectedPreset.options.some((opt) => !opt.response.length) || selectedPreset.options.length === 0,
    [selectedPreset.options],
  );

  return (
    <>
      <Popover
        open={openEditResponses}
        classes={{ paper: classes.popoverContainer }}
        style={{ top: 0 }}
        onClose={() => {
          setSelectedPreset({
            ...selectedPreset,
            options: [
              {
                order: 0,
                response: '',
                conditionals: [],
              },
            ],
          });
          setOpenEditResponses(false);
        }}
      >
        <div className={classes.header}>
          <Typography className={classes.editResponsesTitle}>{t('presetResponses')}</Typography>
          <Close
            data-testid={`closeSettingsPopover`}
            className={classes.closeSettings}
            onClick={() => {
              setSelectedPreset({
                ...selectedPreset,
                options: [
                  {
                    order: 0,
                    response: '',
                    conditionals: [],
                  },
                ],
              });
              setOpenEditResponses(false);
              setOpen(false);
            }}
          />
        </div>
        <Divider classes={{ root: classes.settingsDivider }} />
        <div className={classes.responsesSettingsContainer}>
          {selectedPreset.options?.map((option, index) => (
            <ResponseItem
              key={index}
              index={index}
              addPreset={addPreset}
              selectedPreset={selectedPreset}
              option={option}
              setSelectedPreset={setSelectedPreset}
              editingResponse={editingResponse}
              setEditingResponse={setEditingResponse}
            />
          ))}
          <Button
            data-testid={`addPresetResponse`}
            color={'primary'}
            variant={'text'}
            classes={{ root: classes.addResponseSettingsBtn }}
            onClick={() => {
              setSelectedPreset({
                ...selectedPreset,
                options: [
                  ...selectedPreset.options,
                  { order: selectedPreset.options.length, response: '', conditionals: [] },
                ],
              });
              setEditingResponse(selectedPreset.options.length);
            }}
          >
            <Add className={classes.addResponseSettingsIcon} />
            <Typography className={classes.addResponseSettings}>{t('addResponses')}</Typography>
          </Button>
        </div>
        <div className={classes.actionGroup}>
          <DefaultButton
            variant={'discard'}
            onClick={() => {
              setSelectedPreset({
                order: 0,
                options: [],
                inputs: [],
              });
              setAddPreset(false);
              setOpenEditResponses(false);
              setOpen(false);
            }}
            data-testid={`cancelSettingsPopover`}
            label={t('cancel')}
          />
          <DefaultButton
            data-testid={`saveSettingsPopover`}
            disabled={emptyResponse}
            onClick={async () => {
              if (addPreset) {
                const createResponse = await createPreset(issueTemplate._id, {
                  order: updatedPresets.length ? updatedPresets.length : presets.length,
                  options: selectedPreset.options,
                });
                updateIssueTemplateDraftState({
                  frame: { ...issueTemplate?.frame, presets: createResponse.frame.presets },
                });
                setAddPreset(false);
                setChangesSubmited(true);
                setOpenEditResponses(false);
              }

              if (
                !addPreset &&
                issueTemplate.frame.presets.some(
                  (preset: PresetWhereInput) => preset._id === selectedPreset._id && preset.inputs.length > 1,
                )
              ) {
                setOpenEditAllInputsModal(true);
              } else if (!addPreset) {
                const updatedPresets = issueTemplate.frame.presets.map((preset) => {
                  if (preset._id === selectedPreset._id) {
                    return { ...preset, options: selectedPreset.options };
                  }
                  return preset;
                });
                updateIssueTemplateDraftState({
                  frame: {
                    ...issueTemplate?.frame,
                    presets: updatedPresets,
                  },
                });
                setSelectedPreset({
                  ...selectedPreset,
                  options: [
                    {
                      order: 0,
                      response: '',
                      conditionals: [],
                    },
                  ],
                });
                setChangesSubmited(true);
                setOpenEditResponses(false);
                setOpenEditAllInputsModal(false);
                setOpen(false);
              }
            }}
            label={t('save')}
          />
        </div>
      </Popover>
      <Modal open={openEditAllInputsModal} onClose={() => setOpenEditAllInputsModal(false)} className={classes.modal}>
        <Box className={classes.modalContainer}>
          <div className={classes.modalContentContainer}>
            <Typography className={classes.modalTitle}>{t('updateResponses')}</Typography>
            <Typography className={classes.modalContent}>{t('presetResponseWillUpdate')}</Typography>
          </div>
          <div className={classes.modalBtnContainer}>
            <DefaultButton
              variant={'discard'}
              onClick={() => setOpenEditAllInputsModal(false)}
              data-testid={`cancelSavePreset`}
              label={t('cancel')}
            />
            <DefaultButton
              data-testid={`updateSavePreset`}
              onClick={async () => {
                setSelectedPreset({
                  ...selectedPreset,
                  options: [
                    {
                      order: 0,
                      response: '',
                      conditionals: [],
                    },
                  ],
                });
                setChangesSubmited(true);
                setOpenEditResponses(false);
                setOpenEditAllInputsModal(false);
                setOpen(false);
              }}
              label={t('update')}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createPreset,
};

const connecter = connect(mapStateToProps, mapDispatchToProps);

export default withStyles(styles)(connecter(withIssueTemplateConnector(ResponseSettingsPopover)));
