import { useState } from 'react';
import * as React from 'react';
import { Dialog, Fade } from '@mui/material';
import { WithStyles, withStyles } from '@mui/styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { styles } from './styles/new-widget-modal-styles';
import { Close } from '@mui/icons-material';
import {
  ActionsByAssigneeCard,
  ActionsByLabelCard,
  ActionsBySitesCard,
  ActionsByStatusCard,
  BarChartCard,
  ExecutionAverageTimeCard,
  ExecutionAverageTimeGaugeCard,
  GaugeCard,
  InstancesByIssueCard,
  InstancesByTimeCard,
  IssueInstancesComparisonCard,
  IssuesByAssigneeCard,
  IssuesByAssigneeEqualizerCard,
  IssuesByLabelCard,
  IssuesBySitesCard,
  IssuesByStateCard,
  IssuesByMultipleStateCard,
  IssuesFinishedTextCard,
  LineCard,
  PieCard,
  PlannedIssuesBySiteCard,
  TableCard,
  TagByTimeCard,
  TagLastValueCard,
  TextCard,
  TimeSpentAssigneeCard,
  TotalActionsTextCard,
  WidgetInfo,
  ActionsByMultipleStatusesCard,
  ValuesByTimeCard,
  LastValueByResponseCard,
  TotalValueResponsesCard,
  MediumValueByResponseCard,
  ResponsesByRatingCard,
  TotalResponsesCard,
} from './components/chartCards';
import EditWidget from './components/edit-widget';

export enum ChartTabs {
  Featured,
  Issues,
  Responses,
  Actions,
  Labels,
  Assignees,
  Sites,
  Tags,
  Custom,
}

export const cardsDict = {
  [ChartTabs.Featured]: [
    IssuesByMultipleStateCard,
    IssuesByAssigneeEqualizerCard,
    ExecutionAverageTimeGaugeCard,
    IssuesByLabelCard,
  ],
  [ChartTabs.Issues]: [
    IssuesByMultipleStateCard,
    InstancesByTimeCard,
    IssueInstancesComparisonCard,
    ExecutionAverageTimeCard,
    IssuesByStateCard,
    IssuesByAssigneeCard,
    IssuesFinishedTextCard,
    InstancesByIssueCard,
    PlannedIssuesBySiteCard,
  ],
  [ChartTabs.Responses]: [
    LastValueByResponseCard,
    MediumValueByResponseCard,
    ResponsesByRatingCard,
    TotalResponsesCard,
    TotalValueResponsesCard,
    ValuesByTimeCard,
  ],
  [ChartTabs.Actions]: [
    ActionsByMultipleStatusesCard,
    ActionsBySitesCard,
    ActionsByAssigneeCard,
    TotalActionsTextCard,
    ActionsByStatusCard,
    ActionsByLabelCard,
  ],
  [ChartTabs.Labels]: [ActionsByLabelCard, IssuesByLabelCard],
  [ChartTabs.Assignees]: [IssuesByAssigneeCard, ActionsByAssigneeCard, TimeSpentAssigneeCard],
  [ChartTabs.Sites]: [IssuesBySitesCard, ActionsBySitesCard],
  [ChartTabs.Tags]: [TagByTimeCard, TagLastValueCard],
  [ChartTabs.Custom]: [TableCard, BarChartCard, GaugeCard, TextCard, PieCard, LineCard],
};

type INewWidgetModalProps = {
  onClose: (refresh?: boolean) => any;
  edit?: WidgetInfo;
} & WithTranslation &
  WithStyles<typeof styles>;

const NewWidgetModal: React.FC<INewWidgetModalProps> = (props) => {
  const { classes, t } = props;

  const [currentTab, setCurrentTab] = useState<ChartTabs>(ChartTabs.Featured);
  const [editing, setEditing] = useState<WidgetInfo>(null);

  const tabProps = (tab: ChartTabs) => ({
    onClick: () => setCurrentTab(tab),
    'data-testid': `tab-${tab}`,
    'data-selected': (tab === currentTab).toString(),
  });

  return (
    <Dialog open={true} classes={{ paper: classes.paperContainer }} onClose={() => props.onClose()}>
      {!editing && !props.edit ? (
        <>
          <div className={classes.topContainer}>
            <span style={{ fontSize: 20, paddingLeft: 8, fontWeight: 500 }}>{t('addCard')}</span>
            <Close classes={{ root: classes.closeIcon }} onClick={() => props.onClose()} />
          </div>
          <div className={classes.gridContainer}>
            <div className={classes.dataTypes}>
              <span {...tabProps(ChartTabs.Featured)}>{t('featured')}</span>
              <span {...tabProps(ChartTabs.Issues)}>{t('issues')}</span>
              <span {...tabProps(ChartTabs.Responses)}>{t('responses')}</span>
              <span {...tabProps(ChartTabs.Actions)}>{t('actions')}</span>
              <span {...tabProps(ChartTabs.Labels)}>{t('labels')}</span>
              <span {...tabProps(ChartTabs.Assignees)}>{t('assignees')}</span>
              <span {...tabProps(ChartTabs.Sites)}>{t('siteElements')}</span>
              <span {...tabProps(ChartTabs.Tags)}>{t('tags')}</span>
              <span {...tabProps(ChartTabs.Custom)}>{t('custom')}</span>
            </div>
            <Fade key={currentTab} in={true}>
              <div className={classes.selectionGridItem}>
                {cardsDict[currentTab]?.map((CardComponent, i) => (
                  <CardComponent key={i} classes={classes} t={t} setEditInfo={setEditing} />
                ))}
              </div>
            </Fade>
          </div>
        </>
      ) : (
        <EditWidget
          info={editing || props.edit}
          edit={!!props.edit}
          goBack={(close?: boolean) => {
            setEditing(null);

            if (props.edit) {
              props.onClose(true);
              return;
            }

            if (close) {
              props.onClose();
            }
          }}
        />
      )}
    </Dialog>
  );
};

export default compose(withStyles(styles), withTranslation('translation'))(NewWidgetModal);
