import { Chip, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { styles } from 'src/utils/components/generic-input-card/styles';
import { Settings, Close, CallSplit, FeedbackOutlined } from '@mui/icons-material';
import QrCodeValidationChips from 'src/utils/components/generic-input-card/components/qr-code-validation-chips';
import ActionDraftPopover from 'src/utils/components/generic-input-card/components/action-draft-popover';
import SelectValidationType from 'src/utils/components/generic-input-card/components/select-validation-type';
import QrShowValidation from 'src/utils/components/generic-input-card/components/qr-show-validation';
import ToolQRValidationChips from './tool-qr-validation-chips';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';
import { typedConnect } from 'src/utils/funcs';

interface Input {
  subType: string;
  type: string;
  [x: string]: any;
  number: {
    validations: {
      min?: number;
      max?: number;
      inclusive?: boolean;
      name?: string;
      notBetween?: number[];
    }[];
  };
  multiple: {
    maxValues?: number;
    validations: {
      min?: number;
      max?: number;
      regex?: string;
      name?: string;
    }[];
  };
  string: {
    maxValues: number;
    validations: {
      min?: number;
      max?: number;
      regex?: string;
      name?: string;
    }[];
  };
}

interface OnResponse {
  actionRequired: boolean;
  ifValues: { label: string }[];
  condition: string;
  actionDraft: {
    _id: string;
  };
}

interface ShowValidationsProps extends WithIssueTemplateConnectorProps {
  classes: Record<string, string>;
  t?: any;
  setInput: (input: Partial<Input>) => void;
  active?: boolean;
  input: Input;
  selectRef: any;
  onBlur?: () => void;
  refSelectOptionTask: any;
  refSelectOptionInputToLink: any;
  setActionDraftsToDeletefromDraft: (data) => void;
  setActionDraftsToDeletefromTemplate: (data) => void;
}

const ShowValidations = (props: ShowValidationsProps): JSX.Element => {
  const {
    classes,
    input,
    setInput,
    onBlur,
    active,
    selectRef,
    refSelectOptionTask,
    refSelectOptionInputToLink,
    updateTemplateErrors,
  } = props;
  const [openActionDraftPopover, setOpenActionDraftPopover] = useState<any>(null);
  const [exp, setExp] = useState<any>(null);
  const [test, setTest] = useState<any>(null);
  const [notBetweenMin, setNotBetweenMin] = useState<number>(null);
  const [notBetweenMax, setNotBetweenMax] = useState<number>(null);
  const [min, setMin] = useState<number>(null);
  const [max, setMax] = useState<number>(null);
  const [multipleMax, setMultipleMax] = useState<number>(input.multiple?.maxValues);
  const [validationErrors, setValidationErrors] = useState<{ limit: boolean; content: boolean }>({
    limit: false,
    content: false,
  });

  useEffect(() => {
    const limitValidation = input[input.type].validations?.find((val) =>
      ['minmax', 'min', 'max'].includes(val?.name?.split(':')[0]),
    );
    if (['string', 'qrCode'].includes(input.type)) {
      setMin(limitValidation?.min);
      setMax(limitValidation?.max);
    }
  }, [input[input.type].validations, active]);

  useEffect(() => {
    const regexValidation = input[input.type].validations?.find((val) =>
      ['contains', 'notContains', 'startWith', 'endWith'].includes(val?.name?.split(':')[0]),
    );
    setTest(
      input[input.type].validations?.length && regexValidation?.name?.split(':').length > 1
        ? regexValidation?.name
        : null,
    );
  }, [input[input.type].validations, active]);

  useEffect(() => {
    if (['string', 'qrCode'].includes(input.type) && input[input.type].validations?.length) {
      validateRegexValidation();
    }
  }, [active, test, min, max, input[input.type].validations]);

  const removeStringValidation = (conditionToRemove: string, currentIndex: number) => {
    let updatedValidations = [...input[input.type].validations];

    if (
      updatedValidations.some((validation) => validation.name === 'minmax') &&
      ['min', 'max'].includes(conditionToRemove)
    ) {
      switch (conditionToRemove) {
        case 'min':
          updatedValidations[currentIndex] = { ...updatedValidations[currentIndex], min: undefined, name: 'max' };
          break;
        case 'max':
          updatedValidations[currentIndex] = { ...updatedValidations[currentIndex], max: undefined, name: 'min' };
          setValidationErrors({
            limit: false,
            content: false,
          });
          updateTemplateErrors({
            templateHasErrors: false,
          });
          break;
      }
    } else {
      updatedValidations = updatedValidations.filter(
        (validation: { name: string }) => validation.name !== conditionToRemove,
      );
      if (
        conditionToRemove === 'max' ||
        ['contains', 'notContains', 'startWith', 'endWith'].includes(conditionToRemove?.split(':')[0])
      ) {
        setValidationErrors({
          limit: false,
          content: false,
        });
        updateTemplateErrors({
          templateHasErrors: false,
        });
      }
    }

    if (input.type === 'string') {
      return setInput({
        _id: input._id,
        [input.type]: { ...input[input.type], validations: updatedValidations },
      });
    }

    setInput({
      _id: input._id,
      [input.type]: { ...input[input.type], validations: updatedValidations },
      string: { ...input['string'], validations: updatedValidations },
    });
  };

  const validateRegexValidation = () => {
    const valueToValidate = test?.split(':')[1];
    const maxValidation = input[input.type].validations.find(
      (validation: { name: string }) => validation.name === 'max',
    );
    const minMaxValidation = input[input.type].validations.find(
      (validation: { name: string }) => validation.name === 'minmax',
    );

    const errorsExist: {
      limit: boolean;
      content: boolean;
    } = { limit: false, content: false };

    if (
      (valueToValidate && maxValidation && valueToValidate?.length > max) ||
      (minMaxValidation && valueToValidate?.length > max) ||
      (minMaxValidation && min > max)
    ) {
      errorsExist.content =
        (valueToValidate && maxValidation && valueToValidate?.length > max) ||
        (minMaxValidation && valueToValidate?.length > max);
      errorsExist.limit = minMaxValidation && min > max;
      setValidationErrors({ ...errorsExist });
      updateTemplateErrors({
        templateHasErrors: true,
      });
      return;
    }

    setValidationErrors({ ...errorsExist });
    updateTemplateErrors({
      templateHasErrors: false,
    });
  };

  const saveChangesString = (conditionToSave: string, currentIndex: number, value?: string) => {
    const updatedValidations = [...input[input.type].validations];

    if (['min', 'max'].includes(conditionToSave)) {
      updatedValidations[currentIndex] = { ...updatedValidations[currentIndex], [conditionToSave]: parseInt(value) };
    } else {
      updatedValidations[currentIndex] = { ...updatedValidations[currentIndex], regex: exp || undefined, name: test };
    }

    if (input.type === 'string') {
      return setInput({
        _id: input._id,
        [input.type]: { ...input[input.type], validations: updatedValidations },
      });
    }

    setInput({
      _id: input._id,
      [input.type]: { ...input[input.type], validations: updatedValidations },
      string: { ...input['string'], validations: updatedValidations },
    });
  };

  const options = [
    { option: 'contains', value: 'contains' },
    { option: 'notContains', value: 'notContains' },
    { option: 'startWith', value: 'startWith' },
    { option: 'endWith', value: 'endWith' },
    { option: 'linkWith', value: 'linkWith' },
    { option: 'specificTool', value: 'specificTool' },
  ];

  const optionsWithoutLinkWith = [
    { option: 'contains', value: 'contains' },
    { option: 'notContains', value: 'notContains' },
    { option: 'startWith', value: 'startWith' },
    { option: 'endWith', value: 'endWith' },
  ];

  const regexName = (name: string): string => {
    const n = name?.split(':')[0];
    switch (n) {
      case 'contains':
        return `${props.t('contains')}: `;
      case 'notContains':
        return `${props.t('notContains')}: `;
      case 'startWith':
        return `${props.t('startWith')}: `;
      case 'endWith':
        return `${props.t('endWith')}: `;
      case 'linkWith':
        return `${props.t('linkWith')}: `;
      case 'specificTool':
        return `${props.t('specificTool')}: `;
      default:
        return '';
    }
  };

  const colors = ['#B468FF', '#00BF9D', '#FE8947', '#ACED24'];
  const backgroundColors: string[] = [
    'rgba(180, 104, 255, 0.13)',
    'rgba(0, 191, 157, 0.13)',
    'rgba(254, 137, 71, 0.13)',
    'rgba(172, 237, 36, 0.13)',
  ];

  const showChip = (conditionName?: string, index?: number): JSX.Element => {
    if (
      input.type !== 'number' &&
      input[input.type].onResponse?.filter((onResp: OnResponse) => onResp.actionRequired && !onResp.condition).length
    ) {
      let color: number = -1;
      return input[input.type].onResponse.map((onResp: OnResponse) => {
        if (onResp.actionRequired) {
          color++;
          return (
            <Chip
              icon={<CallSplit classes={{ root: classes.iconChip }} />}
              style={{ margin: '5px' }}
              label={
                <div id='div-validation-if' data-testid='div-validation-if' className={classes.divLabel}>
                  {`${props.t('if')} `}
                  <span
                    style={{
                      backgroundColor: backgroundColors[parseInt(`${color}`)],
                      color: colors[parseInt(`${color}`)],
                    }}
                    className={classes.spanValueAction}
                  >
                    {(onResp.ifValues[0].label || onResp.ifValues[0]) as string}
                  </span>
                  {` ${props.t('selected')} `}
                  <span className={classes.spanCreateAction}>
                    <FeedbackOutlined style={{ fontSize: '16px', marginRight: '5px' }} />
                    {`${props.t('createAction')}`}
                  </span>
                  {onResp.actionDraft ? (
                    <span className={classes.spanCreateAction}>
                      {`${props.t('defaultSettingsPopover')}`}
                      <Close
                        id='button-close'
                        data-testid='button-close'
                        classes={{ root: classes.closeIconActionDefaultSettings }}
                        style={{ display: active ? 'initial' : 'none' }}
                        onClick={() => {
                          props.setActionDraftsToDeletefromTemplate((prev) => [...prev, onResp.actionDraft]);
                          setInput({
                            _id: input._id,
                            [input.type]: {
                              ...input[input.type],
                              onResponse: input[input.type].onResponse.map((onRespon: OnResponse) =>
                                onRespon.ifValues[0].label === onResp.ifValues[0].label
                                  ? { ...onRespon, actionDraft: null }
                                  : onRespon,
                              ),
                            },
                          });
                        }}
                      />
                    </span>
                  ) : undefined}
                  <div style={{ display: active ? 'flex' : 'none' }}>
                    <span
                      id='buttonOpenActionDraftSettings'
                      data-testid='buttonOpenActionDraftSettings'
                      style={{ alignItems: 'center', display: 'flex' }}
                      onClick={() => {
                        setOpenActionDraftPopover(onResp);
                      }}
                    >
                      <Settings classes={{ root: classes.settingsIcon }} />
                    </span>
                    <Close
                      id='buttonDeleteConditional'
                      data-testid='buttonDeleteConditional'
                      classes={{ root: classes.closeIcon }}
                      onClick={(): void => {
                        if (onResp.actionDraft)
                          props.setActionDraftsToDeletefromTemplate((prev) => [...prev, onResp.actionDraft]);
                        setInput({
                          _id: input._id,
                          [input.type]: {
                            ...input[input.type],
                            onResponse: input[input.type].onResponse.map((onRespon: OnResponse) =>
                              onRespon.ifValues[0].label === onResp.ifValues[0].label
                                ? { ...onRespon, actionRequired: false, actionDraft: null }
                                : onRespon,
                            ),
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              }
              classes={{ root: classes.advancedChip }}
            />
          );
        }
        return undefined;
      });
    }
    if (input.type === 'number' && input.number?.validations?.length) {
      if (input.number.validations[0].name === 'between') {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div id='div-validation-between' data-testid='div-validation-between' className={classes.divLabel}>
                {`${props.t('between')}:`}
                <TextField
                  id='input-validation-between-min'
                  data-testid='input-validation-between-min'
                  value={min !== null ? min : input.number?.validations[0]?.min}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  placeholder={props.t('min')}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => {
                    if (input.number?.validations[0].min !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        number: {
                          ...input.number,
                          validations: [
                            {
                              ...input.number?.validations[0],
                              min: parseInt(e.target.value),
                            },
                          ],
                        },
                      });
                    }
                  }}
                  onChange={(e): void => setMin(parseInt(e.target.value))}
                />
                <TextField
                  id='input-validation-between-max'
                  data-testid='input-validation-between-max'
                  value={max !== null ? max : input[input.type]?.validations[0]?.max}
                  type={'number'}
                  variant={'standard'}
                  placeholder={props.t('max')}
                  disabled={!active}
                  style={{ marginLeft: '10px' }}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => {
                    if (input.number?.validations[0]?.max !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        number: {
                          ...input.number,
                          validations: [
                            {
                              ...input.number?.validations[0],
                              max: parseInt(e.target.value),
                            },
                          ],
                        },
                      });
                    }
                  }}
                  onChange={(e): void => setMax(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void =>
                    setInput({
                      _id: input._id,
                      number: { ...input.number, validations: [] },
                    })
                  }
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      } else if (
        input.number.validations?.length &&
        input.number.validations[0].name === 'notBetween' &&
        input.number.validations[0].notBetween?.length
      ) {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div
                id='div-validation-not-between'
                data-testid='div-validation-not-between'
                className={classes.divLabel}
              >
                {`${props.t('notBetween')}:`}
                <TextField
                  id='input-validation-not-between-min'
                  data-testid='input-validation-not-between-min'
                  value={notBetweenMin !== null ? notBetweenMin : input.number?.validations[0]?.notBetween[0]}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  placeholder={props.t('min')}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => {
                    if (input.number?.validations[0].notBetween[0] !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        number: {
                          ...input.number,
                          validations: [
                            {
                              ...input.number?.validations[0],
                              max: undefined,
                              notBetween: [
                                parseInt(e.target.value),
                                input.number?.validations[0].notBetween
                                  ? input.number?.validations[0].notBetween[1]
                                  : 0,
                              ],
                            },
                          ],
                        },
                      });
                    }
                  }}
                  onChange={(e): void => setNotBetweenMin(parseInt(e.target.value))}
                />
                <TextField
                  id='input-validation-not-between-max'
                  data-testid='input-validation-not-between-max'
                  value={notBetweenMax !== null ? notBetweenMax : input.number?.validations[0]?.notBetween[1]}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  placeholder={props.t('max')}
                  style={{ marginLeft: '10px' }}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => {
                    if (input.number?.validations[0].notBetween[1] !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        number: {
                          ...input.number,
                          validations: [
                            {
                              ...input.number?.validations[0],
                              min: undefined,
                              notBetween: [
                                input.number?.validations[0].notBetween
                                  ? input.number?.validations[0].notBetween[0]
                                  : 0,
                                parseInt(e.target.value),
                              ],
                            },
                          ],
                        },
                      });
                    }
                  }}
                  onChange={(e): void => setNotBetweenMax(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void =>
                    setInput({
                      _id: input._id,
                      number: { ...input.number, validations: [] },
                    })
                  }
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      } else if (input.number.validations?.length && input.number.validations[0].name === 'equalTo') {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div id='div-validation-equal-to' data-testid='div-validation-equal-to' className={classes.divLabel}>
                {`${props.t('equalTo')}:`}
                <TextField
                  id='input-validation-equal-to'
                  data-testid='input-validation-equal-to'
                  value={min !== null ? min : input.number?.validations[0]?.min}
                  type={'number'}
                  disabled={!active}
                  variant={'standard'}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => {
                    if (input.number?.validations[0]?.min !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        number: {
                          ...input.number,
                          validations: [
                            {
                              ...input.number?.validations[0],
                              max: parseInt(e.target.value),
                              min: parseInt(e.target.value),
                            },
                          ],
                        },
                      });
                    }
                  }}
                  onChange={(e): void => setMin(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void =>
                    setInput({
                      _id: input._id,
                      number: { ...input.number, validations: [] },
                    })
                  }
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      } else if (input.number.validations?.length && input.number.validations[0].name === 'moreThan') {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div id='div-validation-more-than' data-testid='div-validation-more-than' className={classes.divLabel}>
                {`${props.t('moreThan')}:`}
                <TextField
                  id='input-validation-more-than'
                  data-testid='input-validation-more-than'
                  value={min !== null ? min : input.number?.validations[0]?.min}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  onBlur={(e) => {
                    if (input.number?.validations[0]?.min !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        number: {
                          ...input.number,
                          validations: [
                            {
                              ...input.number?.validations[0],
                              min: parseInt(e.target.value),
                            },
                          ],
                        },
                      });
                    }
                  }}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onChange={(e): void => setMin(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void =>
                    setInput({
                      _id: input._id,
                      number: { ...input.number, validations: [] },
                    })
                  }
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      } else if (input.number.validations?.length && input.number.validations[0].name === 'lessThan') {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div id='div-validation-less-than' data-testid='div-validation-less-than' className={classes.divLabel}>
                {`${props.t('lessThan')}:`}
                <TextField
                  id='input-validation-less-than'
                  data-testid='input-validation-less-than'
                  value={max !== null ? max : input.number?.validations[0]?.max}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => {
                    if (input.number?.validations[0]?.max !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        number: {
                          ...input.number,
                          validations: [
                            {
                              ...input.number?.validations[0],
                              max: parseInt(e.target.value),
                            },
                          ],
                        },
                      });
                    }
                  }}
                  onChange={(e): void => setMax(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void =>
                    setInput({
                      _id: input._id,
                      number: { ...input.number, validations: [] },
                    })
                  }
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      } else if (input.number.validations?.length && input.number.validations[0].name === 'lessOrEqualTo') {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div
                id='div-validation-less-or-equal-to'
                data-testid='div-validation-less-or-equal-to'
                className={classes.divLabel}
              >
                {`${props.t('lessOrEqualTo')}:`}
                <TextField
                  id='input-validation-less-or-equal-to'
                  data-testid='input-validation-less-or-equal-to'
                  value={max !== null ? max : input.number?.validations[0]?.max}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  onBlur={(e) => {
                    if (input.number?.validations[0]?.max !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        number: {
                          ...input.number,
                          validations: [
                            {
                              ...input.number?.validations[0],
                              max: parseInt(e.target.value),
                            },
                          ],
                        },
                      });
                    }
                  }}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onChange={(e): void => setMax(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void =>
                    setInput({
                      _id: input._id,
                      number: { ...input.number, validations: [] },
                    })
                  }
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      }
    } else if (
      (input.type === 'string' || input.type === 'qrCode') &&
      input[input.type]?.validations?.length &&
      !['contains', 'notContains', 'startWith', 'endWith'].includes(conditionName?.split(':')[0])
    ) {
      if (conditionName === 'minmax') {
        return (
          <div>
            <div style={{ display: 'flex' }}>
              <Chip
                icon={<Settings classes={{ root: classes.iconChip }} />}
                label={
                  <div id='div-validation-min' data-testid='div-validation-min' className={classes.divLabel}>
                    {`${props.t('min')}:`}
                    <TextField
                      id='input-validation-min'
                      data-testid='input-validation-min'
                      value={min !== null ? min : input[input.type].validations[index]?.min}
                      type={'number'}
                      disabled={!active}
                      variant={'standard'}
                      className={
                        validationErrors.limit
                          ? `${classes.inputMultiline} ${classes.inputMultilineError}`
                          : classes.inputMultiline
                      }
                      InputProps={{
                        classes: { input: classes.inputValidations },
                      }}
                      onBlur={(e) => {
                        saveChangesString('min', index, e.target.value);
                        validateRegexValidation();
                      }}
                      onChange={(e): void => setMin(parseInt(e.target.value))}
                    />
                    <Close
                      id='button-close'
                      data-testid='button-close'
                      classes={{ root: classes.closeIcon }}
                      style={{ display: active ? 'initial' : 'none' }}
                      onClick={(): void => removeStringValidation('min', index)}
                    />
                  </div>
                }
                classes={{ root: classes.advancedChip }}
              />
              <Chip
                icon={<Settings classes={{ root: classes.iconChip }} />}
                style={{ marginLeft: '10px' }}
                label={
                  <div id='div-validation-max' data-testid='div-validation-max' className={classes.divLabel}>
                    {`${props.t('max')}:`}
                    <TextField
                      id='input-validation-max'
                      data-testid='input-validation-max'
                      value={max !== null ? max : input[input.type]?.validations[index]?.max}
                      type={'number'}
                      variant={'standard'}
                      disabled={!active}
                      className={
                        validationErrors.limit
                          ? `${classes.inputMultiline} ${classes.inputMultilineError}`
                          : classes.inputMultiline
                      }
                      InputProps={{
                        classes: { input: classes.inputValidations },
                      }}
                      onBlur={(e) => {
                        saveChangesString('max', index, e.target.value);
                        validateRegexValidation();
                      }}
                      onChange={(e): void => setMax(parseInt(e.target.value))}
                    />
                    <Close
                      id='button-close'
                      data-testid='button-close'
                      classes={{ root: classes.closeIcon }}
                      style={{ display: active ? 'initial' : 'none' }}
                      onClick={(): void => removeStringValidation('max', index)}
                    />
                  </div>
                }
                classes={{ root: classes.advancedChip }}
              />
            </div>
            {validationErrors.limit && (
              <Typography className={classes.validationErrorText}>{props.t('maxMustBeHigherThanMin')}</Typography>
            )}
          </div>
        );
      } else if (conditionName === 'min') {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div id='div-validation-min' data-testid='div-validation-min' className={classes.divLabel}>
                {`${props.t('min')}:`}
                <TextField
                  id='input-validation-min'
                  data-testid='input-validation-min'
                  value={min !== null ? min : input[input.type]?.validations[index]?.min}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => saveChangesString(conditionName, index, e.target.value)}
                  onChange={(e): void => setMin(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void => removeStringValidation(conditionName, index)}
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      } else if (conditionName === 'max') {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div id='div-validation-max' data-testid='div-validation-max' className={classes.divLabel}>
                {`${props.t('max')}:`}
                <TextField
                  id='input-validation-max'
                  data-testid='input-validation-max'
                  value={max !== null ? max : input[input.type]?.validations[index]?.max}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => saveChangesString(conditionName, index, e.target.value)}
                  onChange={(e): void => setMax(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void => removeStringValidation(conditionName, index)}
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      } else if (
        (input.type === 'string' || input.type === 'qrCode') &&
        input[input?.type]?.validations?.length &&
        conditionName === 'linkWith'
      ) {
        return (
          <>
            <QrCodeValidationChips
              classes={classes}
              regexName={regexName}
              active={active}
              options={options}
              optionsWithoutLinkWith={optionsWithoutLinkWith}
              setInput={setInput}
              selectRef={selectRef}
              input={input}
              refSelectOptionTask={refSelectOptionTask}
              refSelectOptionInputToLink={refSelectOptionInputToLink}
              validation={input[input.type].validations[index]}
              idx={index}
              masterValidation={input[input.type].validations.find((v) => v.name !== 'linkWith')}
              onBlur={onBlur}
            />
            {input[input.type].validations.find((v) => v.name !== 'linkWith') &&
              input[input.type].validations[index].linkWithInputs.map((inp, i) => (
                <QrShowValidation
                  key={i}
                  input={input}
                  regexName={regexName}
                  valInput={inp}
                  t={props.t}
                  masterValidation={input[input.type].validations.find((v) => v.name !== 'linkWith')}
                />
              ))}
          </>
        );
      }
    } else if (
      (input.tool?.validations?.length &&
        input.tool.validations[0]?.name &&
        !['linkWith', 'specificTool'].includes(conditionName)) ||
      ((input.type === 'string' || input.type === 'qrCode') &&
        input[input?.type]?.validations?.length &&
        ['contains', 'notContains', 'startWith', 'endWith'].includes(conditionName?.split(':')[0]))
    ) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            style={{ width: 'fit-content' }}
            label={
              <div
                id='div-validation-not-sure-1'
                data-testid='div-validation-not-sure-1'
                className={classes.divLabel}
                style={{ alignItems: 'flex-start' }}
              >
                {active ? (
                  <SelectValidationType
                    id='input-selection-validation-not-sure-1'
                    data-testid='input-selection-validation-not-sure-1'
                    input={input}
                    active={active}
                    selectRef={selectRef}
                    name={
                      input[input.type].validations.find((val) =>
                        ['contains', 'notContains', 'startWith', 'endWith'].includes(val.name?.split(':')[0]),
                      )?.name
                    }
                    idx={0}
                    linkWith={false}
                    options={options}
                    optionsWithoutLinkWith={optionsWithoutLinkWith}
                    setInput={setInput}
                    validationIndex={index}
                  />
                ) : (
                  regexName(
                    input[input.type].validations.find((val: { name: string }) =>
                      ['contains', 'notContains', 'startWith', 'endWith'].includes(val?.name?.split(':')[0]),
                    )?.name,
                  )
                )}
                <TextField
                  id='input-text-validation-not-sure-1'
                  data-testid='input-text-validation-not-sure-1'
                  value={test?.split(':').length > 1 ? test?.split(':')[1] : null}
                  // style={active ? null : { left: '5px', bottom: '2px' }}
                  type={'text'}
                  variant={'standard'}
                  disabled={!active}
                  className={
                    validationErrors.content
                      ? `${classes.inputMultiline} ${classes.inputMultilineError}`
                      : classes.inputMultiline
                  }
                  multiline={false}
                  InputProps={{
                    classes: { input: `${classes.inputValidations} ${classes.inputWithEllipsis}` },
                  }}
                  onBlur={() => {
                    saveChangesString(conditionName, index);
                    if (
                      input[input.type].validations.some(
                        (validation: { name: string; min: number | null; max: number | null }) =>
                          ['min', 'max', 'minmax'].includes(validation.name) && (validation.min || validation.max),
                      )
                    )
                      validateRegexValidation();
                  }}
                  onChange={(e): void => {
                    let expression: string | null = null;
                    switch (
                      input[input.type]?.validations
                        .find((val: { name: string }) =>
                          ['contains', 'notContains', 'startWith', 'endWith'].includes(val.name?.split(':')[0]),
                        )
                        .name?.split(':')[0]
                    ) {
                      case 'contains':
                        expression = `^.*${e.target.value}.*$`;
                        break;
                      case 'notContains':
                        expression = `^((?!${e.target.value}).)*$`;
                        break;
                      case 'startWith':
                        expression = `^${e.target.value}`;
                        break;
                      case 'endWith':
                        expression = `${e.target.value}$`;
                        break;
                      default:
                        expression = null;
                    }
                    setTest(
                      input[input.type]?.validations
                        .find((val: { name: string }) =>
                          ['contains', 'notContains', 'startWith', 'endWith'].includes(val.name?.split(':')[0]),
                        )
                        .name?.split(':')[0] +
                        ':' +
                        e.target.value,
                    );
                    setExp(expression || undefined);
                  }}
                />
                <Close
                  id='button-close-validation-not-sure-1'
                  data-testid='button-close-validation-not-sure-1'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none', alignSelf: 'center' }}
                  onClick={(): void => removeStringValidation(conditionName, index)}
                />
              </div>
            }
            classes={{ root: active ? classes.advancedChipCustom : classes.advancedChip }}
          />
          {validationErrors.content && (
            <Typography className={classes.validationErrorText}>{props.t('validationNotCorrespondToLimit')}</Typography>
          )}
        </div>
      );
    } else if (input.type === 'tool') {
      if (
        input[input.type].validations.length > 1 &&
        input[input.type].validations.find((val) => val?.name === 'minmax')
      ) {
        return (
          <>
            <Chip
              icon={<Settings classes={{ root: classes.iconChip }} />}
              label={
                <div id='div-validation-min' data-testid='div-validation-min' className={classes.divLabel}>
                  {`${props.t('min')}:`}
                  <TextField
                    id='input-validation-min'
                    data-testid='input-validation-min'
                    value={min !== null ? min : input[input.type]?.validations[0]?.min}
                    type={'number'}
                    variant={'standard'}
                    disabled={!active}
                    InputProps={{
                      classes: { input: classes.inputValidations },
                    }}
                    onBlur={(e) => {
                      if (input[input.type]?.validations[0]?.min !== parseInt(e.target.value)) {
                        setInput({
                          _id: input._id,
                          [input.type]: {
                            ...input[input.type],
                            validations: [
                              {
                                ...input[input.type]?.validations[0],
                                min: parseInt(e.target.value),
                              },
                            ],
                          },
                        });
                      }
                    }}
                    onChange={(e): void => setMin(parseInt(e.target.value))}
                  />
                  <Close
                    id='button-close'
                    data-testid='button-close'
                    classes={{ root: classes.closeIcon }}
                    style={{ display: active ? 'initial' : 'none' }}
                    onClick={(): void =>
                      setInput({
                        _id: input._id,
                        [input.type]: {
                          ...input[input.type],
                          validations: [
                            {
                              ...input[input.type]?.validations[0],
                              min: undefined,
                              name: 'max',
                            },
                          ],
                        },
                        string: {
                          ...input.string,
                          validations: [
                            {
                              ...input.string?.validations[0],
                              min: undefined,
                              name: 'max',
                            },
                          ],
                        },
                      })
                    }
                  />
                </div>
              }
              classes={{ root: classes.advancedChip }}
            />
            <Chip
              icon={<Settings classes={{ root: classes.iconChip }} />}
              style={{ marginLeft: '10px' }}
              label={
                <div id='div-validation-max' data-testid='div-validation-max' className={classes.divLabel}>
                  {`${props.t('max')}:`}
                  <TextField
                    id='input-validation-max'
                    data-testid='input-validation-max'
                    value={max !== null ? max : input[input.type].validations[0].max}
                    type={'number'}
                    variant={'standard'}
                    disabled={!active}
                    InputProps={{
                      classes: { input: classes.inputValidations },
                    }}
                    onBlur={(e) => {
                      if (input[input.type]?.validations[0]?.max !== parseInt(e.target.value)) {
                        setInput({
                          _id: input._id,
                          [input.type]: {
                            ...input[input.type],
                            validations: [
                              {
                                ...input[input.type]?.validations[0],
                                max: parseInt(e.target.value),
                              },
                            ],
                          },
                        });
                      }
                    }}
                    onChange={(e): void => setMax(parseInt(e.target.value))}
                  />
                  <Close
                    id='button-close'
                    data-testid='button-close'
                    classes={{ root: classes.closeIcon }}
                    style={{ display: active ? 'initial' : 'none' }}
                    onClick={(): void =>
                      setInput({
                        _id: input._id,
                        [input.type]: {
                          ...input[input.type],
                          validations: [
                            {
                              ...input[input.type]?.validations[0],
                              max: undefined,
                              name: 'min',
                            },
                          ],
                        },
                        string: {
                          ...input.string,
                          validations: [
                            {
                              ...input.string?.validations[0],
                              max: undefined,
                              name: 'min',
                            },
                          ],
                        },
                      })
                    }
                  />
                </div>
              }
              classes={{ root: classes.advancedChip }}
            />
          </>
        );
      } else if (input[input.type].validations[0].name === 'min') {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div id='div-validation-min' data-testid='div-validation-min' className={classes.divLabel}>
                {`${props.t('min')}:`}
                <TextField
                  id='input-validation-min'
                  data-testid='input-validation-min'
                  value={min !== null ? min : input[input.type]?.validations[0]?.min}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => {
                    if (input[input.type]?.validations[0]?.min !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        [input.type]: {
                          ...input[input.type],
                          validations: [
                            {
                              ...input[input.type]?.validations[0],
                              min: parseInt(e.target.value),
                            },
                          ],
                        },
                      });
                    }
                  }}
                  onChange={(e): void => setMin(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void =>
                    setInput({
                      _id: input._id,
                      [input.type]: { ...input[input.type], validations: [] },
                    })
                  }
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      } else if (input[input.type].validations[0].name === 'max') {
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div id='div-validation-max' data-testid='div-validation-max' className={classes.divLabel}>
                {`${props.t('max')}:`}
                <TextField
                  id='input-validation-max'
                  data-testid='input-validation-max'
                  value={max !== null ? max : input[input.type]?.validations[0]?.max}
                  type={'number'}
                  disabled={!active}
                  variant={'standard'}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={(e) => {
                    if (input[input.type]?.validations[0]?.max !== parseInt(e.target.value)) {
                      setInput({
                        _id: input._id,
                        [input.type]: {
                          ...input[input.type],
                          validations: [
                            {
                              ...input[input.type]?.validations[0],
                              max: parseInt(e.target.value),
                            },
                          ],
                        },
                      });
                    }
                  }}
                  onChange={(e): void => setMax(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void =>
                    setInput({
                      _id: input._id,
                      [input.type]: { ...input[input.type], validations: [] },
                      string: { ...input.string, validations: [] },
                    })
                  }
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
      } else if (input[input.type].validations?.length && input[input.type].validations[0].name) {
        if (input[input.type].validations[index].name === 'linkWith') {
          return (
            <>
              <QrCodeValidationChips
                classes={classes}
                regexName={regexName}
                active={active}
                options={options}
                optionsWithoutLinkWith={optionsWithoutLinkWith}
                setInput={setInput}
                selectRef={selectRef}
                input={input}
                refSelectOptionTask={refSelectOptionTask}
                refSelectOptionInputToLink={refSelectOptionInputToLink}
                validation={input[input.type].validations[index]}
                idx={index}
                masterValidation={input[input.type].validations.find((v) => v.name !== 'linkWith')}
                onBlur={onBlur}
              />
              {input[input.type].validations.find((v) => v.name !== 'linkWith') &&
                input[input.type].validations[index].linkWithInputs.map((inp, i) => (
                  <QrShowValidation
                    key={i}
                    input={input}
                    regexName={regexName}
                    valInput={inp}
                    t={props.t}
                    masterValidation={input[input.type].validations.find((v) => v.name !== 'linkWith')}
                  />
                ))}
            </>
          );
        } else {
          return (
            <ToolQRValidationChips
              input={input}
              regexName={regexName}
              active={active}
              options={options}
              optionsWithoutLinkWith={optionsWithoutLinkWith}
              setInput={setInput}
              selectRef={selectRef}
              validation={input[input.type].validations[index]}
              idx={index}
            />
          );
        }
      }
    } else if (input.type === 'multiple' && input.multiple?.validations?.length) {
      if (input.multiple.validations[0].name === 'limitAnswers')
        return (
          <Chip
            icon={<Settings classes={{ root: classes.iconChip }} />}
            label={
              <div
                id='div-validation-limit-of-answers'
                data-testid='div-validation-limit-of-answers'
                className={classes.divLabel}
              >
                {`${props.t('limitOfAnswers')}:`}
                <TextField
                  id='input-validation-limit-of-answers'
                  data-testid='input-validation-limit-of-answers'
                  value={multipleMax}
                  type={'number'}
                  variant={'standard'}
                  disabled={!active}
                  InputProps={{ classes: { input: classes.inputValidations } }}
                  onBlur={() => {
                    setInput({
                      _id: input._id,
                      multiple: {
                        ...input.multiple,
                        maxValues: multipleMax,
                      },
                    });
                  }}
                  onChange={(e): void => setMultipleMax(parseInt(e.target.value))}
                />
                <Close
                  id='button-close'
                  data-testid='button-close'
                  classes={{ root: classes.closeIcon }}
                  style={{ display: active ? 'initial' : 'none' }}
                  onClick={(): void =>
                    setInput({
                      _id: input._id,
                      maxValues: undefined,
                      multiple: { ...input.multiple, validations: [] },
                    })
                  }
                />
              </div>
            }
            classes={{ root: classes.advancedChip }}
          />
        );
    }

    return <div />;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection:
          ['string', 'qrCode'].includes(input.type) && (validationErrors.content || validationErrors.limit)
            ? 'column'
            : 'row',
        alignItems:
          ['string', 'qrCode'].includes(input.type) && (validationErrors.content || validationErrors.limit)
            ? 'flex-start'
            : 'center',
        gap: '5px',
        flexWrap: 'inherit',
      }}
    >
      {input[input.type].validations?.length > 0 &&
        input[input.type].validations?.map((val: { name: string }, index: number) => showChip(val?.name, index))}
      {input.type !== 'number' && input[input.type].onResponse?.length > 0 && showChip()}
      {!!openActionDraftPopover ? (
        <ActionDraftPopover
          onClose={(action) => {
            if (action) {
              props.setActionDraftsToDeletefromDraft((prev) => [...prev, action._id]);
              setInput({
                _id: input._id,
                [input.type]: {
                  ...input[input.type],
                  onResponse: input[input.type].onResponse.map((onRespon: OnResponse) =>
                    onRespon.ifValues[0].label === openActionDraftPopover.ifValues[0].label
                      ? { ...onRespon, actionDraft: action._id }
                      : onRespon,
                  ),
                },
              });
            }
            setOpenActionDraftPopover(null);
          }}
          actionDraft={openActionDraftPopover.actionDraft}
        />
      ) : undefined}
    </div>
  );
};

const connector = typedConnect((state) => ({ isMasterTenant: state.authenticationReducer.tenantInfo.master }), {});

export default compose<any>(
  connector,
  withTranslation('translation'),
  withStyles(styles),
  withIssueTemplateConnector,
)(ShowValidations);
