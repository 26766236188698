import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import {
  Input,
  InputStringOnResponse,
  IssueTemplateDraft,
  IssueTemplateDraftTaskTemplateGroupTaskTemplate,
} from 'src/gql/graphql';
import { GlarState } from 'src/reducer-manager';
import GenericCardInputData from 'src/utils/components/generic-input-card/interfaces/GenericCardInputData';
import { run } from 'src/utils/funcs';

export interface IssueTemplateState {
  issueTemplate?: Partial<IssueTemplateDraft>;
  selectedTaskId?: string;
  firstTime?: string | null;
  templateHasErrors?: boolean;
}

export const issueTemplateDraftInitialValue = (): IssueTemplateState => ({
  issueTemplate: null,
  selectedTaskId: null,
  firstTime: null,
  templateHasErrors: false,
});

export interface InputOptions {
  order: number;
  id: string;
  label: string;
  _id: string;
  firstItem: string;
  secondItem: string;
  value: string;
}

const issueTemplateDraftSlice = createSlice({
  name: 'issueTemplateDraftReducer',
  initialState: issueTemplateDraftInitialValue(),
  reducers: {
    resetIssueTemplateDraftState() {
      return issueTemplateDraftInitialValue();
    },
    updateTemplateErrors(state, action: PayloadAction<{ templateHasErrors: boolean }>) {
      state.templateHasErrors = action.payload ? action.payload.templateHasErrors : false;
    },
    updateIssueTemplateDraftState(state, action: PayloadAction<Partial<IssueTemplateDraft>>) {
      state.issueTemplate = action.payload ? { ...(state.issueTemplate || {}), ...action.payload } : null;
    },
    updateTemplateTaskState(
      state,
      action: PayloadAction<{ id?: string; task: Partial<IssueTemplateDraftTaskTemplateGroupTaskTemplate> }>,
    ) {
      for (const tg of state.issueTemplate?.taskTemplateGroups || []) {
        for (const [index, task] of Object.entries(tg.taskTemplates) || []) {
          if (task._id === (action.payload.id || state.selectedTaskId)) {
            tg.taskTemplates[index] = { ...task, ...action.payload.task };
            return;
          }
        }
      }
    },
    updateTaskInputState(
      state,
      action: PayloadAction<{ id: string; input: Partial<GenericCardInputData>; taskToSelect?: string }>,
    ) {
      const recurseInputs = (parent: { inputs?: Input[] }) => {
        for (const [index, input] of Object.entries(parent.inputs) || []) {
          if (input._id === action.payload.id) {
            parent.inputs[index] = { ...input, ...action.payload.input };
            return true;
          }

          for (const onResponse of input[input.type]?.['onResponse'] || []) {
            if (recurseInputs(onResponse)) {
              return true;
            }
          }
        }

        return false;
      };

      const correctSelectedTaskId = action.payload.taskToSelect || state.selectedTaskId;

      for (const tg of state.issueTemplate?.taskTemplateGroups || []) {
        for (const task of tg.taskTemplates || []) {
          if (correctSelectedTaskId === task._id) {
            recurseInputs(task);
            return;
          }
        }
      }
    },
    addInputState(state, action: PayloadAction<{ onResponseId?: string; inputToAdd: Input }>) {
      const { onResponseId, inputToAdd } = action.payload;

      const selectedTask = state.issueTemplate.taskTemplateGroups
        .flatMap((x) => x.taskTemplates)
        .find((t) => t._id === state.selectedTaskId);

      const spliceInput = (inputs: Input[]) => {
        inputs.sort((a, b) => a.order - b.order);
        inputs.splice(inputToAdd.order, 0, inputToAdd);

        inputs.forEach((input, index) => {
          input.order = index;
        });
      };

      const iterateInputs = (inputs: Input[]) => {
        for (const input of inputs) {
          for (const resp of (input[input.type]?.['onResponse'] || []) as InputStringOnResponse[]) {
            if (resp._id === onResponseId) {
              spliceInput(resp.inputs);
              return true;
            } else {
              if (iterateInputs(resp.inputs)) {
                return true;
              }
            }
          }
        }

        return false;
      };

      if (!onResponseId) {
        spliceInput(selectedTask.inputs);
      } else {
        iterateInputs(selectedTask.inputs);
      }

      state.firstTime = inputToAdd._id;
    },
    changeSelectedTask(state, action: PayloadAction<string | null>) {
      state.selectedTaskId = action.payload;
      state.firstTime = null;
    },
    setFirstTime(state, action) {
      state.firstTime = action.payload;
    },
  },
});

export function issueTemplateSelector(state: GlarState) {
  const templateHasErrors = state.issueTemplateDraftReducer.templateHasErrors;
  const issueTemplate = state.issueTemplateDraftReducer.issueTemplate;
  const templateGroups = issueTemplate?.taskTemplateGroups || [];

  const selectedTask: IssueTemplateDraftTaskTemplateGroupTaskTemplate | null = run(() => {
    const selectedTaskID = state.issueTemplateDraftReducer.selectedTaskId;
    if (selectedTaskID) {
      for (const tg of templateGroups || []) {
        for (const task of tg.taskTemplates) {
          if (task._id === selectedTaskID) {
            return task;
          }
        }
      }
    }

    return null;
  });

  const selectedTemplateGroup = selectedTask
    ? templateGroups.find((g) => g.taskTemplates.some((t) => t._id === selectedTask._id))
    : null;

  return {
    issueTemplate,
    templateGroups,
    selectedTemplateGroup,
    selectedTask,
    focusedInput: state.issueTemplateDraftReducer.firstTime,
    templateHasErrors,
  };
}

export const useIssueTemplateSelector = () => useSelector(issueTemplateSelector);

export const withIssueTemplateConnector = connect(issueTemplateSelector, issueTemplateDraftSlice.actions);

export type WithIssueTemplateConnectorProps = ConnectedProps<typeof withIssueTemplateConnector>;

export const {
  updateTemplateErrors,
  updateIssueTemplateDraftState,
  updateTaskInputState,
  updateTemplateTaskState,
  addInputState,
  changeSelectedTask,
  setFirstTime,
} = issueTemplateDraftSlice.actions;

export default issueTemplateDraftSlice.reducer;
