import {
  AddCircle,
  ArrowDropDown,
  CallSplit,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  FeedbackOutlined,
  InsertDriveFileOutlined,
  LiveHelpOutlined,
  RemoveCircleOutlineOutlined,
  ReportProblemOutlined,
  Settings,
} from '@mui/icons-material';
import { Chip, Dialog, Divider, MenuItem, Popover, Select, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  withIssueTemplateConnector,
  WithIssueTemplateConnectorProps,
} from 'src/modules/issue-templates/redux/issue.template.draft.redux';
import DefaultButton from 'src/utils/components/default-button';
import ActionDraftPopover from 'src/utils/components/generic-input-card/components/action-draft-popover';
import { styles } from 'src/utils/components/generic-input-card/styles';

interface Input {
  [x: string]: any;
  _id: string;
  name: string;
  type: string;
  order: number;
  required: boolean;
  options: string[];
  subType?: string;
  container?: string;
  number?: {
    validations?: {
      name?: string;
      min?: number;
      max?: number;
      inclusive?: boolean;
      notBetween?: any[] | undefined;
    }[];
    onResponse?: OnResponse[];
    unit?: {
      _id: string;
    };
  };
  multiple?: {
    maxValues: number;
    validations: {
      min: number;
      max: number;
      regex: string;
      name: string;
    }[];
  };
  string?: {
    maxValues: number;
    validations: {
      min: number;
      max: number;
      regex: string;
      name: string;
    }[];
  };
}

interface OnResponse {
  inputs?: Input[];
  actionRequired?: boolean;
  ifValues: number[];
  _id?: string;
  color?: string;
  condition?: string;
  actionDraft?: string;
  createAlert?: boolean;
  failResponse?: boolean;
  createTasks?: boolean;
  repeatTasks?: boolean;
  repeatTasksIds?: string[];
  finishIssue?: boolean;
}

interface NumberChipConditionalProps extends WithIssueTemplateConnectorProps {
  classes: Record<string, string>;
  t?: any;
  setInput: (input: Partial<Input>) => void;
  active?: boolean;
  refNumberCondFirstSelect: any;
  refNumberCondSecondSelect: any;
  level: number;
  error: any;
  input: Input;
  setActionDraftsToDeletefromDraft: (data) => void;
  setActionDraftsToDeletefromTemplate: (data) => void;
  associateTaskToInput: (responseId, task, associate, input?) => Promise<void>;
  taskId: string;
  addTask: (order, group, taskId, onResponseId, repeatTasks?) => Promise<void>;
  repeatTasksFunction: (tasksIds: string[]) => void;
}

/* const colors = ['#B468FF', '#00BF9D', '#FE8947', '#ACED24']; */

const NumberChipConditional = (props: NumberChipConditionalProps): JSX.Element => {
  const {
    classes,
    input,
    setInput,
    active,
    refNumberCondFirstSelect,
    refNumberCondSecondSelect,
    taskId,
    addTask,
    selectedTemplateGroup,
  } = props;
  const [deleteModal, setDeleteModal] = useState<any>(null);
  const [openActionDraftPopover, setOpenActionDraftPopover] = useState<any>(null);
  const [openSelectTaskPopover, setOpenSelectTaskPopover] = useState<{
    onResponseId: string;
    anchor: Element;
    repeatTasks: boolean;
  }>(null);
  const [idsToDisassociate, setIdsToDisassociate] = useState<string[]>([]);

  const showFilter = (response: OnResponse) => {
    if (response.condition === 'BETWEEN') {
      return (
        <Chip
          label={
            <div className={classes.divLabel}>
              <TextField
                value={response.ifValues[0] !== null ? response.ifValues[0] : ''}
                variant={'standard'}
                type={'number'}
                disabled={!active}
                placeholder={props.t('min')}
                InputProps={{ classes: { input: classes.inputValidations } }}
                onChange={(e): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: input.number?.onResponse?.map((r) =>
                        r._id === response._id ? { ...r, ifValues: [parseFloat(e.target.value), r.ifValues[1]] } : r,
                      ),
                    },
                  });
                }}
              />
              <TextField
                value={response.ifValues[1] !== null ? response.ifValues[1] : ''}
                variant={'standard'}
                type={'number'}
                placeholder={props.t('max')}
                disabled={!active}
                style={{ marginLeft: '10px' }}
                InputProps={{ classes: { input: classes.inputValidations } }}
                onChange={(e): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: input.number?.onResponse?.map((r) =>
                        r._id === response._id ? { ...r, ifValues: [r.ifValues[0], parseFloat(e.target.value)] } : r,
                      ),
                    },
                  });
                }}
              />
            </div>
          }
          style={{ display: 'contents' }}
          classes={{ root: classes.advancedChip }}
        />
      );
    } else if (response.condition === 'NOT_BETWEEN') {
      return (
        <Chip
          label={
            <div className={classes.divLabel}>
              <TextField
                value={response.ifValues[0] !== null ? response.ifValues[0] : ''}
                variant={'standard'}
                type={'number'}
                disabled={!active}
                placeholder={props.t('min')}
                InputProps={{ classes: { input: classes.inputValidations } }}
                onChange={(e): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: input.number?.onResponse?.map((r) =>
                        r._id === response._id ? { ...r, ifValues: [parseFloat(e.target.value), r.ifValues[1]] } : r,
                      ),
                    },
                  });
                }}
              />
              <TextField
                value={response.ifValues[1] !== null ? response.ifValues[1] : ''}
                variant={'standard'}
                type={'number'}
                disabled={!active}
                placeholder={props.t('max')}
                style={{ marginLeft: '10px' }}
                InputProps={{ classes: { input: classes.inputValidations } }}
                onChange={(e): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: input.number?.onResponse?.map((r) =>
                        r._id === response._id ? { ...r, ifValues: [r.ifValues[0], parseFloat(e.target.value)] } : r,
                      ),
                    },
                  });
                }}
              />
            </div>
          }
          style={{ display: 'contents' }}
          classes={{ root: classes.advancedChip }}
        />
      );
    } else {
      return (
        <Chip
          label={
            <div className={classes.divLabel}>
              <TextField
                value={response.ifValues[0] !== null ? response.ifValues[0] : ''}
                variant={'standard'}
                type={'number'}
                disabled={!active}
                InputProps={{ classes: { input: classes.inputValidations } }}
                onChange={(e): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: input.number?.onResponse?.map((r) =>
                        r._id === response._id ? { ...r, ifValues: [parseFloat(e.target.value)] } : r,
                      ),
                    },
                  });
                }}
              />
            </div>
          }
          style={{ display: 'contents' }}
          classes={{ root: classes.advancedChip }}
        />
      );
    }
  };

  const taskSelected = (taskId: string) => {
    let selected = false;

    input.number.onResponse.map((response) =>
      response.repeatTasksIds?.length !== 0
        ? response.repeatTasksIds?.includes(taskId)
          ? (selected = true)
          : null
        : [],
    );

    return selected;
  };

  const showChip = (): JSX.Element[] => {
    if (input.type === 'number' && input.number?.onResponse?.length) {
      return input.number?.onResponse.map((response, index) => {
        return response.repeatTasks ? (
          <Chip
            key={index}
            label={
              <div className={classes.divLabel}>
                <Chip
                  icon={<AddCircle classes={{ root: classes.iconChipAddCircle }} />}
                  label={<span>{`${props.t('repeatTask')}: `}</span>}
                  classes={{
                    root: classes.repeatTaskChip,
                  }}
                />
                {selectedTemplateGroup?.taskTemplates
                  ?.filter((t) => response.repeatTasksIds?.includes(t._id))
                  ?.map((t) => (
                    <span key={t._id} className={classes.secondSpanCreateTask}>
                      {t.name}
                    </span>
                  ))}
                {!active ? null : (
                  <>
                    <ArrowDropDown
                      classes={{ root: classes.createTaskDropdownIcon }}
                      onClick={(e) => {
                        setOpenSelectTaskPopover({
                          anchor: e.currentTarget,
                          onResponseId: response._id,
                          repeatTasks: true,
                        });
                      }}
                    />
                  </>
                )}
                <span>{props.t('repeatNumberTimes')}</span>
                {!active ? null : (
                  <>
                    <Close
                      classes={{ root: classes.closeIcon }}
                      onClick={(): void => {
                        if (response.actionRequired || !response.inputs?.length) {
                          if (response.actionDraft)
                            props.setActionDraftsToDeletefromTemplate((prev) => [...prev, response.actionDraft]);
                          setInput({
                            _id: input._id,
                            number: {
                              ...input.number,
                              onResponse: input.number?.onResponse?.filter((r) => r._id !== response._id),
                            },
                          });
                        } else {
                          setDeleteModal(response);
                        }
                      }}
                    />
                  </>
                )}
              </div>
            }
            classes={{
              root: props.error && props.error[response._id || ''] ? classes.advancedChipError : classes.advancedChip,
            }}
          />
        ) : (
          <Chip
            icon={<CallSplit style={{ transform: 'rotate(90deg)' }} classes={{ root: classes.iconChip }} />}
            key={index}
            label={
              <div className={classes.divLabel}>
                {`${props.t('ifnumberIs')}: `}
                <Select
                  value={response.condition}
                  id={response._id}
                  disabled={!active}
                  key={response._id}
                  variant={'standard'}
                  style={{ marginLeft: '10px' }}
                  MenuProps={{ ref: refNumberCondFirstSelect }}
                  classes={{ select: classes.selectConditional }}
                  placeholder={props.t('selectOption')}
                  onChange={(e) => {
                    setInput({
                      _id: input._id,
                      number: {
                        ...input.number,
                        //@ts-ignore
                        onResponse: input.number?.onResponse?.map((r) =>
                          r._id === response._id
                            ? {
                                ...r,
                                ifValues:
                                  e.target.value === 'BETWEEN' || e.target.value === 'NOT_BETWEEN'
                                    ? [null, null]
                                    : [null],
                                condition: e.target.value,
                              }
                            : r,
                        ),
                      },
                    });
                  }}
                >
                  <MenuItem value={'BETWEEN'}>{props.t('BETWEEN')}</MenuItem>
                  <MenuItem value={'NOT_BETWEEN'}>{props.t('NOT_BETWEEN')}</MenuItem>
                  <MenuItem value={'EQUALS'}>{props.t('EQUALS')}</MenuItem>
                  <MenuItem value={'MORE_THAN'}>{props.t('MORE_THAN')}</MenuItem>
                  <MenuItem value={'LESS_THAN'}>{props.t('LESS_THAN')}</MenuItem>
                  <MenuItem value={'LESS_EQUAL_THAN'}>{props.t('LESS_EQUAL_THAN')}</MenuItem>
                  <MenuItem value={'MORE_EQUAL_THAN'}>{props.t('MORE_EQUAL_THAN')}</MenuItem>
                </Select>
                {response.condition ? showFilter(response) : null}
                {response.condition ? (
                  <>
                    {`, ${props.t('then')}`}
                    {response.actionRequired ? (
                      <Chip
                        size='small'
                        classes={{ root: classes.chipNumberCreateAction }}
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FeedbackOutlined classes={{ root: classes.feedbackIcon }} />
                            {props.t('createAction')}
                          </div>
                        }
                      />
                    ) : undefined}
                    {response.failResponse ? (
                      <Chip
                        size='small'
                        classes={{ root: classes.chipNumberFailResponse }}
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <RemoveCircleOutlineOutlined classes={{ root: classes.failResponseIcon }} />
                            {props.t('failResponse')}
                          </div>
                        }
                      />
                    ) : undefined}
                    {response.createTasks ? (
                      <Chip
                        size='small'
                        classes={{ root: classes.chipNumberCreateTasks }}
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <InsertDriveFileOutlined classes={{ root: classes.createTasksIcon }} />
                            {props.t('executeNewTask')}
                          </div>
                        }
                      />
                    ) : undefined}
                    {response.createAlert ? (
                      <Chip
                        size='small'
                        classes={{ root: classes.chipNumberCreateAlert }}
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ReportProblemOutlined classes={{ root: classes.alertIcon }} />
                            {props.t('createAlert')}
                          </div>
                        }
                      />
                    ) : undefined}
                    {response.inputs?.length ? (
                      <Chip
                        size='small'
                        classes={{ root: classes.chipNumberNewAnswer }}
                        label={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LiveHelpOutlined classes={{ root: classes.liveHelpIcon }} />
                            {props.t('answerNewQuestion')}
                          </div>
                        }
                      />
                    ) : undefined}
                  </>
                ) : undefined}
                {response.actionDraft && response.actionRequired ? (
                  <span className={classes.spanCreateAction}>
                    {`${props.t('defaultSettingsPopover')}`}
                    {active ? (
                      <Close
                        classes={{ root: classes.closeIconActionDefaultSettings }}
                        onClick={() => {
                          props.setActionDraftsToDeletefromTemplate((prev) => [...prev, response.actionDraft]);
                          setInput({
                            _id: input._id,
                            number: {
                              ...input.number,
                              //@ts-ignore
                              onResponse: input.number?.onResponse?.map((r) =>
                                r._id === response._id
                                  ? {
                                      ...r,
                                      actionDraft: null,
                                    }
                                  : r,
                              ),
                            },
                          });
                        }}
                      />
                    ) : undefined}
                  </span>
                ) : undefined}
                {response.createTasks
                  ? selectedTemplateGroup?.taskTemplates
                      ?.filter((t) => t.onResponseId === response._id)
                      ?.map((t) => (
                        <span key={t._id} className={classes.secondSpanCreateTask}>
                          {t.name}
                        </span>
                      ))
                  : undefined}
                {active && response.createTasks ? (
                  <>
                    <ArrowDropDown
                      classes={{ root: classes.createTaskDropdownIcon }}
                      onClick={(e) => {
                        setOpenSelectTaskPopover({
                          anchor: e.currentTarget,
                          onResponseId: response._id,
                          repeatTasks: false,
                        });
                      }}
                    />
                  </>
                ) : null}
                {!active ? null : (
                  <>
                    {response.actionRequired ? (
                      <span
                        style={{ alignItems: 'center', display: 'flex' }}
                        onClick={() => {
                          setOpenActionDraftPopover(response);
                        }}
                      >
                        <Settings classes={{ root: classes.settingsIcon }} />
                      </span>
                    ) : undefined}
                    <Close
                      classes={{ root: classes.closeIcon }}
                      onClick={(): void => {
                        if (response.actionRequired || !response.inputs?.length) {
                          if (response.actionDraft)
                            props.setActionDraftsToDeletefromTemplate((prev) => [...prev, response.actionDraft]);
                          setInput({
                            _id: input._id,
                            number: {
                              ...input.number,
                              onResponse: input.number?.onResponse?.filter((r) => r._id !== response._id),
                            },
                          });
                        } else {
                          setDeleteModal(response);
                        }
                      }}
                    />
                  </>
                )}
              </div>
            }
            classes={{
              root: props.error && props.error[response._id || ''] ? classes.advancedChipError : classes.advancedChip,
            }}
          />
        );
      });
    } else return [<div />];
  };

  useEffect(() => {
    if (idsToDisassociate.length) {
      for (let i = 0; i < idsToDisassociate.length; i++) {
        props.associateTaskToInput(openSelectTaskPopover.onResponseId, { _id: idsToDisassociate[i] }, false);
      }
      setIdsToDisassociate([]);
    }
  }, [idsToDisassociate]);

  return (
    <div className={classes.numberChipCondContainer}>
      {showChip()}
      {!!openActionDraftPopover ? (
        <ActionDraftPopover
          onClose={(action) => {
            if (action) {
              props.setActionDraftsToDeletefromDraft((prev) => [...prev, action._id]);
              setInput({
                _id: input._id,
                [input.type]: {
                  ...input[input.type],
                  onResponse: input[input.type].onResponse.map((onRespon: OnResponse) =>
                    onRespon._id === openActionDraftPopover._id ? { ...onRespon, actionDraft: action._id } : onRespon,
                  ),
                },
              });
            }
            setOpenActionDraftPopover(null);
          }}
          actionDraft={openActionDraftPopover.actionDraft}
        />
      ) : undefined}
      {deleteModal ? (
        <Dialog
          open={!!deleteModal}
          onClose={(): void => {
            setDeleteModal(null);
          }}
        >
          <div className={classes.dialogContent}>
            <div className={classes.divTextTitle}>{`${props.t('deleteConditionalQuestions')}?`}</div>
            <div className={classes.divText}>{`${props.t('deleteConditionalQuestionsText')}`}</div>
            <div className={classes.divBtnsModal}>
              <DefaultButton
                id={'buttonCancel'}
                discard
                onClick={(): void => {
                  setDeleteModal(null);
                }}
              >
                {props.t('cancel')}
              </DefaultButton>
              <DefaultButton
                id={'buttonDeleteCondition'}
                success
                onClick={(): void => {
                  setInput({
                    _id: input._id,
                    number: {
                      ...input.number,
                      onResponse: input.number?.onResponse?.filter((r) => r._id !== deleteModal._id),
                    },
                  });
                  setDeleteModal(null);
                }}
              >
                {props.t('delete')}
              </DefaultButton>
            </div>
          </div>
        </Dialog>
      ) : null}
      {openSelectTaskPopover ? (
        <Popover
          open={!!openSelectTaskPopover}
          anchorEl={openSelectTaskPopover.anchor}
          ref={refNumberCondSecondSelect}
          onClose={(): void => {
            setOpenSelectTaskPopover(null);
          }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {selectedTemplateGroup?.taskTemplates
            .filter(
              (t) =>
                t._id !== taskId &&
                (!t.onResponseId || openSelectTaskPopover.onResponseId === t.onResponseId) &&
                !selectedTemplateGroup?.taskTemplates.find(
                  (tt) => tt.parentsTree?.length && tt.parentsTree[0] === t._id,
                ),
            )
            .map((task) => (
              <MenuItem
                classes={{ root: classes.createTaskMenuItem }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (openSelectTaskPopover.repeatTasks) {
                    const updatedInput = {
                      ...input,
                      number: {
                        ...input.number,
                        onResponse: input.number?.onResponse?.map((r) =>
                          r.repeatTasks
                            ? r.repeatTasksIds?.includes(task._id)
                              ? { ...r, repeatTasksIds: [] }
                              : {
                                  ...r,
                                  repeatTasksIds: [task._id],
                                }
                            : null,
                        ),
                      },
                    };
                    setInput(updatedInput);

                    //remove the response id from the task if we deselect or choose other option
                    const ids = [];
                    input.number?.onResponse?.map((r) => (r.repeatTasks ? ids.push(...r.repeatTasksIds) : null));
                    setIdsToDisassociate(ids);

                    props.repeatTasksFunction(ids.includes(task._id) ? [] : [task._id]);
                    return props.associateTaskToInput(
                      openSelectTaskPopover.onResponseId,
                      task,
                      !task.onResponseId,
                      updatedInput,
                    );
                  } else {
                    return props.associateTaskToInput(openSelectTaskPopover.onResponseId, task, !task.onResponseId);
                  }
                }}
              >
                <div key={task._id} style={{ display: 'flex', alignItems: 'center' }}>
                  {openSelectTaskPopover.repeatTasks ? (
                    taskSelected(task._id) ? (
                      <CheckBox classes={{ root: classes.createTaskCheck }} />
                    ) : (
                      <CheckBoxOutlineBlank classes={{ root: classes.createTaskCheckEmpty }} />
                    )
                  ) : task.onResponseId === openSelectTaskPopover.onResponseId ? (
                    <CheckBox classes={{ root: classes.createTaskCheck }} />
                  ) : (
                    <CheckBoxOutlineBlank classes={{ root: classes.createTaskCheckEmpty }} />
                  )}
                </div>
                {task.name}
              </MenuItem>
            ))}
          <Divider />
          <MenuItem
            id={'menuItemAddNewTask'}
            data-testid={'menuItemAddNewTask'}
            classes={{ root: classes.createTaskMenuItem }}
            style={{ marginLeft: '3px' }}
            onClick={(e) => {
              e.stopPropagation();
              addTask(
                selectedTemplateGroup.taskTemplates.length,
                selectedTemplateGroup,
                taskId,
                openSelectTaskPopover.onResponseId,
                openSelectTaskPopover.repeatTasks,
              );
            }}
          >
            {`+ ${props.t('addNewTask')}`}
          </MenuItem>
        </Popover>
      ) : undefined}
    </div>
  );
};

export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  withIssueTemplateConnector,
)(NumberChipConditional);
