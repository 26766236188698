import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { NexusGenFieldTypes, NexusGenInputs } from '@server/src/types';
import { APOLLO_CLIENT } from 'config/apollo.config';
import { orderBy } from 'lodash';
import { Dispatch } from 'redux';
import {
  GetIssueTemplateDraftDocument,
  GetIssueTemplateDraftToHeatmapDocument,
  IssueTemplate,
  IssueTemplateDraft,
  IssueTemplateDraftUpdateInput,
  IssueTemplateDraftWhereUniqueInput,
  IssueTemplateFolder,
  IssueTemplateOrderByInput,
  IssueTemplateUpdateInput,
  PresetWhereInput,
} from 'src/gql/graphql';
import * as queries from 'src/modules/issue-templates/issue.templates.queries';
import { addSpace, getErrorObject } from 'src/utils/funcs';
import i18n from 'src/utils/translations/i18n';

export const createPreset = (templateId: string, preset: PresetWhereInput) => async (dispatch: Dispatch) => {
  try {
    const response: { data } = await APOLLO_CLIENT.query({
      variables: {
        templateId,
        preset,
      },
      fetchPolicy: 'no-cache',
      query: queries.M_CREATE_PRESET,
    });

    return response.data.createPreset;
  } catch (error) {
    const obj = getErrorObject(error, '');
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const editPreset =
  (templateId: string, where: PresetWhereInput, presetId?: string, presetIds: string[] | null = []) =>
  async (dispatch: Dispatch) => {
    try {
      const response: { data } = await APOLLO_CLIENT.query({
        variables: {
          templateId,
          where,
          presetId,
          presetIds,
        },
        fetchPolicy: 'no-cache',
        query: queries.M_EDIT_PRESET,
      });

      return response.data.editPreset;
    } catch (error) {
      const obj = getErrorObject(error);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueTemplates = (filter: NexusGenInputs['IssueTemplateWhereInput']) => async () => {
  try {
    const response: { data: { issueTemplates: NexusGenFieldTypes['IssueTemplate'][] } } = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ISSUES_TEMPLATES,
    });

    return response.data.issueTemplates;
  } catch (error) {
    return error;
  }
};

export const getIssueTemplatesLean = (filter: NexusGenInputs['IssueTemplateWhereInput']) => async () => {
  try {
    const response: { data: { issueTemplates: NexusGenFieldTypes['IssueTemplate'][] } } = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ISSUES_TEMPLATES_LEAN,
    });

    return response.data.issueTemplates;
  } catch (error) {
    return error;
  }
};

export const getIssueTemplateLibrary =
  (search: string | null, type: string | null, skip: number, limit: number, orderBy?: [IssueTemplateOrderByInput]) =>
  async (): Promise<{
    issueTemplateLibrary: { _id: string; title: string; folder: { _id; name: string } }[];
    issueTemplateLibraryCount: number;
    graphQLErrors?: string;
  }> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          search,
          type,
          limit,
          skip,
          orderBy: orderBy?.length ? orderBy : ['title_ASC'],
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUES_TEMPLATES_LIBRARY,
      });

      return response.data;
    } catch (error) {
      return error;
    }
  };

export const getIssueTemplateDraft = (filter: IssueTemplateDraftWhereUniqueInput) => async () => {
  try {
    const response: { data: { issueTemplateDraft: IssueTemplateDraft } } = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: GetIssueTemplateDraftDocument,
    });

    return response.data.issueTemplateDraft;
  } catch (error) {
    return error;
  }
};

export const getIssueTemplateToHeatmap = (filter: IssueTemplateDraftWhereUniqueInput) => async () => {
  try {
    const response: { data: { issueTemplateDraft: IssueTemplateDraft[] } } = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: GetIssueTemplateDraftToHeatmapDocument,
    });

    return response.data.issueTemplateDraft;
  } catch (error) {
    return error;
  }
};

export const createIssueTemplate = (folderId: string) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ createIssueTemplateDraft: NexusGenFieldTypes['IssueTemplateDraft'] }> =
      await APOLLO_CLIENT.mutate({
        variables: {
          title: i18n.t('New Issue Template'),
          folder: folderId,
          frame: {
            sequential: false,
            inputs: [],
            estimatedDurationFromTasks: false,
          },
          taskTemplateGroups: [
            {
              name: `Group 1`,
            },
          ],
        },
        mutation: queries.M_CREATE_ISSUES_TEMPLATE,
      });

    if (response) return response.data.createIssueTemplateDraft;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const createDraftFromIssueTemplate = (id: string, folder?: string) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ createDraftFromIssueTemplate: NexusGenFieldTypes['IssueTemplateDraft'] }> =
      await APOLLO_CLIENT.mutate({
        variables: {
          id,
          folder,
        },
        mutation: queries.M_CREATE_DRAFT_FROM_ISSUE_TEMPLATE,
      });

    if (response) return response.data.createDraftFromIssueTemplate;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const upsertIssueTemplateFromDraft =
  (id: string, create = false, doingOnBoarding?: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      const response: FetchResult<{ upsertIssueTemplateFromDraft: NexusGenFieldTypes['IssueTemplate'] }> =
        await APOLLO_CLIENT.mutate({
          variables: {
            id,
          },
          mutation: queries.M_CREATE_COMMIT_ISSUE_TEMPLATE,
        });

      !doingOnBoarding &&
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + ' ' + i18n.t(create ? 'toastCreateSuccess' : 'toastUpdateSuccess'),
            severity: 'success',
          },
        });

      if (response) return response.data.upsertIssueTemplateFromDraft;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateIssueTemplate = (data: Partial<IssueTemplateDraftUpdateInput>, _id: string) => async () => {
  try {
    const response: FetchResult<{ updateIssueTemplateDraft: NexusGenFieldTypes['IssueTemplateDraft'] }> =
      await APOLLO_CLIENT.mutate({
        variables: {
          data,
          _id,
        },
        mutation: queries.M_UPDATE_ISSUES_TEMPLATE,
      });
    return response.data.updateIssueTemplateDraft;
  } catch (error) {
    return error;
  }
};

export const updateTemplate = (data: IssueTemplateUpdateInput, _id: string) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ updateIssueTemplate: IssueTemplate }> = await APOLLO_CLIENT.mutate({
      variables: {
        data,
        _id,
      },
      mutation: queries.M_UPDATE_TEMPLATE,
    });
    return response.data.updateIssueTemplate;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const moveIssueTemplates = (folder: string, _ids: string[]) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ moveIssueTemplates: NexusGenFieldTypes['IssueTemplate'][] }> =
      await APOLLO_CLIENT.mutate({
        variables: {
          folder,
          _ids,
        },
        mutation: queries.M_MOVE_ISSUE_TEMPLATES,
      });
    return response.data.moveIssueTemplates;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const deleteIssueTemplate =
  (issueTemplateData: { title: string; _id: string }, doingOnBoarding?: boolean) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['IssueTemplate']> => {
    try {
      const response: FetchResult<{ deleteOneIssueTemplate: NexusGenFieldTypes['IssueTemplate'] }> =
        await APOLLO_CLIENT.mutate({
          variables: {
            id: issueTemplateData._id,
          },
          mutation: queries.M_DELETE_ISSUES_TEMPLATE,
        });

      !doingOnBoarding &&
        dispatch({
          type: 'SNACKBAR_NEW_MESSAGE',
          payload: {
            message: i18n.t('Template') + addSpace(issueTemplateData.title) + i18n.t('toastDeleteSuccess'),
            severity: 'success',
          },
        });

      return response.data.deleteOneIssueTemplate;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteOneIssueTemplateDraft =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: {
          id: id,
        },
        mutation: queries.M_DELETE_ISSUE_TEMPLATE_DRAFT,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getIssueTemplatesFromFolders =
  (folderId: string) =>
  async (dispatch: Dispatch): Promise<object> => {
    try {
      const response: { data: { issueTemplates: NexusGenFieldTypes['IssueTemplate'][] } } = await APOLLO_CLIENT.query({
        variables: {
          filter: { folder: { _id_eq: folderId }, archivedBy_exists: false },
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUES_TEMPLATES_TO_TREE,
      });

      return response.data.issueTemplates;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getFoldersToModal =
  (options: { parent_exists: boolean; parent_eq: string }, item: { totalFolders: number; totalItems: number }) =>
  //eslint-disable-next-line
  async (): Promise<any> => {
    try {
      let response: ApolloQueryResult<{ issueTemplateFolders: NexusGenFieldTypes['IssueTemplateFolder'][] }> | null =
        null;
      if (item?.totalFolders || options.parent_exists !== undefined) {
        response = await APOLLO_CLIENT.query({
          variables: { where: options },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ISSUE_TEMPLATE_FOLDERS,
        });
      }

      let issueTemplates: ApolloQueryResult<{ issueTemplates: NexusGenFieldTypes['IssueTemplate'][] }> | null = null;
      if (options.parent_eq && item.totalItems) {
        issueTemplates = await APOLLO_CLIENT.query({
          variables: { filter: { folder: { _id_eq: options.parent_eq } } },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ISSUES_TEMPLATES_TO_TREE,
        });
      }
      return orderBy(
        [
          ...(response ? response.data.issueTemplateFolders : []),
          ...(issueTemplates ? issueTemplates.data.issueTemplates : []),
        ],
        [
          (o): string => {
            //eslint-disable-next-line
            //@ts-ignore
            return o.name || o.title;
          },
        ],
        'asc',
      );
    } catch (error) {
      console.error('getFoldersToModal: ', error);
    }
  };

export const createFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }) =>
  async (dispatch: Dispatch) => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent?._id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_CREATE_ISSUE_TEMPLATE_FOLDER,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastCreateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateFolder =
  ({ name, parent = null }: { name: string; parent: { _id: string } | null }, { _id }) =>
  async (dispatch: Dispatch) => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: { name, parent: parent === null ? parent : parent?._id, _id },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_ISSUE_TEMPLATE_FOLDER,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(name) + i18n.t('toastUpdateSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const deleteFolder =
  (folderData) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: { where: { _id: folderData.id } },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DELETE_ISSUE_TEMPLATE_FOLDER,
      });
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('folder') + addSpace(folderData.name) + i18n.t('toastDeleteSuccess'),
          severity: 'success',
        },
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };
export const getAllIssuesIds =
  (id: string) =>
  async (dispatch: Dispatch): Promise<string[]> => {
    try {
      const allIssues: FetchResult<{ issueTemplates: Pick<IssueTemplate, 'variant'>[] }> = await APOLLO_CLIENT.mutate({
        variables: { filter: { folder: { _id_eq: id } } },
        fetchPolicy: 'no-cache',
        mutation: queries.Q_GET_ISSUES_TEMPLATES_BY_FATHER_ID,
      });
      const issueIds = allIssues.data.issueTemplates.map((issue) => issue.variant.variantId);

      return issueIds;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const getTaskTemplateGroups =
  (where: NexusGenInputs['TaskTemplateGroupWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response: { data: { taskTemplateGroups: NexusGenFieldTypes['TaskTemplateGroup'][] } } =
        await APOLLO_CLIENT.query({
          variables: { where },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_TASK_TEMPLATE_GROUPS,
        });

      return response.data.taskTemplateGroups;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateTaskTemplateGroup =
  (data: NexusGenInputs['TaskGroupUpdateInput'], id: string, draft: string) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['IssueTemplateDraft']> => {
    try {
      const resp: FetchResult<{ updateTaskTemplateGroupDraft: NexusGenFieldTypes['IssueTemplateDraft'] }> =
        await APOLLO_CLIENT.mutate({
          variables: {
            data,
            id,
            draft,
          },
          fetchPolicy: 'no-cache',
          mutation: queries.M_UPDATE_TASK_TEMPLATE_GROUP,
        });
      return resp.data.updateTaskTemplateGroupDraft;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const taskGroupDraftsByLabelFilter =
  (id: string, filter: NexusGenInputs['TaskTemplateAdvancedLabelSelectionFilterCreateInput'][]) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['IssueTemplateDraft']> => {
    try {
      const resp: FetchResult<{ taskGroupDraftsByLabelFilter: NexusGenFieldTypes['IssueTemplateDraft'] }> =
        await APOLLO_CLIENT.mutate({
          variables: {
            _id: id,
            filter: filter,
          },
          fetchPolicy: 'no-cache',
          mutation: queries.M_TASK_GROUP_DRAFTS_LABEL_FILTER,
        });
      return resp.data.taskGroupDraftsByLabelFilter;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const taskTemplateGroupsByLabelFilter =
  (id: string, filter: NexusGenInputs['TaskTemplateAdvancedLabelSelectionFilterCreateInput'][]) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['IssueTemplate']> => {
    try {
      const resp: { data: { taskTemplateGroupsByLabelFilter: NexusGenFieldTypes['IssueTemplate'] } } =
        await APOLLO_CLIENT.query({
          variables: {
            _id: id,
            filter: filter,
          },
          fetchPolicy: 'no-cache',
          query: queries.M_TASK_GROUP_LABEL_FILTER,
        });
      return resp.data.taskTemplateGroupsByLabelFilter;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getTimeTable =
  (id: string) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['timeTableSelectionResult'][]> => {
    try {
      const resp: { data: { getTimeTable: NexusGenFieldTypes['timeTableSelectionResult'][] } } =
        await APOLLO_CLIENT.query({
          variables: {
            _id: id,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_TIME_TABLE,
        });
      return resp.data.getTimeTable;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getDraftTimeTable =
  (id: string) =>
  async (
    dispatch: Dispatch,
  ): Promise<{
    columns: {
      groupId: string;
      min: number;
      max: number;
    }[];
  }> => {
    try {
      const resp: {
        data: {
          getDraftTimeTable: {
            columns: {
              groupId: string;
              min: number;
              max: number;
            }[];
          };
        };
      } = await APOLLO_CLIENT.query({
        variables: {
          _id: id,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_DRAFT_TIME_TABLE,
      });
      return resp.data.getDraftTimeTable;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getTimeMap =
  (id: string) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['timeMapSelectionResult']> => {
    try {
      const resp: { data: { getTimeMap: NexusGenFieldTypes['timeMapSelectionResult'] } } = await APOLLO_CLIENT.query({
        variables: {
          _id: id,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_TIME_MAP,
      });
      return resp.data.getTimeMap;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getDraftTimeMap =
  (id) =>
  async (
    dispatch: Dispatch,
  ): Promise<{
    columns: {
      groupId: string;
      time: number;
    }[];
  }> => {
    try {
      const resp: {
        data: {
          getDraftTimeMap: {
            columns: {
              groupId: string;
              time: number;
            }[];
          };
        };
      } = await APOLLO_CLIENT.query({
        variables: {
          _id: id,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_DRAFT_TIME_MAP,
      });
      return resp.data.getDraftTimeMap;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const duplicateIssueTemplate = (_id: string, copyChildren: boolean) => async (dispatch: Dispatch) => {
  try {
    const response: FetchResult<{ duplicateIssueTemplate: NexusGenFieldTypes['IssueTemplate'] }> =
      await APOLLO_CLIENT.mutate({
        variables: { _id, copyChildren },
        fetchPolicy: 'no-cache',
        mutation: queries.M_DUPLICATE_ISSUE_TEMPLATE,
      });

    return response.data.duplicateIssueTemplate;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getTimeMapColumns = (templateId: string | string[], isDraft: boolean) => async (dispatch: Dispatch) => {
  try {
    const filter = { _id: templateId };
    const response: {
      data: {
        issueTemplateDraft: NexusGenFieldTypes['IssueTemplateDraft'];
        issueTemplates: NexusGenFieldTypes['IssueTemplate'][];
      };
    } = isDraft
      ? await APOLLO_CLIENT.query({
          variables: {
            filter,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_DRAFT_TIME_MAP_COLUMNS,
        })
      : await APOLLO_CLIENT.query({
          variables: {
            filter,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_TIME_MAP_COLUMNS,
        });

    return isDraft ? response.data.issueTemplateDraft.timeMapColumns : response.data.issueTemplates[0].timeMapColumns;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const countIssueCatalogs = (filter: NexusGenInputs['IssueCatalogWhereInput']) => async (dispatch: Dispatch) => {
  try {
    const response: { data: { issueCatalogsCount: number } } = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_ISSUE_CATALOGS_COUNT,
    });

    return response.data.issueCatalogsCount;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });
    return error;
  }
};

export const getIssueTemplateFoldersToShow =
  (
    limit: number,
    skip: number,
    filterFolder: NexusGenInputs['IssueTemplateFolderWhereInput'],
    filterIssueTemplate: NexusGenInputs['IssueTemplateWhereInput'],
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<
    | {
        folders: NexusGenFieldTypes['IssueTemplateFolder'][];
        totalFolders: number;
        issueTemplates: NexusGenFieldTypes['IssueTemplate'][];
        totalIssueTemplates: number;
        breadcrumbFolder: NexusGenFieldTypes['IssueTemplateFolder'];
      }
    | { graphQLErrors: string }
  > => {
    try {
      const counts: { data: { issueTemplateFoldersCount: number; issueTemplatesCount: number } } =
        await APOLLO_CLIENT.query({
          variables: {
            whereFolder: filterFolder,
            whereIssueTemplate: filterIssueTemplate,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ISSUE_TEMPLATE_FOLDERS_COUNT,
        });

      const foldersLimit =
        counts.data.issueTemplateFoldersCount > skip && counts.data.issueTemplateFoldersCount < skip + limit
          ? counts.data.issueTemplateFoldersCount
          : counts.data.issueTemplateFoldersCount > skip
            ? limit
            : 0;

      let folder: { data: { issueTemplateFolder: NexusGenFieldTypes['IssueTemplateFolder'] } } = null;

      if (filterFolder.parent_eq)
        folder = await APOLLO_CLIENT.query({
          variables: {
            where: { _id: filterFolder.parent_eq },
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ISSUE_TEMPLATE_FOLDER,
        });
      let response: {
        data: {
          issueTemplates: NexusGenFieldTypes['IssueTemplate'][];
          issueTemplateFolders: NexusGenFieldTypes['IssueTemplateFolder'][];
        };
      };

      if (foldersLimit > 0 && limit - foldersLimit > 0) {
        response = await APOLLO_CLIENT.query({
          variables: {
            whereFolder: filterFolder,
            limitFolder: foldersLimit,
            skipFolder: skip,
            whereIssueTemplate: filterIssueTemplate,
            limitIssueTemplate: limit - foldersLimit,
            skipIssueTemplate: skip,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ISSUE_TEMPLATE_FOLDERS,
        });
      } else if (limit - foldersLimit > 0) {
        response = await APOLLO_CLIENT.query({
          variables: {
            whereIssueTemplate: filterIssueTemplate,
            limitIssueTemplate: limit - foldersLimit,
            skipIssueTemplate: skip,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ISSUE_TEMPLATES,
        });
      } else if (foldersLimit > 0) {
        response = await APOLLO_CLIENT.query({
          variables: {
            whereFolder: filterFolder,
            limitFolder: foldersLimit,
            skipFolder: skip,
          },
          fetchPolicy: 'no-cache',
          query: queries.Q_GET_ISSUE_TEMPLATE_FOLDERS_ONLY,
        });
      }

      return {
        folders: response.data.issueTemplateFolders ? response.data.issueTemplateFolders : [],
        totalFolders: counts.data.issueTemplateFoldersCount,
        issueTemplates: response.data.issueTemplates ? response.data.issueTemplates : [],
        totalIssueTemplates: counts.data.issueTemplatesCount,
        breadcrumbFolder: folder?.data.issueTemplateFolder,
      };
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error as { graphQLErrors: string };
    }
  };

export const getIssueTemplateFolders =
  (where: NexusGenInputs['IssueTemplateFolderWhereInput'], limit: number, skip: number) =>
  async (dispatch: Dispatch): Promise<IssueTemplateFolder[] & { graphQLErrors?: string }> => {
    try {
      const response = await APOLLO_CLIENT.query({
        variables: {
          where,
          limit,
          skip,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUE_TEMPLATE_FOLDERS_TREE,
      });

      return response.data.issueTemplateFolders;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error;
    }
  };

export const updateManyIssueTemplates =
  (where: NexusGenInputs['IssueTemplateWhereInput'], update: NexusGenInputs['IssueTemplateUpdateInput']) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['IssueTemplate'][] & { graphQLErrors: GraphQLErrors }> => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          where,
          update,
        },
        fetchPolicy: 'no-cache',
        mutation: queries.M_UPDATE_MANY_ISSUE_TEMPLATES,
      });

      return response.data.updateManyIssueTemplates;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteManyIssueTemplateFolders =
  (_ids: string[]) =>
  async (dispatch: Dispatch): Promise<NexusGenFieldTypes['IssueTemplateFolder'][] | { graphQLErrors: string }> => {
    try {
      const response: FetchResult<{ deleteManyIssueTemplateFolders: NexusGenFieldTypes['IssueTemplateFolder'][] }> =
        await APOLLO_CLIENT.mutate({
          variables: {
            _ids,
          },
          fetchPolicy: 'no-cache',
          mutation: queries.M_DELETE_ISSUE_TEMPLATE_FOLDERS,
        });

      return response.data.deleteManyIssueTemplateFolders;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });

      return error as { graphQLErrors: string };
    }
  };

export const upsertIssueTemplateVariantFromDraft =
  (id: string, variantName: string, variantDescription: string, variantPosition: number) =>
  async (dispatch: Dispatch) => {
    try {
      const response: FetchResult<{ upsertIssueTemplateVariantFromDraft: NexusGenFieldTypes['IssueTemplate'] }> =
        await APOLLO_CLIENT.mutate({
          variables: {
            id,
            variantName,
            variantDescription,
            variantPosition,
          },
          mutation: queries.M_CREATE_NEW_VARIANT_ISSUE_TEMPLATE,
        });

      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: i18n.t('newVariantCreated'),
          severity: 'success',
        },
      });

      if (response) return response.data.upsertIssueTemplateVariantFromDraft;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const countIssueTemplates =
  (filter: NexusGenInputs['IssueTemplateWhereInput']) => async (dispatch: Dispatch) => {
    try {
      const response: { data: { issueTemplatesCount: number } } = await APOLLO_CLIENT.query({
        variables: {
          filter,
        },
        fetchPolicy: 'no-cache',
        query: queries.Q_GET_ISSUE_TEMPLATES_COUNT,
      });

      return response.data.issueTemplatesCount;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const getHeatmapConfigurations = (templateId: string) => async (dispatch: Dispatch) => {
  try {
    const filter = { issueTemplate_eq: templateId };
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_HEATMAP_CONFIGURATIONS,
    });

    return response.data.heatmapConfigurations;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const getHeatmapConfigurationDrafts = (issueTemplateDraftId: string) => async (dispatch: Dispatch) => {
  try {
    const filter = { issueTemplateDraft_eq: issueTemplateDraftId };
    const response = await APOLLO_CLIENT.query({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      query: queries.Q_GET_HEATMAP_CONFIGURATION_DRAFTS,
    });

    return response.data.heatmapConfigurationDrafts;
  } catch (error) {
    const obj = getErrorObject(error, '', dispatch);
    dispatch({
      type: 'SNACKBAR_NEW_MESSAGE',
      payload: {
        message: obj.message,
        severity: 'error',
      },
    });

    return error;
  }
};

export const createHeatmapConfiguration =
  (data: NexusGenInputs['HeatmapConfigurationCreateInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
        },
        mutation: queries.M_CREATE_HEATMAP_CONFIGURATION,
      });

      if (response) return response.data;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const createHeatmapConfigurationDraft =
  (data: NexusGenInputs['HeatmapConfigurationDraftCreateInput']) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
        },
        mutation: queries.M_CREATE_HEATMAP_CONFIGURATION_DRAFT,
      });

      if (response) return response.data.createHeatmapConfigurationDraft;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateHeatmapConfiguration =
  (data: NexusGenInputs['HeatmapConfigurationUpdateInput'], _id: string) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          _id,
        },
        mutation: queries.M_UPDATE_HEATMAP_CONFIGURATION,
      });

      if (response) return response.data;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updateHeatmapConfigurationDraft =
  (data: NexusGenInputs['HeatmapConfigurationDraftUpdateInput'], _id: string) => async (dispatch: Dispatch) => {
    try {
      const response = await APOLLO_CLIENT.mutate({
        variables: {
          data,
          _id,
        },
        mutation: queries.M_UPDATE_HEATMAP_CONFIGURATION_DRAFT,
      });

      if (response) return response.data.updateHeatmapConfigurationDraft;
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  }; /* deleteOneHeatmapConfiguration */

export const deleteOneHeatmapConfiguration =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: {
          id: id,
        },
        mutation: queries.M_DELETE_HEATMAP_CONFIGURATION,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteOneHeatmapConfigurationDraft =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: {
          id: id,
        },
        mutation: queries.M_DELETE_HEATMAP_CONFIGURATION_DRAFT,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const deleteManyIssueTemplates =
  (where: NexusGenInputs['IssueTemplateWhereInput']) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: {
          where: where,
        },
        mutation: queries.M_DELETE_MANY_ISSUE_TEMPLATES,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };

export const updatePeriodicIssueTemplateDraft =
  (draftId: string, taskMap: Array<any>, groupMap: Array<any>, otherDraftAttributes: any) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await APOLLO_CLIENT.mutate({
        variables: {
          draftId,
          taskMap,
          groupMap,
          otherDraftAttributes,
        },
        mutation: queries.M_UPDATE_PERIODIC_ISSUE_TEMPLATE_DRAFT,
      });
    } catch (error) {
      const obj = getErrorObject(error, '', dispatch);
      dispatch({
        type: 'SNACKBAR_NEW_MESSAGE',
        payload: {
          message: obj.message,
          severity: 'error',
        },
      });
      return error;
    }
  };
