import { Popper, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { styles } from 'src/base/on-boarding/components/styles';
import { useMainContainerContext } from 'src/base/main-container/context/main.container.context';
import { updateLoggedUser } from 'src/base/login/login.redux';
import Tour from '@rc-component/tour';
import { PlacementType } from '@rc-component/tour/es/placements';
import { formatPlacement } from '../utils/onBoarding.utils.formatPlacement';
import { handleNextSubStep } from '../utils/onBoarding.utils.handleNextSubStep';
import { handleSkipSubSteps } from '../utils/onBoarding.utils.handleSkipSubSteps';
import { ArrowRight } from '@mui/icons-material';
import DefaultButton from 'src/utils/components/default-button/default-button';
import { getTourStepsData } from '../utils/onBoarding.utils.getTourStepsData';
import makeStyles from '@mui/styles/makeStyles';
import { ClassNameMap } from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';

export type TourSubStepsProps = {
  loggedUser: any;
} & ConnectedProps<typeof connecter>;

interface TourStep {
  title: string;
  description: string;
  target: HTMLElement;
  placement: PlacementType;
}

const useStyles = makeStyles(styles);
const TourSubStepsModal: React.FC<TourSubStepsProps> = (props): JSX.Element => {
  const { loggedUser } = props;
  const state = useMainContainerContext();
  const { updateMainContainerState, templateRefs, catalogsRefs, executionRefs, currentStep } = state;
  const classes: ClassNameMap<string> = useStyles();
  const arrowRef = useRef<SVGSVGElement>();
  const [tourSteps, setTourSteps] = useState<TourStep[]>([]);
  const { t } = useTranslation();

  const handleStepsDialog = (
    title: string,
    target: HTMLDivElement,
    placement: PlacementType,
    stepObject: TourStep[],
  ) => {
    const newObj = {
      title: title,
      description: '',
      target: target,
      placement: placement,
    };
    stepObject.push(newObj);
  };

  const generateTourSteps = () => {
    const subStepsDialog = getTourStepsData(currentStep, t, templateRefs, catalogsRefs, executionRefs);
    const stepObject: TourStep[] = [];
    subStepsDialog.map((dialog) => {
      handleStepsDialog(dialog.title, dialog.target.current, dialog.placement as PlacementType, stepObject);
    });
    setTourSteps(stepObject);
  };

  useEffect(
    () => generateTourSteps(),
    [state.currentStep, state.startSubSteps, loggedUser.onBoarding.currentSubStep, state.generateSubComponent],
  );

  const onNextClick = useCallback(() => {
    const stepsToGenerateComponents = [1, 2];
    if (
      (!stepsToGenerateComponents.includes(loggedUser.onBoarding.currentSubStep) && currentStep === 'build') ||
      currentStep !== 'build'
    ) {
      handleNextSubStep(state, updateMainContainerState, loggedUser?.onBoarding.currentSubStep, props, loggedUser);
    } else if (stepsToGenerateComponents.includes(loggedUser.onBoarding.currentSubStep) && currentStep === 'build') {
      updateMainContainerState({
        generateSubComponent: loggedUser.onBoarding.currentSubStep === 1 ? 'task' : 'input-card',
      });
    }
  }, [currentStep, JSON.stringify(loggedUser)]);

  const subStepPopup = (
    refCurrent: HTMLDivElement,
    dialog: React.ReactNode,
    placement: PlacementType,
    current: number,
  ) => {
    const { correspondingClass, correspondingArrow } = formatPlacement(placement, classes, state);

    return (
      <div>
        <Popper
          open={!!refCurrent}
          anchorEl={refCurrent}
          style={{
            color: '#fff',
            zIndex: 1302,
          }}
          popperOptions={{
            modifiers: [
              {
                keepTogether: {
                  enabled: true, // Attach arrow to popper
                },
              },
              {
                arrow: {
                  enabled: true,
                  element: arrowRef.current, // Arrow of the popper
                },
              },
              {
                offset: {
                  enabled: true,
                  offset: '0, 15', // Adjust the values to apply padding (top, left)
                },
              },
            ],
          }}
        >
          <ArrowRight
            ref={arrowRef}
            className={`${classes.subStepArrow} ${correspondingArrow} ${correspondingClass}`}
          />
          <Paper
            style={{
              padding: '14px',
              maxWidth: '370px',
              width: '370px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div
              data-testid={'skip-sub-step'}
              className={classes.skipSubStep}
              onClick={() =>
                handleSkipSubSteps(
                  state,
                  loggedUser?.onBoarding.maxSubStep,
                  props,
                  loggedUser,
                  updateMainContainerState,
                )
              }
            >
              {t('skip')}
            </div>
            <div id='text-body' data-testid='text-body'>
              <Typography className={classes.subStepDialog}>{dialog}</Typography>
            </div>
            <div className={classes.subStepFooter}>
              <DefaultButton
                data-testid={'next-sub-step'}
                className={classes.nextSubStep}
                rounded
                onClick={(e) => {
                  e.stopPropagation();
                  onNextClick();
                }}
                label={t('next')}
              />
              <span className={classes.subStepSteps}>
                {current + 1}/{loggedUser?.onBoarding.maxSubStep}
              </span>
            </div>
          </Paper>
        </Popper>
      </div>
    );
  };

  return (
    <Tour
      open={state.startSubSteps}
      current={loggedUser?.onBoarding.currentSubStep}
      steps={tourSteps}
      onFinish={() => updateMainContainerState({ startSubSteps: false })}
      mask={true}
      renderPanel={(props, current) =>
        subStepPopup(props.target as HTMLDivElement, props.title, props.placement, current)
      }
    />
  );
};

const connecter = connect(
  (state: any) => ({
    loggedUser: state.loginReducer.loggedUser,
  }),
  {
    updateLoggedUser,
  },
);

export default connecter(TourSubStepsModal);
