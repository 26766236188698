// import * as React from 'react';
import * as React from 'react';
import { styles } from 'src/utils/components/heat-map/heat-map-colors/styles';
import { Popover, Typography, Tooltip, TextField, Button, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Add, Close, Delete } from '@mui/icons-material';
import DefaultButton from 'src/utils/components/default-button';
import {
  updateTemplate,
  createHeatmapConfiguration,
  updateHeatmapConfigurationDraft,
} from 'src/modules/issue-templates/issue.templates.redux';
import { useCallback, useState, useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import i18n from 'src/utils/translations/i18n';
import { HeatmapConfigurationDraft } from 'src/utils/components/heat-map/index';
import { IssueTemplate, IssueTemplateDraft } from 'src/interfaces/issue';

interface HeatMapProps {
  classes: Record<string, string>;
  openFilter: boolean;
  onClose: () => void;
  colorFilters: ColorObject[];
  setColorFilters: (colorFilters: ColorObject[]) => void;
  taskTime: number;
  setTaskTime: (time: number) => void;
  heatmapConfigurationDraft: HeatmapConfigurationDraft;
  setHeatmapConfigurationDraft: (heatmapConfig: HeatmapConfigurationDraft) => void;
  template: IssueTemplateDraft | IssueTemplate;
  createHeatmapConfiguration: (data: any) => Promise<HeatmapConfigurationDraft>;
  updateHeatmapConfigurationDraft: (data: any, _id: string) => Promise<HeatmapConfigurationDraft>;
}

interface ColorObject {
  min: number;
  max: number;
  color: string;
}

const HeatMapColors = (props: HeatMapProps): JSX.Element => {
  const {
    openFilter,
    classes,
    onClose,
    colorFilters,
    setColorFilters,
    taskTime,
    setTaskTime,
    heatmapConfigurationDraft,
    setHeatmapConfigurationDraft,
  } = props;

  const { t } = useTranslation();

  const [colorOptions, setColorOptions] = useState<ColorObject[]>(colorFilters);
  const [keyColorPicker, setKeyColorPicker] = useState<number | null>(null);
  const [numberMinToEdit, setNumberMinToEdit] = useState<{ val: number; key: number; time: number }>(null);
  const [numberMaxToEdit, setNumberMaxToEdit] = useState<{ val: number; key: number; time: number }>(null);
  // const [edit, setEdit] = useState<boolean>(false);
  const [, setUpdate] = useState();
  const forceUpdate = useCallback(() => setUpdate(() => undefined), []);
  const [colorPickerRef, setColorPickerRef] = useState<HTMLDivElement>(null);

  useEffect(() => {
    if (heatmapConfigurationDraft) {
      setColorOptions(heatmapConfigurationDraft.colorsRange);
      setTaskTime(heatmapConfigurationDraft.taskTime);
    }
  }, []);

  const save = async () => {
    const data = {
      taskTime,
      colorsRange: colorOptions,
    };

    const resp = await props.updateHeatmapConfigurationDraft(data, heatmapConfigurationDraft?._id);
    if (resp) {
      setHeatmapConfigurationDraft(resp);
    }
  };

  return (
    <Popover
      style={{ top: 0 }}
      open={!!openFilter}
      onClose={() => onClose()}
      classes={{ paper: classes.popoverContainer }}
    >
      <div className={classes.divContainer}>
        <div className={classes.divSettings}>
          <Typography className={classes.popoverTitleText}>{t('Settings')}</Typography>
          {/* <Edit onClick={() => setEdit(!edit)} style={{ marginLeft: 15 }} classes={{ root: classes.editIcon }} /> */}
          <Close className={classes.btnClose} onClick={() => onClose()} />
        </div>
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'auto',
            }}
          >
            <div className={classes.divTaskTime}>
              <Typography className={classes.textArea}>{t('taktTime')} (s)</Typography>
              {/* {edit ? ( */}
              <TextField
                value={taskTime}
                variant='outlined'
                size='small'
                className={classes.textField}
                inputProps={{
                  style: { textAlign: 'center', padding: 5 },
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setTaskTime(e.target.value ? parseFloat(e.target.value) : null);
                }}
              />
              {/* ) : (
                <Typography>{taskTime}</Typography>
              )} */}
            </div>

            <Divider style={{ margin: '16px 8px' }} />

            <div
              style={{ marginLeft: '24px', display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto' }}
            >
              <Typography className={classes.textArea}>{t('colors')}</Typography>
              <div style={{ overflow: 'auto' }}>
                {colorOptions?.length !== 0
                  ? colorOptions?.map((option, key) => (
                      <div key={key} style={{ marginBottom: '24px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }} key={key}>
                          {/* {edit ? ( */}
                          <TextField
                            value={numberMinToEdit && numberMinToEdit.key === key ? numberMinToEdit.val : option.min}
                            // variant={edit === true ? 'outlined' : 'standard'}
                            variant={'outlined'}
                            type='number'
                            size='small'
                            placeholder='None'
                            className={classes.textField}
                            // disabled={!edit}
                            inputProps={{
                              style: { textAlign: 'center', padding: 5 },
                              step: 0.01,
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setNumberMinToEdit({
                                val: e.target.value ? parseFloat(e.target.value) : null,
                                key: key,
                                time: e.target.value ? taskTime * (parseFloat(e.target.value) / 100) : 0,
                              });
                              const test = colorOptions;
                              test[parseInt(`${key}`)].min = e.target.value ? parseFloat(e.target.value) : 0.0;
                              setColorOptions(test);
                            }}
                            onClick={() =>
                              setNumberMinToEdit({ val: option.min, key: key, time: taskTime * (option.min / 100) })
                            }
                          />

                          {/* ) : (
                          <Typography className={classes.colorValues}>
                          {numberMinToEdit && numberMinToEdit.key === key ? numberMinToEdit.val : option.min}
                          </Typography>
                        )} */}

                          <span className={classes.separator}>-</span>
                          {/* {edit ? ( */}
                          <TextField
                            value={numberMaxToEdit && numberMaxToEdit.key === key ? numberMaxToEdit.val : option.max}
                            placeholder='None'
                            // variant={edit === true ? 'outlined' : 'standard'}
                            variant={'outlined'}
                            size='small'
                            type='number'
                            // disabled={!edit}
                            className={classes.textField}
                            inputProps={{
                              style: { textAlign: 'center', padding: 5 },
                              step: 0.01,
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setNumberMaxToEdit({
                                val: e.target.value ? parseFloat(e.target.value) : null,
                                key: key,
                                time: e.target.value ? taskTime * (parseFloat(e.target.value) / 100) : 0,
                              });
                              const test = colorOptions;
                              test[parseInt(`${key}`)].max = e.target.value ? parseFloat(e.target.value) : 0.0;
                              setColorOptions(test);
                            }}
                            onClick={() =>
                              setNumberMaxToEdit({ val: option.max, key: key, time: taskTime * (option.max / 100) })
                            }
                          />
                          {/* ) : (
                          <Typography className={classes.colorValues}>
                          {numberMaxToEdit && numberMaxToEdit.key === key ? numberMaxToEdit.val : option.max}
                          </Typography>
                        )} */}

                          <Tooltip arrow title={'Label value color'}>
                            <div
                              className={classes.divBackgroundColor}
                              style={{ backgroundColor: option.color }}
                              id={`pickerColor${key}`}
                              onClick={(e): void => {
                                setKeyColorPicker(key);
                                setColorPickerRef(e.currentTarget);
                              }}
                            />
                          </Tooltip>
                          {/* {edit ? ( */}
                          <div style={{ marginLeft: 'auto', marginRight: '24px' }}>
                            <Delete
                              onClick={() => {
                                setColorOptions(colorOptions.filter((opt) => colorOptions.indexOf(opt) !== key));
                              }}
                              classes={{ root: classes.deleteIcon }}
                            />
                          </div>
                          {/* ) : null} */}
                          <Popover
                            open={!!colorPickerRef}
                            // @ts-ignore
                            anchorEl={colorPickerRef}
                            onClose={(): void => {
                              setColorPickerRef(null);
                              setKeyColorPicker(null);
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          >
                            <div className={classes.colorPicker}>
                              <TwitterPicker
                                onChange={(e: { hex: string }): void => {
                                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                  colorOptions[parseInt(`${keyColorPicker}`)].color = e.hex;
                                  forceUpdate();

                                  setKeyColorPicker(null);
                                  setColorPickerRef(null);
                                }}
                                /* id={`picker${key}`}
                            passedStyles={{ input: { display: 'none' }, card: { position: 'relative' } }} */
                                width={'312px'}
                                colors={[
                                  '#000000',
                                  '#272848',
                                  '#00BF9D',
                                  '#FFD279',
                                  '#FE8947',
                                  '#F65555',
                                  '#F44336',
                                  '#FFD600',
                                  '#00C48C',
                                  '#1976D2',
                                  '#CACACA',
                                ]}
                              />
                            </div>
                          </Popover>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ width: '24%' }}>
                            <p style={{ margin: '0px' }}>
                              {colorOptions[key]
                                ? parseFloat((taskTime * (colorOptions[key].min / 100)).toFixed(2))
                                : 0.0}{' '}
                              (s)
                            </p>
                          </div>
                          <div style={{ marginLeft: '32px', width: '24%' }}>
                            <p style={{ margin: '0px' }}>
                              {colorOptions[key]
                                ? parseFloat((taskTime * (colorOptions[key].max / 100)).toFixed(2))
                                : 0.0}{' '}
                              (s)
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  : null}

                {/* {edit ? ( */}
                <Button
                  variant='text'
                  onClick={() => {
                    setColorOptions(
                      colorOptions
                        ? [...colorOptions, { min: 0.0, max: 0.0, color: '#000000' }]
                        : [{ min: 0.0, max: 0.0, color: '#000000' }],
                    );
                    setNumberMinToEdit(null);
                    setNumberMaxToEdit(null);
                  }}
                  // className={classes.addGroupText}
                  className={classes.btnAddRange}
                >
                  <Add />
                  {i18n.t('addRange')}
                </Button>
                {/* ) : null} */}
              </div>
            </div>
          </div>

          {/* {edit ? ( */}
          <div className={classes.divApply}>
            <DefaultButton id='buttonClearAll' discard onClick={() => setColorOptions([])}>
              {t('clearAll')}
            </DefaultButton>
            <DefaultButton
              id='buttonApply'
              onClick={async () => {
                await save();
                setColorFilters(colorOptions);
                onClose();
              }}
              success
            >
              {t('apply')}
            </DefaultButton>
          </div>
          {/* ) : null} */}
        </>
      </div>
    </Popover>
  );
};
const mapStateToProps = (): Record<string, unknown> => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateTemplate,
      createHeatmapConfiguration,
      updateHeatmapConfigurationDraft,
    },
    dispatch,
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default compose<any>(
  withTranslation('translation'),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(HeatMapColors);
